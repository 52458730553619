import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Field, Flex, Form, Input, Modal, Select, Text, Textarea, useHistory,
} from 'controls';

function EditComponentProperties(props) {
  const { onEditProperties, skillComponent } = props;
  const { component, properties } = skillComponent;
  const history = useHistory();
  const initialValues = {};
  properties.forEach((property) => {
    initialValues[property.property.name] = property.value != null ? property.value : '';
  });
  return (
    <Modal
      title="Edit properties"
      width={['100%', '100%', '500px']}
      overflow="visible"
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      <Form
        initialValues={initialValues}
        onSubmit={async (values) => {
          if (onEditProperties) {
            onEditProperties(skillComponent, values);
            history.goBack();
          }

          return null;
        }}
        action="Update"
        fields={() => (
          <Box>
            <Text
              color="muted"
              fontSize={14}
              mt={1}
              mb={4}
              text={`${component.name} properties`}
            />
            {properties.map((property) => (
              <Box key={property.property.name} mt={4}>
                <Flex mb={1} justifyContent="space-between" alignItems="center">
                  <Text
                    fontSize={14}
                    text={`${property.property.description} (${property.property.type})`}
                  />
                  {property.property.required && (
                    <Text
                      bg="error"
                      color="bg"
                      text="Required"
                      borderRadius={4}
                      px={1}
                      fontSize={12}
                    />
                  )}
                </Flex>
                {!property.property.options && property.property.type === 'string' && (
                  <Field
                    name={property.property.name}
                    component={(
                      <Input type={property.property.secret ? 'password' : 'text'} />
                    )}
                  />
                )}
                {!property.property.options && property.property.type === 'text' && (
                  <Field
                    name={property.property.name}
                    component={(
                      <Textarea />
                    )}
                  />
                )}
                {!property.property.options && property.property.type === 'number' && (
                  <Field
                    name={property.property.name}
                    component={(
                      <Input type="number" />
                    )}
                  />
                )}
                {property.property.options && (
                  <Field
                    name={property.property.name}
                    component={(
                      <Select
                        options={property.property.options}
                      />
                    )}
                  />
                )}
              </Box>
            ))}
          </Box>
        )}
      />
    </Modal>
  );
}

EditComponentProperties.propTypes = {
  onEditProperties: PropTypes.func,
  skillComponent: PropTypes.shape({
    component: PropTypes.shape({
      name: PropTypes.string,
    }),
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        property: PropTypes.shape({
          name: PropTypes.string,
          description: PropTypes.string,
          type: PropTypes.string,
          required: PropTypes.bool,
          defaultValue: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
            PropTypes.bool,
          ]),
        }),
        value: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
          PropTypes.bool,
        ]),
      }),
    ),
  }).isRequired,
};

EditComponentProperties.defaultProps = {
  onEditProperties: null,
};

export default EditComponentProperties;
