import React from 'react';
import {
  Box, Button, Divider, Flex, Link, Page, Tabs, TabItem, useParams, Section, Text, useQuery,
} from 'controls';

import Utils from 'components/Utils';
import SkillSection from 'pages/SkillSection';
import QuerySection from 'pages/QuerySection';

import HackDetectTextTest from './HackDetectTextTest';
import HackDetectImageTest from './HackDetectImageTest';
import HackDetectGifTest from './HackDetectGifTest';
import HackDetectVideoTest from './HackDetectVideoTest';
import HackDetectStreamTest from './HackDetectStreamTest';
import HackDetectAudioTest from './HackDetectAudioTest';
import HackDetectCustomTest from './HackDetectCustomTest';
import HackDetectTextApi from './HackDetectTextApi';
import HackDetectImageApi from './HackDetectImageApi';
import HackDetectGifApi from './HackDetectGifApi';
import HackDetectVideoApi from './HackDetectVideoApi';
import HackDetectStreamApi from './HackDetectStreamApi';
import HackDetectAudioApi from './HackDetectAudioApi';
import HackDetectCustomApi from './HackDetectCustomApi';

function HackDetectQueue() {
  const params = useParams();
  const { type, queue, tab } = params;
  const queryParams = useQuery();

  const singularType = Utils.toSingular(type);
  const lowerTab = tab.toLowerCase();

  return (
    <Page mt={4}>
      <Section mt={6} mb={4}>
        <Box>
          <Flex alignItems="center">
            <Button
              fontSize={17}
              format="inline"
              text="Automatic Detection"
              to="/detect"
            />
            <Text
              ml={2}
              mr={1}
              fontSize={17}
              text=">"
            />
            <Button
              ml={2}
              fontSize={17}
              format="inline"
              text={Utils.toPascalCase(type)}
              to={`/detect/${type}`}
            />
            <Text
              ml={2}
              mr={1}
              fontSize={17}
              text=">"
            />
            <Text
              ml={2}
              fontSize={17}
              text={queue}
            />
          </Flex>
        </Box>
      </Section>
      <Divider />
      <Tabs mt={1}>
        <TabItem
          text="API"
          active={lowerTab === 'api'}
          to={`/detect/${type}/${queue}/api`}
        />
        <TabItem
          text="Skill"
          active={lowerTab === 'skill'}
          to={`/detect/${type}/${queue}/skill`}
        />
        <TabItem
          text="Test"
          active={lowerTab === 'test'}
          to={`/detect/${type}/${queue}/test`}
        />
        <TabItem
          text="Board"
          active={lowerTab === 'board'}
          to={`/detect/${type}/${queue}/board`}
        />
      </Tabs>
      {tab === 'skill' && (
        <Section mt={6}>
          <Text
            mt={2}
            mb={6}
            color="muted"
            text={[`Configure how traits are extracted from the ${singularType.toLowerCase()} with the skill below. A skill can invoke multiple components and can have imperative logic to combine their results. `, <Link to="/documentation/skills">Learn about skills.</Link>]}
          />
          <SkillSection type={type} id={`${type.toLowerCase()}-${queue}`} hideTitlebar />
        </Section>
      )}
      {tab === 'board' && <QuerySection mt={6} type={type} queryParams={queryParams} />}
      {type === 'texts' && tab === 'test' && <HackDetectTextTest skillId={`${type}-${queue}`} />}
      {type === 'images' && tab === 'test' && <HackDetectImageTest skillId={`${type}-${queue}`} />}
      {type === 'gifs' && tab === 'test' && <HackDetectGifTest skillId={`${type}-${queue}`} />}
      {type === 'videos' && tab === 'test' && <HackDetectVideoTest skillId={`${type}-${queue}`} />}
      {type === 'streams' && tab === 'test' && <HackDetectStreamTest skillId={`${type}-${queue}`} />}
      {type === 'audios' && tab === 'test' && <HackDetectAudioTest skillId={`${type}-${queue}`} />}
      {type === 'customs' && tab === 'test' && <HackDetectCustomTest skillId={`${type}-${queue}`} />}
      {type === 'texts' && tab === 'api' && <HackDetectTextApi />}
      {type === 'images' && tab === 'api' && <HackDetectImageApi />}
      {type === 'gifs' && tab === 'api' && <HackDetectGifApi />}
      {type === 'videos' && tab === 'api' && <HackDetectVideoApi />}
      {type === 'streams' && tab === 'api' && <HackDetectStreamApi />}
      {type === 'audios' && tab === 'api' && <HackDetectAudioApi />}
      {type === 'customs' && tab === 'api' && <HackDetectCustomApi />}
    </Page>
  );
}

export default HackDetectQueue;
