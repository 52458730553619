import React from 'react';
import PropTypes from 'prop-types';
import {
  ResponsiveContainer, LineChart, XAxis, YAxis, Line, CartesianGrid,
} from 'recharts';
import { stylex } from 'controls';

import Utils from './Utils';

const STRINGS = {
  ylabel: '%',
};

function CDF(props) {
  const { className, data, title } = props;

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <div className={className}>
      <ResponsiveContainer width="100%" aspect={4.0 / 2.0}>
        <LineChart width={300} height={300} data={data}>
          <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
          <XAxis
            type="number"
            tickCount={20}
            dataKey="x"
            domain={[data[0].x, data[data.length - 1].x]}
            label={{ value: title, position: 'insideBottom' }}
            height={40}
            tickFormatter={(value) => Utils.toDecimal(value, 1)}
          />
          <YAxis
            label={{
              value: STRINGS.ylabel, position: 'inside', angle: -90, dx: -20,
            }}
            type="number"
            dataKey="y"
            width={40}
            tickFormatter={(value) => Utils.toDecimal(value, 1)}
          />
          <Line type="monotone" dataKey="y" stroke="#81b2f1" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

CDF.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  ),
  title: PropTypes.string,
};

CDF.defaultProps = {
  className: null,
  data: [],
  title: null,
};

export default stylex(CDF);
