import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Flex, Link, Page, Section, Tabs, TabItem, Text, useApi, useQuery,
} from 'controls';

import api from 'api';
import Utils from 'components/Utils';

const StatusContainer = (props) => {
  const {
    title, value, footer, width, to,
  } = props;
  return (
    <Box width={width} p={3} m={1} borderWidth={1} borderStyle="solid" borderColor="border" borderRadius={4}>
      {!to && (
        <Text
          fontSize="small"
          fontWeight={500}
          text={title}
        />
      )}
      {to && (
        <Link
          to={to}
        >
          <Text
            fontSize="small"
            fontWeight={500}
            text={title}
          />
        </Link>
      )}
      {!to && (
        <Text fontWeight={500} fontSize={30}>
          {value}
        </Text>
      )}
      {to && (
        <Link
          to={to}
        >
          <Text fontWeight={500} fontSize={30}>
            {value}
          </Text>
        </Link>
      )}
      {footer && (
        <Text>
          {footer}
        </Text>
      )}
    </Box>
  );
};

StatusContainer.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  footer: PropTypes.string,
  width: PropTypes.string.isRequired,
  to: PropTypes.string,
};

StatusContainer.defaultProps = {
  footer: null,
  to: null,
};

function Status(props) {
  const { contentType } = props;
  const type = Utils.mapContentTypeToRestType(contentType);
  const queryParams = useQuery();
  const regionId = queryParams.get('regionId') || '';

  const {
    data: statusData, isLoading: isStatusLoading,
  } = useApi(async () => api.getStatus(regionId));

  const {
    data: allStatusData, isLoading: isAllStatusLoading,
  } = useApi(async () => api.getStatus(''));

  let errorRate = 0;
  if (statusData && statusData.requests > 0) {
    errorRate = statusData.errors / statusData.requests;
  }

  return (
    <Page mt={2} loading={isStatusLoading || isAllStatusLoading}>
      {allStatusData && (
        <Tabs>
          <TabItem
            text="All regions"
            active={!regionId}
            to="/status"
          />
          {allStatusData.regions.map((region) => (
            <TabItem
              key={region}
              text={region}
              active={regionId === region}
              to={`/status?regionId=${region}`}
            />
          ))}
        </Tabs>
      )}

      <Section mt={4}>
        {statusData && allStatusData && (
          <>
            <Flex p={2} justifyContent="center">
              <Text
                mb={2}
                fontSize="small"
                text="Status from the last 1 minute"
              />
            </Flex>
            <Flex justifyContent="center">
              <Flex width="100%" textAlign="center">
                <StatusContainer width="33%" title="Nodes" value={statusData.nodes} />
                <StatusContainer width="33%" title="Processes" value={statusData.processes} />
                <StatusContainer width="33%" title="Threads" value={statusData.threads} />
              </Flex>
            </Flex>
            <Flex justifyContent="center">
              <Flex width="100%" textAlign="center">
                <StatusContainer width="33%" title="Seconds Spent Busy" value={statusData.busyTimeInSeconds.toFixed(1)} />
                <StatusContainer width="33%" title="Seconds Spent Idle" value={statusData.idleTimeInSeconds.toFixed(1)} />
                <StatusContainer width="33%" title="Waiting" value={statusData.waiting} />
              </Flex>
            </Flex>
            <Flex justifyContent="center">
              <Flex width="100%" textAlign="center">
                <StatusContainer
                  width="33%"
                  title="Jobs Processed"
                  value={statusData.processed}
                  footer={`Avg: ${statusData.avgProcessed.toFixed(0)}`}
                  to={`/${type}`}
                />
                <StatusContainer
                  width="33%"
                  title="Frames Processed"
                  value={statusData.framesProcessed}
                  footer={`Avg: ${statusData.avgFramesProcessed.toFixed(0)}`}
                />
                <StatusContainer
                  width="33%"
                  title="Average job waiting time"
                  value={statusData.avgJobWaitingTime.toFixed(1)}
                />
              </Flex>
            </Flex>
            <Flex justifyContent="center">
              <Flex width="100%" textAlign="center">
                <StatusContainer
                  width="33%"
                  title="Requests"
                  value={statusData.requests}
                />
                <StatusContainer
                  width="33%"
                  title="Active streams"
                  value={statusData.activeStreams}
                  to="/activestreams"
                />
                <StatusContainer
                  width="33%"
                  title="Error rate"
                  value={errorRate.toFixed(1)}
                />
              </Flex>
            </Flex>
            <Flex justifyContent="center">
              <Flex width="100%" textAlign="center">
                <StatusContainer
                  width="25%"
                  title="Job errors"
                  value={statusData.errorCounts.jobErrors}
                  to={`/errors?category=job&contentType=${contentType}&regionId=${regionId}`}
                />
                <StatusContainer
                  width="25%"
                  title="Output errors"
                  value={statusData.errorCounts.outputErrors}
                  to={`/errors?category=output&contentType=${contentType}&regionId=${regionId}`}
                />
                <StatusContainer
                  width="25%"
                  title="Processor errors"
                  value={statusData.errorCounts.processorErrors}
                  to={`/errors?category=processor&contentType=${contentType}&regionId=${regionId}`}
                />
                <StatusContainer
                  width="25%"
                  title="Input errors"
                  value={statusData.errorCounts.inputErrors}
                  to={`/errors?category=input&contentType=${contentType}&regionId=${regionId}`}
                />
              </Flex>
            </Flex>
          </>
        )}
      </Section>
    </Page>
  );
}

Status.propTypes = {
  contentType: PropTypes.string.isRequired,
};

export default Status;
