import React from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import Collapsible from 'components/Collapsible';
import {
  Modal,
} from 'controls';
import Utils from 'components/Utils';

function ProcessRequestTemplate(props) {
  const {
    type, skillId, skillName, skillVersion,
    isActiveSkill,
  } = props;
  const singularType = Utils.toSingularLowerCase(type);
  const skillIdTemplate = JSON.parse(`{"skillId": "${skillName === null ? skillId : skillName}"}`);
  const skillNameTemplate = JSON.parse(`{"skillName": "${skillName}", "skillVersion": ${skillVersion}}`);
  const activeSkillTemplate = JSON.parse(`{"skillName": "${skillName === null ? skillId : skillName}"}`);
  let activeSkillTitle = skillName === null ? 'Option 2: ' : 'Option 3: ';
  activeSkillTitle = activeSkillTitle.concat('This will call the active skill. SkillVersion is not needed.');
  const skillNameAndVersionTitle = isActiveSkill ? 'Option 2: Skill name and version.' : 'Option 1: Skill name and version.';
  const skillIdTitle = 'Option 1: This will call the active skill. SkillVersion is not needed. This option will be deprecated in the future.';

  return (
    <Modal
      title={`Process ${singularType} request templates for making direct call to POST /api/v1/${type} (e.g. via Swagger UI)`}
      subtitle={`Choose one of the options to build your ${singularType} processing request. Add more properties (e.g. ${singularType}Uri) as needed.`}
      width={['100%', '100%', '500px']}
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      {isActiveSkill && (
        <Collapsible mt={4} title={skillIdTitle}>
          <ReactJson
            name={false}
            src={skillIdTemplate}
          />
        </Collapsible>
      )}
      {skillName !== null && (
        <Collapsible mt={4} title={skillNameAndVersionTitle}>
          <ReactJson
            name={false}
            src={skillNameTemplate}
          />
        </Collapsible>
      )}
      {isActiveSkill && (
        <Collapsible mt={4} title={activeSkillTitle}>
          <ReactJson
            name={false}
            src={activeSkillTemplate}
          />
        </Collapsible>
      )}
    </Modal>
  );
}

ProcessRequestTemplate.propTypes = {
  type: PropTypes.string,
  skillId: PropTypes.string,
  skillName: PropTypes.string,
  skillVersion: PropTypes.number,
  isActiveSkill: PropTypes.bool,
};

ProcessRequestTemplate.defaultProps = {
  type: null,
  skillId: null,
  skillName: null,
  skillVersion: null,
  isActiveSkill: false,
};

export default ProcessRequestTemplate;
