import React from 'react';

import Markdown from 'documentation/Markdown';

const markdown = `

Integrate with the following APIs to automatically detect traits in images and moderate them in real-time. API key should be in the *x-api-key* header.

#### Processing images

~~~
POST /images
~~~
Start processing an image. Input is specified in the body. The main fields are:
* **id** (required): Unique id for the image. It can be maximum 1024 characters with [0-9a-zA-Z-_]+. The results in storage are overwritten when multiple images are passed with the same id. *We recommend not to pass any PII information as part of id.*
* **imageUri** (required): Url to the image.
* **props** (optional): Properties exposed to the skill. Props has to be formatted as a JSON ({ ... }).
* **context** (optional): A string that is simply echoed back. Pass any value that needs to be read together with the results (e.g. to correlate). Context is attached to the results sent to Event Hub and also stored and returned as part of the GET results API. *We recommend not to pass any PII information here*.
* **privateContext** (optional): Similar to *context* except that it is not stored and not returned as part of the GET results API. It will only be echoed back with the results sent to Event Hub.

~~~
GET /images/{id}
~~~
Get results for a processed image. If retention policy doesn't allow results to be stored, this API only returns the processing status.
The following describes the main fields in the response:
* **id**: Id in the request.
* **status**: Contains one of four values (waiting / processing / success / error) that specifies whether the image is waiting to be processed, is currently being processed, has successfully completed or completed with an error. If the value is *waiting* or *processing*, poll again. If the value is *error*, the *error* field has a descriptive message. If the value is *success*, read the other fields.
* **result**: Contains the results. The *result* field has all the data returned by the skill (<code>processImage</code> method) being run. For example, the template skill returns the following values:
  * result.adult: Whether the image is classified as adult.
  * result.racy: Whether the image is classified as racy.
  * result.gore: Whether the image is classified as gore.
  * result.classifiers: Raw results from individual models.
  * result.hash: Perceptual (d)hash of the image.
* **context**: The context value passed in the POST request.
* **stats**: Stats such as image attributes, timestamps and processing durations.
* **error**: Descriptive error if status is returned as *error*. 

~~~
READ Image Event Hub
~~~
Results are pushed to Event Hub (both on success and on error) as soon as an image is finished processing. We recommend that results are read through Event Hub instead of polling the GET API.
One event is generated per image. The result format is same as described in the GET API above.

#### Example
The following shows a sample request:

~~~
POST /images

{
  "id": "test-image",
  "imageUri": "https://www.gstatic.com/webp/gallery/1.jpg"
}
~~~

The following is the result:
~~~
{
  "id": "test-image",
  "status": "success",
  "result": {
    "adult": false,
    "racy": false,
    "gore": false
  },
  ...
}
~~~

<br/><br/>
`;

export default function Doc() {
  return <Markdown fontSize={15} mt={6} markdown={markdown} />;
}
