import React, { Children } from 'react';
import PropTypes from 'prop-types';

import Box from './Box';
import Divider from './Divider';
import stylex from './stylex';

function Tabs(props) {
  const {
    children,
    className,
  } = props;

  const renderedChildren = Children.toArray(children);
  const totalTabs = renderedChildren.length;
  return (
    <Box className={className}>
      {renderedChildren.map((child, index) => {
        if (!child) {
          return null;
        }

        return (
          <Box
            key={(child.props && child.props.text) || index}
            display="inline-block"
            height="100%"
            width={1 / totalTabs}
          >
            {child}
          </Box>
        );
      })}
      <Divider />
    </Box>
  );
}

Tabs.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
};

Tabs.defaultProps = {
  children: null,
  className: null,
};

export default stylex(Tabs, {
  color: 'muted',
  height: 48,
});
