import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Box, Field, Form, Input, Modal, Select, Text, useHistory,
} from 'controls';

import api from 'api';

function EditNodeSettings(props) {
  const {
    region, settings, onUpdate,
  } = props;
  const history = useHistory();

  return (
    <Modal
      overflow="visible"
      title="Edit Node Settings"
      width={['100%', '100%', '480px']}
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      <Form
        initialValues={{
          processPerCore: settings.processPerCore,
          processes: settings.processes,
          cpuOffset: settings.cpuOffset,
          pin: settings.pin,
          threads: settings.threads,
        }}
        validationSchema={Yup.object().shape({
          processes: Yup.number().required('Required'),
          cpuOffset: Yup.number().required('Required'),
          threads: Yup.number().required('Required'),
        })}
        onSubmit={async (values) => {
          const response = await api.updateNodeSettings(
            region,
            values.processPerCore,
            values.processes,
            values.cpuOffset,
            values.pin,
            values.threads,
          );
          if (!response.error) {
            if (onUpdate) {
              onUpdate();
            }

            history.goBack();
            return null;
          }

          return null;
        }}
        action="Update"
        fields={(fprops) => (
          <Box>
            <Field
              label="Run one process per core"
              name="processPerCore"
              component={(
                <Select
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                />
              )}
            />
            {!fprops.values.processPerCore && (
              <>
                <Field
                  label="Number of processes per node"
                  name="processes"
                  component={(
                    <Input
                      type="number"
                    />
                  )}
                />
                <Field
                  label="CPU offset"
                  name="cpuOffset"
                  component={(
                    <Input
                      type="number"
                    />
                  )}
                />
                <Field
                  label="Pin process to core"
                  name="pin"
                  component={(
                    <Select
                      options={[
                        { label: 'Yes', value: true },
                        { label: 'No', value: false },
                      ]}
                    />
                  )}
                />
              </>
            )}
            <Field
              label="Number of threads per process"
              name="threads"
              component={(
                <Input
                  type="number"
                />
              )}
            />
            <Text
              mt={4}
              mb={2}
              fontSize={12}
              color="error"
              text="CAUTION: Processes will be restarted on update. Existing jobs in process will be stopped and retried automatically."
            />
          </Box>

        )}
      />
    </Modal>
  );
}

EditNodeSettings.propTypes = {
  onUpdate: PropTypes.func,
  region: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    processPerCore: PropTypes.bool,
    processes: PropTypes.number,
    cpuOffset: PropTypes.number,
    pin: PropTypes.bool,
    threads: PropTypes.number,
  }).isRequired,
};

EditNodeSettings.defaultProps = {
  onUpdate: null,
};

export default EditNodeSettings;
