import React from 'react';

import Markdown from 'documentation/Markdown';

const markdown = `

Integrate with the following APIs to automatically detect traits in gifs and moderate them in real-time. API key should be in the *x-api-key* header.

#### Processing gifs
A gif is sampled to extract frames and analysis is done on each sampled frame. Frame results are aggregated to produce a result for the entire gif. Sampling rate, per-frame analysis and aggregate analysis are all configurable through skills.

~~~
POST /gifs
~~~
Start processing a gif. Input is specified in the body. The main fields are:
* **id** (required): Unique id for the gid. It can be maximum 1024 characters with [0-9a-zA-Z-_]+. The results in storage are overwritten when multiple gifs are passed with the same id. *We recommend not to pass any PII information as part of id.*
* **gifUri** (required): Url to the gif. We currently support only gif format.
* **props** (optional): Properties exposed to the skill. Props has to be formatted as a JSON ({ ... }).
* **context** (optional): A string that is simply echoed back. Pass any value that needs to be read together with the results (e.g. to correlate). Context is attached to the results sent to Event Hub and also stored and returned as part of the GET results API. *We recommend not to pass any PII information here*.
* **privateContext** (optional): Similar to *context* except that it is not stored and not returned as part of the GET results API. It will only be echoed back with the results sent to Event Hub.

~~~
GET /gifs/{id}
~~~
Get results for a processed gif. If retention policy doesn't allow results to be stored, this API only returns the processing status.
The following describes the main fields in the response:
* **id**: Id in the request.
* **status**: Contains one of four values (waiting / processing / success / error) that specifies whether the gif is waiting to be processed, is currently being processed, has successfully completed or completed with an error. If the value is *waiting* or *processing*, poll again. If the value is *error*, the *error* field has a descriptive message. If the value is *success*, read the other fields.
* **result**: Contains the results. The *result* field has all the data returned by the skill (<code>aggregateFrameResults</code> method) being run. For example, the template skill returns the following values after aggregating frame results:
  * result.adult: Whether the gif is classified as adult.
  * result.racy: Whether the gif is classified as racy.
  * result.gore: Whether the gif is classified as gore.
* **context**: The context value passed in the POST request.
* **stats**: Stats such as gif attributes, timestamps and processing durations.
* **error**: Descriptive error if status is returned as *error*. 

~~~
GET /gifs/{id}/frames
~~~
Get results for individual sampled frames. If retention policy doesn't allow results to be stored, this API returns empty list.
The response is an array of frame results. The following describes the main fields in the response:
* **timestamp**: Timestamp of the frame in the gif.
* **result**: Contains the frame results. The *result* field has all the data returned by the skill (<code>processFrame</code> method) being run on each frame. For example, the template skill returns the following values for each frame:
  * result.adult: Whether the gif frame is classified as adult.
  * result.racy: Whether the gif frame is classified as racy.
  * result.gore: Whether the gif frame is classified as gore.
  * result.classifiers: Raw results from individual models.
  * result.hash: Perceptual (d)hash of the gif frame.
* **frameUri**: Url to the image, if retention policy and skill allows for storing gif frames. Images are stored at 480p resolution.
* **stats**: Stats such as processing durations.

~~~
READ Gif Event Hub
~~~
Results are pushed to Event Hub (both on success and on error) as soon as a gif is finished processing. We recommend that results are read through Event Hub instead of polling the GET API.
One event is generated per gif. The result format is same as described in the GET /gifs/{id} API. In addition, there is also a *frames* field that includes the individual frame results 
with the format described in GET /gifs/{id}/frames API.

#### Example
The following shows a sample request:

~~~
POST /gifs

{
  "id": "test-gif",
  "gifUri": "https://media.giphy.com/media/1BGQQpzJ0N8K99RgzB/giphy.gif"
}
~~~

The following is the result:
~~~
{
  "id": "test-gif",
  "status": "success",
  "result": {
    "adult": false,
    "racy": false,
    "gore": false
  },
  ...
}
~~~

<br/><br/>
`;

export default function Doc() {
  return <Markdown fontSize={15} mt={6} markdown={markdown} />;
}
