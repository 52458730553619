import { HttpClient } from 'controls';
import { getAccessToken } from 'auth/msal';
import SkillUtils from 'components/SkillUtils';

const http = new HttpClient(`${window.location.origin}/api/v1`, 'json', async (config) => {
  const newConfig = config;
  const accessToken = await getAccessToken();
  if (accessToken) {
    newConfig.headers.Authorization = `Bearer ${accessToken}`;
  }
  return newConfig;
});

class Api {
  getUiSettings = async () => http.get('/settings/ui');

  updateUiSettings = async (contentType) => http.put('/settings/ui', { contentType });

  getProcessorSettings = async () => http.get('/settings/processor');

  updateProcessorSettings = async (storeResults, sendEvents, frameSasExpiry, frameHeight) => http.put('/settings/processor', {
    storeResults, sendEvents, frameSasExpiry, frameHeight,
  });

  getNodeSettings = async () => http.get('/settings/node');

  updateNodeSettings = async (region, processPerCore, processes, cpuOffset, pin, threads) => http.put(`/settings/node/${region}`, {
    processPerCore, processes, cpuOffset, pin, threads,
  });

  getAutoscaleSettings = async () => http.get('/settings/autoscale');

  updateAutoscaleSettings = async (
    refreshDelay,
    minReplicas,
    maxReplicas,
    scaleUpSmoothingParameter,
    scaleDownSmoothingParameter,
    scaleUpUtilizationPercentage,
    scaleDownUtilizationPercentage,
    scaleUpCooldownTimeInSeconds,
  ) => http.put('/settings/autoscale', {
    refreshDelay,
    minReplicas,
    maxReplicas,
    scaleUpSmoothingParameter,
    scaleDownSmoothingParameter,
    scaleUpUtilizationPercentage,
    scaleDownUtilizationPercentage,
    scaleUpCooldownTimeInSeconds,
  });

  getSearchSettings = async () => http.get('/settings/search');

  updateSearchSettings = async (endpoint, apiKey, indexName) => http.put('/settings/search', { endpoint, apiKey, indexName });

  getVectorDbApproxSettings = async () => http.get('/settings/vectordb/approx');

  getVectorDbExactSettings = async () => http.get('/settings/vectordb/exact');

  updateVectorDbApproxSettings = async (endpoint, apiKey, collectionName, vectorFieldName, indexType, metricType, nlist) => http.put('/settings/vectordb/approx',
    {
      endpoint,
      apiKey,
      collectionName,
      vectorFieldName,
      indexType,
      metricType,
      nlist,
    });

  updateVectorDbExactSettings = async (endpoint, apiKey) => http.put('/settings/vectordb/exact',
    {
      endpoint,
      apiKey,
    });

  getComponents = async (contentType, detectionType, filter) => http.get(`/components?contentType=${contentType}&detectionType=${detectionType}&filter=${filter}`);

  createBoard = async (
    type,
    frames,
    id,
    name,
    filterScript,
    orderBy,
    nextOffsetScript,
    limit,
    reviewable,
    contentLayoutId,
    frameLayoutId,
  ) => http.post(`/${type}/boards`, {
    frames,
    id,
    name,
    filterScript,
    orderBy,
    nextOffsetScript,
    limit,
    reviewable,
    contentLayoutId,
    frameLayoutId,
  });

  updateBoard = async (
    type,
    frames,
    id,
    name,
    filterScript,
    orderBy,
    nextOffsetScript,
    limit,
    reviewable,
    contentLayoutId,
    frameLayoutId,
  ) => http.put(`/${type}/boards/${id}`, {
    frames,
    name,
    filterScript,
    orderBy,
    nextOffsetScript,
    limit,
    reviewable,
    contentLayoutId,
    frameLayoutId,
  });

  deleteBoard = async (type, id) => http.delete(`/${type}/boards/${id}`);

  getBoards = async (type, frames) => http.get(`/${type}/boards?frames=${frames}`);

  getBoard = async (type, id, offset) => http.get(`/${type}/boards/${id}?offset=${offset}`);

  createLayout = async (
    id,
    descriptor,
  ) => http.post('/layouts', {
    id,
    descriptor,
  });

  updateLayout = async (
    id,
    descriptor,
  ) => http.put(`/layouts/${id}`, {
    descriptor,
  });

  deleteLayout = async (id) => http.delete(`/layouts/${id}`);

  getLayouts = async () => http.get('/layouts');

  getLayout = async (id) => http.get(`/layouts/${id}`);

  getSkill = async (type, skillName, skillVersion) => {
    let requestUrl = `/${type}/skills/${skillName}`;
    if (skillVersion !== null) {
      requestUrl = SkillUtils.concatSkillVersion(requestUrl, skillVersion);
    }
    return http.get(requestUrl);
  };

  getSkills = async (type) => http.get(`/${type}/skills`);

  listSkillVersions = async (type, skillName) => http.get(`/${type}/skills/${skillName}/versions`);

  getActiveSkills = async (type) => http.get(`/${type}/activeskills`);

  getAllSkills = async () => http.get('/skills');

  createSkill = async (type, skillName, cloneSkillName, version) => http.post(`/${type}/skills`, { skillName, cloneSkillName, version });

  updateSkill = async (
    type,
    id,
    skillVersion,
    name,
    components,
    initScript,
    sampleFrameScript,
    processFrameScript,
    processFrameBatchScript,
    aggregateFramesScript,
    storeFrameScript,
    processTextScript,
    processTextBatchScript,
    processAudioScript,
    processScript,
    properties,
  ) => {
    let requestUrl = `/${type}/skills/${id}`;
    if (skillVersion !== 0) {
      requestUrl = SkillUtils.concatSkillVersion(requestUrl, skillVersion);
    }
    return http.put(requestUrl, {
      name,
      components,
      initScript,
      sampleFrameScript,
      processFrameScript,
      processFrameBatchScript,
      aggregateFramesScript,
      storeFrameScript,
      processTextScript,
      processTextBatchScript,
      processAudioScript,
      processScript,
      properties,
    });
  };

  createNewerVersionedSkill = async (
    type,
    skillName,
    cloneSkillVersion,
  ) => http.post(`/${type}/skills/${skillName}/versions/${cloneSkillVersion}`);

  deleteSkill = async (type, id, skillVersion) => {
    let requestUrl = `/${type}/skills/${id}`;
    if (skillVersion !== null) {
      requestUrl = SkillUtils.concatSkillVersion(requestUrl, skillVersion);
    }
    return http.delete(requestUrl);
  };

  setDefaultSkill = async (type, skillName, skillVersion) => http.patch(`/${type}/skills/default`, { skillName, skillVersion });

  setActiveSkill = async (type, skillName, skillVersion) => http.patch(`/${type}/skills/activate`, { skillName, skillVersion });

  getSummary = async (type) => http.get(`/${type}/summary`);

  getMetrics = async (type, frames, filter, orderBy, limit) => http.get(`/${type}/metrics?filter=${filter || ''}&orderBy=${orderBy || ''}&frames=${frames}&limit=${limit || 1000}`);

  getAutopackSettings = async () => http.get('/settings/autopack');

  updateAutopackSettings = async (
    minutesToRunExperiment,
    refreshDelay,
    minProcesses,
    maxProcesses,
    minThreads,
    maxThreads,
    maxConcurrentExperiments,
    pinSettings,
    contentType,
    containerImage,
  ) => http.put('/settings/autopack', {
    minutesToRunExperiment,
    refreshDelay,
    minProcesses,
    maxProcesses,
    minThreads,
    maxThreads,
    maxConcurrentExperiments,
    pinSettings,
    contentType,
    containerImage,
  });

  triggerAutopackExperiment = async () => http.patch('/settings/triggerautopack');

  cancelAutopackExperiment = async () => http.patch('/settings/cancelautopack');

  getAutopackResults = async (frames, rawData, type, filter, orderBy, limit) => http.get(`/${type}/autopackresults?filter=${filter || ''}&orderBy=${orderBy || ''}&limit=${limit || 1000}&frames=${frames}&rawData=${rawData}`);

  getStatus = async (regionId) => http.get(`/status?regionId=${regionId || ''}`);

  getErrors = async (category, contentType, regionId, limit) => http.get(`/errors?category=${category}&contentType=${contentType}&regionId=${regionId}&limit=${limit}`);

  getContent = async (type, id) => http.get(`/${type}/${id}`);

  getFrames = async (type, id, frameType) => http.get(`/${type}/${id}/frames?type=${frameType}`);

  query = async (type, frames, count, filter, orderBy, limit) => http.get(`/${type}?filter=${filter || ''}&orderBy=${orderBy || ''}&frames=${frames}&count=${count}&limit=${limit || 100}`);

  updatePin = async (type, id, frameId, pinned) => http.put(`/${type}/${id}/pin`, { frameId, pinned });

  updateReview = async (type, id, frameId, reviewed, flagged) => http.put(`/${type}/${id}/review`, { frameId, reviewed, flagged });

  bulkUpdateReview = async (type, items) => http.put(`/${type}/review`, items);

  deleteFrames = async (type, id) => http.delete(`/${type}/${id}/frames`);

  getFolders = async (resourceType) => http.get(`/${resourceType}`);

  createFolder = async (resourceType, name) => http.post(`/${resourceType}`, { name });

  deleteFolder = async (resourceType, name) => http.delete(`/${resourceType}/${name}`);

  getResources = async (resourceType, folderName) => http.get(`/${resourceType}/${folderName}`);

  deleteResource = async (resourceType, folderName, resourceName) => http.delete(`/${resourceType}/${folderName}/${resourceName}`);

  uploadResource = async (resourceType, folderName, resourceName, resource, metadata) => {
    const formData = new FormData();
    formData.append('resourceName', resourceName);
    formData.append('metadata', JSON.stringify(metadata));
    formData.append('resource', resource);
    return http.post(`/${resourceType}/${folderName}`, formData);
  };

  getActiveStreams = async () => http.get('/streams/active');

  test = async (jobData, contentType) => http.post(`/test/${contentType}`, { jobData });

  processUploadedImage = async (resourceName, resource, metadata) => {
    const formData = new FormData();
    formData.append('resourceName', resourceName);
    formData.append('resource', resource);
    formData.append('metadata', JSON.stringify(metadata));
    return http.post('/test/UploadImage', formData);
  };

  createDataset = async (name, contentType) => http.post('/datasets', { name, contentType });

  updateDataset = async (id, name) => http.put(`/datasets/${id}`, { name });

  deleteDataset = async (id) => http.delete(`/datasets/${id}`);

  getDataset = async (id) => http.get(`/datasets/${id}`);

  getDatasets = async (contentType) => http.get(`/datasets?contentType=${contentType || ''}`);

  createDatasetContent = async (datasetId, id, contentUri, contentText, attributes) => http.post(`/datasets/${datasetId}/contents`, {
    id, contentUri, contentText, attributes,
  });

  updateDatasetContent = async (datasetId, id, attributes) => http.put(`/datasets/${datasetId}/contents/${id}`, { attributes });

  deleteDatasetContent = async (datasetId, id) => http.delete(`/datasets/${datasetId}/contents/${id}`);

  getDatasetContent = async (datasetId, id) => http.get(`/datasets/${datasetId}/contents/${id}`);

  getDatasetContents = async (datasetId, filter, orderBy, limit, offset) => http.get(`/datasets/${datasetId}/contents?filter=${filter || ''}&orderBy=${orderBy || ''}&limit=${limit || 100}&offset=${offset || 0}`);

  createRun = async (name, datasetId, skillId) => http.post('/runs', { name, datasetId, skillId });

  queueRun = async (id) => http.post(`/runs/${id}`);

  updateRun = async (id, name) => http.put(`/runs/${id}`, { name });

  deleteRun = async (id) => http.delete(`/runs/${id}`);

  getRun = async (id) => http.get(`/runs/${id}`);

  getRuns = async () => http.get('/runs');

  createRunAnalysis = async (runId, name, script) => http.post(`/runs/${runId}/analyses`, { name, script });

  queueRunAnalysis = async (runId, id) => http.post(`/runs/${runId}/analyses/${id}`);

  updateRunAnalysis = async (runId, id, name) => http.put(`/runs/${runId}/analyses/${id}`, { name });

  hackPostText = async (text, skillId) => http.post('/texts/sync', { text, skillId });

  hackPostImage = async (imageUri, skillId) => http.post('/images/sync', { imageUri, skillId });

  hackPostVideo = async (videoUri, skillId) => http.post('/videos/sync', { videoUri, skillId });

  hackPostGif = async (gifUri, skillId) => http.post('/gifs/sync', { gifUri, skillId });

  hackPostStream = async (id, streamUri, skillId) => http.post('/streams', { id, streamUri, skillId });

  hackStopStream = async (id) => http.put(`/streams/${id}/stop`);

  hackPostAudio = async (audioUri, skillId) => http.post('/audios/sync', { audioUri, skillId });

  hackPostCustom = async (skillId) => http.post('/customs/sync', { skillId });
}

export default Api;
