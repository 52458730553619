import React from 'react';
import {
  Divider, Modal, Page, Section, Text, useModalState,
} from 'controls';

import ComponentDetails from 'components/ComponentDetails';
import ComponentSection from 'components/ComponentSection';

function Components() {
  const modalState = useModalState();
  const {
    showComponent, component,
  } = modalState.get();
  return (
    <Page mt={2}>
      <Section mt={6}>
        <Text
          fontWeight="bold"
          text="Repository of Detection Components"
        />
        <Divider mt={2} mb={4} />
        <ComponentSection
          add={false}
          onClick={(c) => modalState.push(
            {
              showComponent: true,
              component: { component: c },
            },
          )}
        />
        {showComponent && (
          <Modal
            title="Component"
            width={['100%', '576px', '700px']}
            overlayStyle={{
              overflowX: 'auto',
              overflowY: 'scroll',
              position: 'fixed',
              zIndex: 11,
            }}
          >
            <ComponentDetails
              component={component.component}
              handle={component.handle}
            />
          </Modal>
        )}
      </Section>
    </Page>
  );
}

export default Components;
