import React from 'react';
import {
  Page, useParams, useQuery,
} from 'controls';

import QuerySection from './QuerySection';

function Query() {
  const params = useParams();
  const { type } = params;
  const queryParams = useQuery();

  return (
    <Page mt={2}>
      <QuerySection type={type} queryParams={queryParams} />
    </Page>
  );
}

export default Query;
