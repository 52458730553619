import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex, stylex,
} from 'controls';

import SkillComponentItem from './SkillComponentItem';

function SkillComponentList(props) {
  const {
    className, disabled, onClick, onEditHandle, onEditProperties,
    onRemove, skillComponents,
  } = props;

  return (
    <Flex
      className={className}
      flexWrap="wrap"
    >
      {skillComponents.map((skillComponent, index) => (
        <SkillComponentItem
          width="100%"
          key={skillComponent.handle}
          skillComponent={skillComponent}
          onEditHandle={onEditHandle}
          onEditProperties={onEditProperties}
          onClick={onClick}
          onRemove={onRemove}
          index={index}
          disabled={disabled}
        />
      ))}
    </Flex>
  );
}

SkillComponentList.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onEditHandle: PropTypes.func,
  onEditProperties: PropTypes.func,
  onRemove: PropTypes.func,
  skillComponents: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
};

SkillComponentList.defaultProps = {
  className: null,
  disabled: false,
  onClick: null,
  onEditHandle: null,
  onEditProperties: null,
  onRemove: null,
  skillComponents: null,
};

export default stylex(SkillComponentList);
