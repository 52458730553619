import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Divider, Dropdown, Flex, IconButton, MenuItem,
  Page, Section, Text, Time, useApi, useModalState,
} from 'controls';

import api from 'api';
import Utils from 'components/Utils';
import CreateOrEditRun from './CreateOrEditRun';
import DeleteRun from './DeleteRun';

const RunItem = (props) => {
  const {
    run,
    onUpdate,
    onUpdated,
    onDelete,
  } = props;

  const statusBgColor = {
    success: 'accent',
    error: 'error',
    waiting: 'muted',
    running: 'muted',
  };

  return (
    <Box
      pt={3}
    >
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box>
          <Flex alignItems="center" width="100%">
            <Button
              format="inline"
              text={run.name}
              to={`/runs/${run.id}`}
            />
          </Flex>
          <Text
            fontSize={14}
            color="muted"
            text={run.id}
          />
          <Flex mt={1} alignItems="center" width="100%">
            <Text
              borderRadius={4}
              bg="border"
              px={2}
              fontSize={12}
              text={Utils.toPascalCase(run.dataset.name)}
            />
            <Text
              ml={4}
              borderRadius={4}
              bg="border"
              px={2}
              fontSize={12}
              text={Utils.toPascalCase(run.skill.id)}
            />
          </Flex>
        </Box>
        <Box mt={1}>
          <Flex>
            {run.status === 'created' && (
              <Button
                mr={4}
                py={1}
                text="Queue run"
                onClick={async () => {
                  const response = await api.queueRun(run.id);
                  if (response.data) {
                    onUpdated(response.data);
                  }
                }}
              />
            )}
            {run.status !== 'created' && (
              <Text
                mr={4}
                py={1}
                borderRadius={4}
                bg={statusBgColor[run.status]}
                px={2}
                fontSize={14}
                text={Utils.toPascalCase(run.status)}
              />
            )}
            <Dropdown
              alignRight
              closeOnClick={false}
              toggle={(
                <IconButton p={1} color="muted" icon="ellipsis-v" />
              )}
              menuStyle={{
                marginTop: '5px',
              }}
              menuContent={(
                <>
                  <MenuItem
                    divider
                    fontSize="small"
                    py={2}
                    text="Update run"
                    onClick={(e) => {
                      e.stopPropagation();
                      onUpdate();
                    }}
                  />
                  <MenuItem
                    fontSize="small"
                    py={2}
                    text="Delete run"
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete();
                    }}
                  />
                </>
              )}
            />
          </Flex>
        </Box>
      </Flex>
      <Divider mt={3} />
    </Box>
  );
};

RunItem.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onUpdated: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  run: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.string,
    dataset: PropTypes.shape({
      name: PropTypes.string,
    }),
    skill: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

function Runs() {
  const {
    data, isLoading, setData, setDependency,
  } = useApi(async () => api.getRuns(), 0);

  const modalState = useModalState();
  const {
    createRun, updateRun,
    deleteRun, run,
  } = modalState.get();

  const updateList = (item) => {
    const newItems = data.map((r) => {
      if (r.id === item.id) {
        return item;
      }
      return r;
    });
    setData(newItems);
  };

  return (
    <Page mt={2}>
      <Section pt={6}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <Text
            fontWeight="bold"
            text="Runs"
          />

          <Button
            format="inline"
            text="+ Create run"
            onClick={() => modalState.push({
              createRun: true,
            })}
          />
        </Flex>
        <Divider />
      </Section>
      <Section loading={isLoading}>
        {data && data.map((r) => (
          <RunItem
            run={r}
            key={r.id}
            onUpdate={() => modalState.push({
              updateRun: true,
              run: r,
            })}
            onDelete={() => modalState.push({
              deleteRun: true,
              run: r,
            })}
            onUpdated={(item) => {
              updateList(item);
            }}
          />
        ))}
        {data && data.length === 0 && (
          <Box mt={6} textAlign="center">
            <Text
              color="muted"
              text="No runs yet"
            />
          </Box>
        )}
      </Section>
      {createRun && (
        <CreateOrEditRun
          create
          onCreate={() => setDependency(Time.now())}
        />
      )}
      {updateRun && (
        <CreateOrEditRun
          create={false}
          run={run}
          onUpdate={() => setDependency(Time.now())}
        />
      )}
      {deleteRun && (
        <DeleteRun
          run={run}
          onDelete={() => setDependency(Time.now())}
        />
      )}
    </Page>
  );
}

export default Runs;
