import React, { useState } from 'react';
import {
  Box, Button, Dropdown, Divider, Flex, IconButton, Input, Label,
  MenuItem, Page, Section, Text, Textarea, Time, useApi, useHistory, useParams, useQuery,
  useModalState,
} from 'controls';

import api from 'api';
import DatasetContentList from 'components/DatasetContentList';
import Utils from 'components/Utils';
import CreateOrEditDataset from './CreateOrEditDataset';
import DeleteDataset from './DeleteDataset';
import CreateOrEditDatasetContent from './CreateOrEditDatasetContent';
import DeleteDatasetContent from './DeleteDatasetContent';

const STRINGS = {
  none: 'There are no items in the dataset',
  placeholders: {
    content: 'c.attributes.adult=true',
  },
};

function Dataset() {
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  const queryParams = useQuery();
  const [filter, setFilter] = useState(queryParams.get('filter'));
  const [orderBy, setOrderBy] = useState(queryParams.get('orderBy') || 'c.createdAt DESC');
  const [limit, setLimit] = useState(queryParams.get('limit') || 100);
  const offset = 0;

  const modalState = useModalState();
  const {
    updateDataset, deleteDataset,
    createDatasetContent,
    updateDatasetContent,
    deleteDatasetContent,
    datasetContent,
  } = modalState.get();

  const {
    data: dataset,
    isLoading: isDatasetLoading,
    setData: setDataset,
  } = useApi(async () => api.getDataset(id), 0);

  const {
    data: datasetContents,
    isLoading: isDatasetContentsLoading,
    setData: setDatasetContents,
    setDependency: setDatasetContentsDependency,
  } = useApi(async () => api.getDatasetContents(id, filter, orderBy, limit, offset), 0);

  const updateContent = (content) => {
    const newItems = datasetContents.contents.map((d) => {
      if (d.id === content.id) {
        return content;
      }
      return d;
    });
    setDatasetContents({
      contents: newItems,
      count: datasetContents.count,
      limit: datasetContents.limit,
      offset: datasetContents.offset,
    });
  };

  const removeContent = (contentId) => {
    const newItems = datasetContents.contents.filter((d) => d.id !== contentId);
    if (newItems.length === 0) {
      setDatasetContentsDependency(Time.now());
    } else {
      setDatasetContents({
        contents: newItems,
        count: datasetContents.count - 1,
        limit: datasetContents.limit,
        offset: datasetContents.offset,
      });
    }
  };

  return (
    <Page mt={2}>
      <Section mt={4} loading={isDatasetLoading}>
        {dataset && (
          <Flex
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Box>
              <Flex alignItems="center" width="100%">
                <Button
                  format="inline"
                  text="Datasets"
                  to="/datasets"
                />
                <Text
                  ml={2}
                  mr={2}
                  text=">"
                />
                <Text
                  fontWeight="bold"
                  text={dataset.name}
                />
                <Text
                  ml={2}
                  borderRadius={4}
                  bg="border"
                  px={2}
                  fontSize={12}
                  text={Utils.toPascalCase(dataset.contentType)}
                />
              </Flex>
              <Text
                fontSize={14}
                color="muted"
                text={dataset.id}
              />
            </Box>
            <Flex alignItems="center">
              <Button
                mr={4}
                format="inline"
                text="+ Add content"
                onClick={() => modalState.push({
                  createDatasetContent: true,
                })}
              />
              <Box mt={1}>
                <Dropdown
                  alignRight
                  closeOnClick={false}
                  toggle={(
                    <IconButton p={1} color="muted" icon="ellipsis-v" />
                  )}
                  menuStyle={{
                    marginTop: '5px',
                  }}
                  menuContent={(
                    <>
                      <MenuItem
                        divider
                        fontSize="small"
                        py={2}
                        text="Update dataset"
                        onClick={(e) => {
                          e.stopPropagation();
                          modalState.push({
                            updateDataset: true,
                          });
                        }}
                      />
                      <MenuItem
                        fontSize="small"
                        py={2}
                        text="Delete dataset"
                        onClick={(e) => {
                          e.stopPropagation();
                          modalState.push({
                            deleteDataset: true,
                          });
                        }}
                      />
                    </>
                  )}
                />
              </Box>
            </Flex>
          </Flex>
        )}
        <Divider mt={2} />
      </Section>

      <Section mt={2} display="none">
        <Box
          mt={4}
        >
          <Label
            text="WHERE"
          />
          <Textarea
            defaultValue={filter}
            placeholder={STRINGS.placeholders.content}
            onBlur={(e) => setFilter(e.target.value)}
            spellCheck={false}
            minRows={2}
          />
        </Box>
        <Flex mt={1} justifyContent="space-between">
          <Box
            width="33%"
            mr={2}
          >
            <Label
              text="ORDER BY"
            />
            <Input
              defaultValue={orderBy}
              onBlur={(e) => setOrderBy(e.target.value)}
            />
          </Box>
          <Box
            width="33%"
            ml={2}
            mr={2}
          >
            <Label
              text="LIMIT"
            />
            <Input
              width="100%"
              type="number"
              defaultValue={limit}
              onBlur={(e) => setLimit(e.target.value)}
            />
          </Box>
          <Button
            ml={2}
            width={100}
            height={30}
            text="Query"
            loading={isDatasetContentsLoading}
            onClick={() => history.push(`/datasets/${id}?filter=${filter || ''}&orderBy=${orderBy || ''}&limit=${limit}`)}
          />
          <Button
            format="inline"
            text="Refresh"
            onClick={() => setDatasetContentsDependency(Time.now())}
          />
        </Flex>
      </Section>

      <Section mt={4} loading={isDatasetContentsLoading}>
        {datasetContents && datasetContents.contents.length > 0 && (
          <Box>
            <Box
              borderWidth={1}
              borderColor="border"
              borderStyle="solid"
              borderRadius={4}
              mt={2}
              mb={2}
              p={2}
              textAlign="center"
            >
              <Text fontSize={18}>
                {`${datasetContents.count} items`}
              </Text>
            </Box>
            <DatasetContentList
              contents={datasetContents.contents}
              onUpdate={(d) => modalState.push({
                updateDatasetContent: true,
                datasetContent: d,
              })}
              onDelete={(d) => modalState.push({
                deleteDatasetContent: true,
                datasetContent: d,
              })}
            />
          </Box>
        )}

        {datasetContents && datasetContents.contents.length === 0 && (
          <Text color="muted" mb={2} mt={4}>
            {STRINGS.none}
          </Text>
        )}
      </Section>

      {updateDataset && (
        <CreateOrEditDataset
          create={false}
          dataset={dataset}
          onUpdate={(d) => setDataset(d)}
        />
      )}
      {deleteDataset && (
        <DeleteDataset
          dataset={dataset}
        />
      )}
      {createDatasetContent && (
        <CreateOrEditDatasetContent
          create
          dataset={dataset}
          onCreate={() => setDatasetContentsDependency(Time.now())}
        />
      )}
      {updateDatasetContent && (
        <CreateOrEditDatasetContent
          create={false}
          dataset={dataset}
          datasetContent={datasetContent}
          onUpdate={(c) => updateContent(c)}
        />
      )}
      {deleteDatasetContent && (
        <DeleteDatasetContent
          datasetContent={datasetContent}
          onDelete={() => removeContent(datasetContent.id)}
        />
      )}
    </Page>
  );
}

export default Dataset;
