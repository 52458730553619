import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import ReactJson from 'react-json-view';
import {
  Box, Field, Form, Input, Section,
} from 'controls';

import api from 'api';

function HackDetectImageTest(props) {
  const { skillId } = props;
  const [data, setData] = useState(null);

  return (
    <Section mt={4}>
      <Section mt={8}>
        <Form
          initialValues={{
            imageUri: '',
          }}
          validationSchema={Yup.object().shape({
            imageUri: Yup.string().required('Required'),
          })}
          onSubmit={async (values) => {
            const response = await api.hackPostImage(
              values.imageUri,
              skillId,
            );

            if (!response.error) {
              setData(response.data);
              return null;
            }

            return response.error.status.toString();
          }}
          action="Run"
          fields={() => (
            <Box mb={2}>
              <Field
                name="imageUri"
                component={(
                  <Input
                    placeholder="Enter an image URL"
                  />
                )}
              />
            </Box>
          )}
        />
      </Section>
      {data && (
        <Section mt={6}>
          <ReactJson
            src={data}
            displayDataTypes={false}
          />
        </Section>
      )}
    </Section>
  );
}

HackDetectImageTest.propTypes = {
  skillId: PropTypes.string.isRequired,
};

export default HackDetectImageTest;
