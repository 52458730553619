import React from 'react';
import {
  Modal, Page, useModalState, useParams, useQuery,
} from 'controls';
import ReactJson from 'react-json-view';

import WatchVideo from 'components/WatchVideo';

import ContentSection from './ContentSection';

function Content() {
  const params = useParams();
  const { type, id } = params;
  const frameType = params.frameType || 'all';
  const queryParams = useQuery();
  const layoutId = queryParams.get('layoutId') || '';
  const modalState = useModalState();
  const {
    showContentDetails, contentDetails, watchVideo,
  } = modalState.get();

  return (
    <Page mt={4}>
      <ContentSection
        id={id}
        type={type}
        frameType={frameType}
        onContentDetails={(content) => {
          modalState.push({
            showContentDetails: true,
            contentDetails: content,
          });
        }}
        onWatch={(content) => {
          modalState.push({
            watchVideo: true,
            contentDetails: content,
          });
        }}
        layoutId={layoutId}
      />

      {watchVideo && (
        <Modal
          title={contentDetails.id}
          width={['100%', '100%', '700px']}
          overlayStyle={{
            overflowX: 'auto',
            overflowY: 'scroll',
            position: 'fixed',
            zIndex: 11,
          }}
        >
          <WatchVideo video={contentDetails} />
        </Modal>
      )}
      {showContentDetails && (
        <Modal
          width={['100%', '100%', '700px']}
          title="Details"
          overlayStyle={{
            overflowX: 'auto',
            overflowY: 'scroll',
            position: 'fixed',
            zIndex: 11,
          }}
        >
          <ReactJson
            src={contentDetails}
            displayDataTypes={false}
          />
        </Modal>
      )}
    </Page>
  );
}

export default Content;
