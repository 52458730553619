import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Flex, stylex, Text,
} from 'controls';

import ComponentItem from './ComponentItem';

function ComponentList(props) {
  const {
    add, className, onAdd, onClick, components,
  } = props;

  return (
    <Flex
      className={className}
      flexWrap="wrap"
    >
      {components.map((component) => (
        <ComponentItem
          add={add}
          width="100%"
          key={component.id}
          component={component}
          onAdd={onAdd}
          onClick={onClick}
        />
      ))}

      {components.length === 0 && (
        <Box textAlign="center">
          <Text
            color="muted"
            text="No components found"
          />
        </Box>
      )}
    </Flex>
  );
}

ComponentList.propTypes = {
  add: PropTypes.bool,
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClick: PropTypes.func,
  components: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
};

ComponentList.defaultProps = {
  add: true,
  className: null,
  onAdd: null,
  onClick: null,
  components: null,
};

export default stylex(ComponentList);
