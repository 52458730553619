import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Card, Divider, Dropdown, EllipsisText, Flex,
  MenuItem, IconButton, Image, stylex, Text,
} from 'controls';

function DatasetContentItem(props) {
  const {
    className, index,
    onDetails,
    onUpdate, onDelete,
    content,
  } = props;

  const [contentState] = useState(content);

  return (
    <Box
      className={className}
      pl={index % 2 === 0 ? 0 : 1}
      pr={index % 2 === 0 ? 1 : 0}
      py={2}
    >
      <Card
        p={0}
      >
        <Flex pt={2} pb={2} px={2} justifyContent="space-between" alignItems="center">
          <Box>
            <Text
              fontSize="x-small"
              lineHeight="100%"
              text={contentState.id}
            />
          </Box>
          <Flex alignItems="center">
            <IconButton
              icon="list"
              color="muted"
              mr={2}
              onClick={() => onDetails(content)}
            />
            <Dropdown
              alignRight
              closeOnClick={false}
              toggle={(
                <IconButton p={1} color="muted" icon="ellipsis-v" />
              )}
              menuStyle={{
                marginTop: '5px',
              }}
              menuContent={(
                <>
                  <MenuItem
                    divider
                    fontSize="small"
                    py={2}
                    text="Update content"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (onUpdate) {
                        onUpdate();
                      }
                    }}
                  />
                  <MenuItem
                    fontSize="small"
                    py={2}
                    text="Delete content"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (onDelete) {
                        onDelete();
                      }
                    }}
                  />
                </>
              )}
            />
          </Flex>
        </Flex>
        {contentState.contentType === 'text' && (
          <>
            <Divider bg="bg-light" />
            <Box px={3} py={2} height="100px">
              <EllipsisText
                text={contentState.contentText}
              />
            </Box>
          </>
        )}
        {contentState.contentType === 'image' && (
          <>
            <Divider bg="bg-light" />
            <Box
              positive="relative"
            >
              <Image
                src={contentState.contentUri}
                alt={contentState.contentUri}
              />
            </Box>
          </>
        )}
      </Card>
    </Box>
  );
}

DatasetContentItem.propTypes = {
  className: PropTypes.string,
  content: PropTypes.shape({
    id: PropTypes.string,
    contentUri: PropTypes.string,
    contentText: PropTypes.string,
    contentType: PropTypes.string,
  }).isRequired,
  index: PropTypes.number,
  onDetails: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};

DatasetContentItem.defaultProps = {
  className: null,
  index: 0,
  onDetails: null,
  onUpdate: null,
  onDelete: null,
};

export default stylex(DatasetContentItem);
