import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Card, Divider, Dropdown, Flex, Form,
  Icon, IconButton, MenuItem, stylex, Text, Time,
} from 'controls';

import api from 'api';

import AnnotatedImage from './AnnotatedImage';

const STRINGS = {
  actions: {
    safe: 'Safe',
    unsafe: 'Flag',
    marked: 'Marked as safe',
    flagged: 'Flagged as unsafe',
  },
};

function FrameItem(props) {
  const {
    className, frame, index,
    onClick, onDetails, onPinned,
    onReviewChange, onReviewed, type,
    moderate,
  } = props;
  const [frameState, setFrame] = useState(frame);
  const [blur, setBlur] = useState(frameState.flagged);
  const [annotate, setAnnotate] = useState(true);

  const showAnnotateIcon = frameState && frameState.result
    && ((frameState.result.objects && frameState.result.objects.length > 0)
      || (frameState.result.faces && frameState.result.faces.length > 0)
      || (frameState.result.ocr && frameState.result.ocr.lines
        && frameState.result.ocr.lines.length > 0));

  return (
    <Box
      className={className}
      pl={index % 2 === 0 ? 0 : 1}
      pr={index % 2 === 0 ? 1 : 0}
      py={2}
    >
      <Card
        p={0}
      >
        <Flex pt={1} pb={2} px={2} justifyContent="space-between">
          <Box>
            {!onClick && (
              <Button
                fontSize="x-small"
                lineHeight="100%"
                format="inline"
                to={`/${type}/${frameState.contentId}`}
                text={frameState.contentId}
              />
            )}
            {onClick && (
              <Button
                fontSize="x-small"
                lineHeight="100%"
                format="inline"
                onClick={() => onClick(frameState.contentId)}
                text={frameState.contentId}
              />
            )}
            <Text
              fontSize="xxx-small"
              lineHeight="100%"
              color="muted"
              text="Frame"
            />
          </Box>
          <Flex alignItems="center">
            {showAnnotateIcon && (
              <IconButton
                icon={[annotate ? 'fas' : 'far', 'square']}
                color="border"
                mr={3}
                onClick={() => setAnnotate(!annotate)}
              />
            )}
            <IconButton
              icon="thumbtack"
              color={frameState.pinned ? 'primary' : 'border'}
              mr={3}
              onClick={async () => {
                const response = await api.updatePin(
                  type, frameState.contentId, frameState.id, !frameState.pinned,
                );
                if (!response.error) {
                  setFrame(response.data);
                  if (onPinned) {
                    onPinned(frameState.id, response.data.pinned);
                  }
                }
              }}
            />
            <IconButton
              icon="list"
              color="muted"
              mr={2}
              onClick={() => onDetails(frameState)}
            />

            <Dropdown
              alignRight
              closeOnClick={false}
              toggle={(
                <Button format="inline" color="muted" fontSize="small">
                  {Time.ago(frameState.createdAt)}
                </Button>
              )}
              menuContent={(
                <Text
                  p={1}
                  textAlign="center"
                  color="muted"
                  width="250px"
                >
                  {Time.format(frameState.createdAt)}
                </Text>
              )}
            />
          </Flex>
        </Flex>
        {frameState.channelType !== 'subtitles' && (
          <Box position="relative">
            <AnnotatedImage
              src={frameState.frameUri}
              blur={blur}
              alt={frameState.id}
              imageWidth={frameState.stats.width}
              imageHeight={frameState.stats.height}
              result={frameState.result}
              annotate={annotate}
            />

            {'timestamp' in frameState && (
              <Flex
                position="absolute"
                top="10px"
                left="10px"
                color="bg"
                bg="rgba(0, 0, 0, 0.5)"
                borderRadius={4}
                px={2}
                py="3px"
                fontSize="xxx-small"
                alignItems="center"
              >
                <Text
                  lineHeight="100%"
                  text={`${frameState.timestamp.toFixed(1)}s`}
                />
              </Flex>
            )}
            {frameState.result && frameState.result.overlay && (
              <Flex
                position="absolute"
                bottom="0px"
                right="0px"
                color="bg"
                bg="rgba(0, 0, 0, 0.5)"
                borderRadius={4}
                px={2}
                py="3px"
                fontSize="xxx-small"
                alignItems="center"
              >
                <Text
                  lineHeight="100%"
                  text={frameState.result.overlay}
                />
              </Flex>
            )}
          </Box>
        )}
        {frameState.channelType === 'subtitles' && (
          <Box position="relative">
            <Divider bg="bg-light" />
            {frameState.subtitles && frameState.subtitles.instances.map((subtitle) => (
              <Box p={2}>
                <Box fontSize="xxx-small" color="muted">
                  {subtitle.startTime.toFixed(2)}
                  {' --> '}
                  {subtitle.endTime.toFixed(2)}
                </Box>
                <Box>
                  {subtitle.lines.map((line) => (
                    <Box>
                      {line}
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
            {frameState.result && frameState.result.overlay && (
              <Flex
                position="absolute"
                bottom="0px"
                right="0px"
                color="bg"
                bg="rgba(0, 0, 0, 0.5)"
                borderRadius={4}
                px={2}
                py="3px"
                fontSize="xxx-small"
                alignItems="center"
              >
                <Text
                  lineHeight="100%"
                  text={frameState.result.overlay}
                />
              </Flex>
            )}
          </Box>
        )}
        {moderate && (
          <Flex pt={2} pb={2} px={2} height="40px" alignItems="center">
            {frameState.reviewed && (
              <Flex justifyContent="space-between" width="100%">
                {!frameState.flagged && (
                  <Text color="accent-dark">
                    <Icon icon="check" mr={2} />
                    {STRINGS.actions.marked}
                  </Text>
                )}
                {frameState.flagged && (
                  <Text color="error">
                    <Icon icon="exclamation-circle" mr={2} />
                    {STRINGS.actions.flagged}
                  </Text>
                )}
                <Dropdown
                  alignRight
                  menuStyle={{ overflow: 'visible' }}
                  toggle={(
                    <IconButton color="muted" icon="ellipsis-v" />
                  )}
                  menuContent={(
                    <>
                      <MenuItem
                        fontSize="small"
                        py={2}
                        text="Remove review"
                        onClick={async () => {
                          const response = await api.updateReview(
                            type, frameState.contentId, frameState.id, false, false,
                          );
                          if (!response.error) {
                            setFrame(response.data);
                            setBlur(false);
                            if (onReviewChange) {
                              onReviewChange();
                            }
                          }
                        }}
                      />
                      {frameState.flagged && blur && (
                        <MenuItem
                          fontSize="small"
                          py={2}
                          text="Unblur"
                          onClick={() => setBlur(false)}
                        />
                      )}
                      {frameState.flagged && !blur && (
                        <MenuItem
                          fontSize="small"
                          py={2}
                          text="Blur"
                          onClick={() => setBlur(true)}
                        />
                      )}
                    </>
                  )}
                />
              </Flex>
            )}
            {!frameState.reviewed && (
              <Flex justifyContent="space-between" width="100%">
                <Flex>
                  <Form
                    errorPosition="none"
                    onSubmit={async () => {
                      const response = await api.updateReview(
                        type, frameState.contentId, frameState.id, true, false,
                      );
                      if (!response.error) {
                        setFrame(response.data);
                        setBlur(false);
                        if (onReviewed) {
                          onReviewed(frameState.id, false);
                        }
                        if (onReviewChange) {
                          onReviewChange();
                        }
                      }
                      return null;
                    }}
                    action={(fprops) => (
                      <Button
                        type="submit"
                        width="80px"
                        py="2px"
                        fontSize="small"
                        variant="accent"
                        loading={fprops.isSubmitting}
                      >
                        <Icon icon="check" mr={2} />
                        {STRINGS.actions.safe}
                      </Button>
                    )}
                  />
                  <Form
                    ml={3}
                    errorPosition="none"
                    onSubmit={async () => {
                      const response = await api.updateReview(
                        type, frameState.contentId, frameState.id, true, true,
                      );
                      if (!response.error) {
                        setFrame(response.data);
                        setBlur(true);
                        if (onReviewed) {
                          onReviewed(frameState.id, true);
                        }
                        if (onReviewChange) {
                          onReviewChange();
                        }
                      }
                      return null;
                    }}
                    action={(fprops) => (
                      <Button
                        type="submit"
                        width="80px"
                        py="2px"
                        fontSize="small"
                        variant="error"
                        loading={fprops.isSubmitting}
                      >
                        <Icon icon="exclamation-circle" mr={2} />
                        {STRINGS.actions.unsafe}
                      </Button>
                    )}
                  />
                </Flex>
              </Flex>
            )}
          </Flex>
        )}
      </Card>
    </Box>
  );
}

FrameItem.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number,
  onClick: PropTypes.func,
  onDetails: PropTypes.func,
  onPinned: PropTypes.func,
  onReviewChange: PropTypes.func,
  onReviewed: PropTypes.func,
  frame: PropTypes.shape({
    frameImageUrl: PropTypes.string,
  }).isRequired,
  type: PropTypes.string,
  moderate: PropTypes.bool,
};

FrameItem.defaultProps = {
  className: null,
  index: 0,
  onClick: null,
  onDetails: null,
  onPinned: null,
  onReviewChange: null,
  onReviewed: null,
  type: null,
  moderate: false,
};

export default stylex(FrameItem);
