import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Box, Button, CodeInput, Divider, Dropdown, Flex, Field, Form, IconButton, Input, MenuItem, Modal,
  Section, stylex, Text, Time, useApi, useModalState, Select,
} from 'controls';
import { useHistory } from 'react-router-dom';

import api from 'api';
import Collapsible from 'components/Collapsible';
import ComponentDetails from 'components/ComponentDetails';
import ComponentSection from 'components/ComponentSection';
import SkillComponentList from 'components/SkillComponentList';
import Utils from 'components/Utils';
import SkillUtils from 'components/SkillUtils';
import EditHandle from 'pages/EditHandle';
import EditComponentProperties from 'pages/EditComponentProperties';
import TestContent from 'pages/TestContent';

import CreateSkill from './CreateSkill';
import CreateNewerVersionedSkill from './CreateNewerVersionedSkill';
import DeleteSkill from './DeleteSkill';
import SetDefaultSkill from './SetDefaultSkill';
import SetActiveSkill from './SetActiveSkill';
import ProcessRequestTemplate from './ProcessRequestTemplate';

function GetComponentReferences(components) {
  const componentReferences = components.map((c) => {
    const properties = c.properties.map(
      (p) => ({ name: p.property.name, value: p.value }),
    );
    return { componentId: c.component.id, handle: c.handle, properties };
  });
  return componentReferences;
}

function GetSkillVersionsDropdown(skillVersionsData, activeSkillData) {
  const skillVersionsDropdown = skillVersionsData.map((x) => {
    const activeSkillStr = x.version === activeSkillData.version ? '(active) ' : '';
    return { value: x.version, label: `Version ${x.version} ${activeSkillStr}- ${x.name}` };
  });
  return skillVersionsDropdown;
}

function SkillSection(props) {
  const [propertiesValid, setPropertiesValid] = useState(true);
  const [editedComponentsSaved, setEditedComponentsSaved] = useState(true);
  const [initCodeValid, setInitCodeValid] = useState(true);
  const [sampleCodeValid, setSampleCodeValid] = useState(true);
  const [pfCodeValid, setPfCodeValid] = useState(true);
  const [ptCodeValid, setPtCodeValid] = useState(true);
  const [ptbCodeValid, setPtbCodeValid] = useState(true);
  const [audioCodeValid, setAudioCodeValid] = useState(true);
  const [customCodeValid, setCustomCodeValid] = useState(true);
  const [sfCodeValid, setSfCodeValid] = useState(true);
  const [afCodeValid, setAfCodeValid] = useState(true);
  const history = useHistory();
  const modalState = useModalState();
  const {
    addComponent, editHandle, editProperties,
    showSkillComponent, skillComponent,
    createSkill, deleteSkill, setDefaultSkill,
    cloneSkillName, testSkill, createNewerVersionedSkill,
    activateSkill, processRequestTemplate, setDefault, skillVersion,
  } = modalState.get();

  const {
    type, skillName, paramSkillVersion, hideTitlebar, className,
  } = props;
  let skillNameUsed = null;
  let isActiveSkill = true;
  const {
    data, setData, isLoading,
  } = useApi(async () => api.getSkill(type, skillName, paramSkillVersion));

  const {
    data: skillVersionsData,
    isLoading: skillVersionsLoading,
    setDependency: skillVersionsSetDependency,
  } = useApi(async () => api.listSkillVersions(type, skillName), 0);

  const {
    data: activeSkillData,
    isLoading: activeSkillIsLoading,
  } = useApi(async () => api.getSkill(type, skillName, null));

  let skillVersionsDropdownData = null;
  let nextHigherSkillVersion = 0;
  if (skillVersionsData !== null && activeSkillData !== null) {
    skillVersionsDropdownData = GetSkillVersionsDropdown(skillVersionsData, activeSkillData);
    nextHigherSkillVersion = skillVersionsDropdownData[0].value + 1;
  }

  // Determine the skill's activeness and display Inactive/Active
  // label depending on the isActive Result
  if (!activeSkillIsLoading && activeSkillData !== null && data !== null) {
    isActiveSkill = activeSkillData.version === data.version;
  }

  let saveButtonIsEnabled = true;
  let mediaType = true;
  if (type === 'texts' || type === 'audios' || type === 'customs') {
    mediaType = false;
  }

  if (data) {
    data.properties = data.properties || {};
    data.properties.resizeFrameHeight = data.properties.resizeFrameHeight || '';
    data.properties.minStreamResolution = data.properties.minStreamResolution || '';
    saveButtonIsEnabled = propertiesValid && initCodeValid
      && sampleCodeValid && pfCodeValid && ptCodeValid && ptbCodeValid
      && audioCodeValid && sfCodeValid && afCodeValid && customCodeValid;
    skillNameUsed = SkillUtils.determineSkillName(data);
  }

  return (
    <Section className={className}>
      <Section mt={4} loading={isLoading}>
        {data && (
          <Form
            initialValues={{
              name: data.name,
              components: data.components,
              initScript: data.initScript,
              sampleFrameScript: data.sampleFrameScript,
              processFrameScript: data.processFrameScript,
              processFrameBatchScript: data.processFrameBatchScript,
              aggregateFramesScript: data.aggregateFramesScript,
              storeFrameScript: data.storeFrameScript,
              processTextScript: data.processTextScript,
              processTextBatchScript: data.processTextBatchScript,
              processAudioScript: data.processAudioScript,
              processScript: data.processScript,
              resizeFrameHeight: data.properties.resizeFrameHeight,
              minStreamResolution: data.properties.minStreamResolution,
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Required'),
            })}
            action={(fprops) => (
              <Box>
                {(data.name !== fprops.values.name
                  || data.components.length !== fprops.values.components.length
                  || data.initScript !== fprops.values.initScript
                  || data.sampleFrameScript !== fprops.values.sampleFrameScript
                  || data.processFrameScript !== fprops.values.processFrameScript
                  || data.processFrameBatchScript !== fprops.values.processFrameBatchScript
                  || data.aggregateFramesScript !== fprops.values.aggregateFramesScript
                  || data.storeFrameScript !== fprops.values.storeFrameScript
                  || data.processTextScript !== fprops.values.processTextScript
                  || data.processTextBatchScript !== fprops.values.processTextBatchScript
                  || data.processAudioScript !== fprops.values.processAudioScript
                  || data.processScript !== fprops.values.processScript
                  || data.properties.resizeFrameHeight !== fprops.values.resizeFrameHeight
                  || data.properties.minStreamResolution !== fprops.values.minStreamResolution)
                  && (
                    <Text
                      mb={2}
                      color="error"
                      text="You have unsaved changes, please save to keep changes. Clone skill and create newer version will not save the edited skill until skill is saved"
                    />
                  )}

                {(!saveButtonIsEnabled)
                  && (
                    <Text
                      mb={2}
                      color="error"
                      text="You have syntax errors or unset properties, please fix before saving"
                    />
                  )}

                {(!editedComponentsSaved)
                  && (
                    <Text
                      mb={2}
                      color="error"
                      text="You have unsaved property changes, please save to keep changes. Clone skill and create newer version are disabled until skill is saved"
                    />
                  )}

                {!data.managed && (
                  <Button
                    fontSize={18}
                    px={4}
                    mr={2}
                    text="Save"
                    type="submit"
                    loading={fprops.isSubmitting}
                    disabled={data.managed || !saveButtonIsEnabled}
                    onClick={() => {
                      setEditedComponentsSaved(true);
                    }}
                  />
                )}

                <Button
                  format="outline"
                  color="primary"
                  fontSize={18}
                  px={4}
                  mr={2}
                  text="Test Skill"
                  onClick={() => {
                    modalState.push({
                      testSkill: true,
                      skillId: data.id,
                    });
                  }}
                />
                <Text
                  mt={2}
                  fontSize={12}
                  color="muted"
                  text={`Updated: ${Time.format(data.updatedAt, 'MMM DD, YYYY LTS')}`}
                />
              </Box>
            )}
            onSubmit={async (values) => {
              const componentReferences = GetComponentReferences(values.components);
              const response = await api.updateSkill(
                type,
                skillNameUsed,
                data.version,
                values.name,
                componentReferences,
                values.initScript,
                values.sampleFrameScript,
                values.processFrameScript,
                values.processFrameBatchScript,
                values.aggregateFramesScript,
                values.storeFrameScript,
                values.processTextScript,
                values.processTextBatchScript,
                values.processAudioScript,
                values.processScript,
                {
                  resizeFrameHeight: values.resizeFrameHeight,
                  minStreamResolution: values.minStreamResolution,
                },
              );

              if (!response.error) {
                setData(response.data);
                skillVersionsSetDependency(Time.now());
              }

              return null;
            }}
            fields={(fprops) => (
              <Box mb={4}>
                {!hideTitlebar && (
                  <Flex
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Box>
                      <Flex
                        alignItems="center"
                      >
                        <Text
                          text={`${Utils.toPascalCase(data.contentType)} Skill Name:`}
                          fontWeight="bold"
                        />
                        <Text
                          text={`${skillNameUsed} `}
                          ml={2}
                          fontWeight="bold"
                        />
                        {data.default && (
                          <Flex ml={3}>
                            <Text
                              bg="muted"
                              color="bg"
                              borderRadius={4}
                              text="Default Skill"
                              px={2}
                              fontSize={12}
                              height={18}
                            />
                          </Flex>
                        )}
                      </Flex>
                      {data.managed && (
                        <Text
                          display="inline-block"
                          color="error"
                          text="Non-editable"
                          fontSize={10}
                          lineHeight="100%"
                        />
                      )}
                    </Box>
                    <Box>
                      <Dropdown
                        alignRight
                        closeOnClick={false}
                        toggle={(
                          <IconButton p={1} color="muted" icon="ellipsis-v" />
                        )}
                        menuStyle={{
                          marginTop: '5px',
                        }}
                        menuContent={(
                          <>
                            {skillNameUsed !== null && !data.default
                              && isActiveSkill && (
                              <MenuItem
                                divider
                                fontSize="small"
                                py={2}
                                text="Set as default"
                                onClick={() => modalState.push({
                                  setDefaultSkill: true,
                                  skillName: skillNameUsed,
                                  skillVersion: data.version === 0 ? null : data.version,
                                })}
                              />
                            )}
                            {skillNameUsed !== null && !activeSkillIsLoading && !isActiveSkill
                              && skillVersionsData && (
                                <MenuItem
                                  divider={!data.managed && !data.default}
                                  fontSize="small"
                                  py={2}
                                  text="Activate version"
                                  onClick={() => modalState.push({
                                    activateSkill: true,
                                    skillName: skillNameUsed,
                                    skillVersion: data.version,
                                    setDefault: skillVersionsData.some((svd) => svd.default),
                                  })}
                                />
                            )}
                            {skillNameUsed !== null && data && (
                              <MenuItem
                                fontSize="small"
                                disabled={!editedComponentsSaved}
                                py={2}
                                text="Clone skill"
                                onClick={() => modalState.push({
                                  createSkill: true,
                                  cloneSkillName: skillNameUsed,
                                  skillVersion: data.version,
                                })}
                              />
                            )}

                            {!data.managed && data.skillName && (
                              <MenuItem
                                fontSize="small"
                                py={2}
                                text="Create version..."
                                disabled={!editedComponentsSaved}
                                onClick={async () => {
                                  modalState.push({
                                    createNewerVersionedSkill: true,
                                  });

                                  return null;
                                }}
                              />
                            )}
                            {skillNameUsed !== null && !data.managed && !data.default && (
                              <MenuItem
                                fontSize="small"
                                py={2}
                                text="Delete version"
                                onClick={() => modalState.push({
                                  deleteSkill: true,
                                  skillName: skillNameUsed,
                                  skillVersion: data.version,
                                })}
                              />
                            )}
                          </>
                        )}
                      />
                    </Box>
                  </Flex>
                )}
                <Flex justifyContent="space-between" alignItems="center">
                  {data && data.skillName && (
                    <Section
                      loading={skillVersionsLoading}
                    >
                      {skillVersionsDropdownData && (
                        <Select
                          width="400px"
                          mt={3}
                          value={data.version}
                          options={skillVersionsDropdownData}
                          onChange={(e) => {
                            history.push(`/${type}/skills/${skillName}/versions/${e.target.value}`);
                          }}
                        />
                      )}
                    </Section>
                  )}
                  <Button
                    fontSize={12}
                    mt={3}
                    format="inline"
                    text="View Request Template"
                    onClick={() => modalState.push({
                      processRequestTemplate: true,
                    })}
                  />
                </Flex>
                {!hideTitlebar && (
                  <Field
                    mt={4}
                    name="name"
                    component={(
                      <Input
                        fontSize={18}
                        placeholder="Skill name"
                        disabled={data.managed}
                      />
                    )}
                  />
                )}
                {mediaType && (
                  <Collapsible mt={4} title="Properties" defaultToggle={false}>
                    <Box mt={3} mb={1}>
                      <Divider style={{ opacity: 0.5 }} />
                    </Box>
                    {data.contentType === 'stream' && (
                      <Field
                        label="Minimum stream resolution to fetch from HLS (e.g., 480)"
                        name="minStreamResolution"
                        component={(
                          <Input type="number" disabled={data.managed} />
                        )}
                      />
                    )}
                    <Field
                      label="Resize frame to the specified height before process method (e.g., 480; null for no resizing)"
                      name="resizeFrameHeight"
                      component={(
                        <Input type="number" disabled={data.managed} />
                      )}
                    />
                  </Collapsible>
                )}
                <Collapsible mt={4} title="Components">
                  <Box mt={3} mb={1}>
                    <Divider style={{ opacity: 0.5 }} />
                  </Box>
                  <SkillComponentList
                    disabled={data.managed}
                    skillComponents={fprops.values.components}
                    onClick={(sc) => modalState.push(
                      {
                        showSkillComponent: true,
                        skillComponent: sc,
                      },
                    )}
                    onEditHandle={(sc) => modalState.push(
                      {
                        editHandle: true,
                        skillComponent: sc,
                      },
                    )}
                    onEditProperties={(sc) => modalState.push(
                      {
                        editProperties: true,
                        skillComponent: sc,
                      },
                    )}
                    onRemove={(sc, index) => {
                      const newComponents = fprops.values.components.filter(
                        (c, i) => !(c.component.id === sc.component.id && i === index),
                      );
                      fprops.setFieldValue('components', newComponents);
                    }}
                  />
                  <Button
                    mt={4}
                    mb={2}
                    format="inline"
                    text="+ Add detection component"
                    onClick={() => modalState.push({ addComponent: true })}
                    disabled={data.managed}
                  />
                </Collapsible>

                {addComponent && (
                  <Modal
                    overflow="visible"
                    title="Repository of Detection Components"
                    width={['100%', '576px', '700px']}
                    overlayStyle={{
                      overflowX: 'auto',
                      overflowY: 'scroll',
                      position: 'fixed',
                      zIndex: 11,
                    }}
                  >
                    <ComponentSection
                      onAdd={(c) => {
                        let componentExists = false;
                        const newComponents = fprops.values.components.map((comp) => {
                          if (c.id === comp.component.id) {
                            componentExists = true;
                          }

                          return comp;
                        });

                        let handle = c.defaultHandle;
                        if (componentExists) {
                          handle += Math.floor(Math.random() * 1000);
                        }

                        const newComponent = {
                          component: c, handle, properties: [],
                        };

                        let componentValid = true;
                        for (let i = 0; i < c.properties.length; i += 1) {
                          newComponent.properties.push({
                            property: c.properties[i],
                            value: c.properties[i].defaultValue,
                            valid: true,
                          });

                          if (c.properties[i].required) {
                            newComponent.properties[i].valid = false;
                            componentValid = false;
                          }
                        }

                        newComponent.valid = componentValid;

                        newComponents.push(newComponent);
                        fprops.setFieldValue('components', newComponents);
                        history.goBack();
                      }}
                      onClick={(c) => modalState.push(
                        {
                          showSkillComponent: true,
                          skillComponent: { component: c },
                        },
                      )}
                    />
                  </Modal>
                )}

                {editHandle && (
                  <EditHandle
                    skillComponent={skillComponent}
                    onEditHandle={(sc, handle) => {
                      const newComponents = fprops.values.components.map((v) => {
                        if (v.handle === sc.handle) {
                          return { ...v, handle };
                        }

                        return v;
                      });

                      fprops.setFieldValue('components', newComponents);
                    }}
                  />
                )}

                {editProperties && (
                  <EditComponentProperties
                    skillComponent={skillComponent}
                    onEditProperties={(sc, values) => {
                      let valid = true;
                      const newProperties = sc.properties.map((property) => {
                        const propertyValid = !property.property.required
                          || !(values[property.property.name] == null || values[property.property.name] === '');
                        valid = valid && propertyValid;
                        return {
                          ...property,
                          value: values[property.property.name],
                          valid: propertyValid,
                        };
                      });

                      setPropertiesValid(newProperties.every((property) => property.valid));
                      const newComponents = fprops.values.components.map((v) => {
                        if (v.component.id === sc.component.id && v.handle === sc.handle) {
                          return { ...v, properties: newProperties, valid };
                        }

                        return v;
                      });

                      fprops.setFieldValue('components', newComponents);
                      setEditedComponentsSaved(false);
                    }}
                  />
                )}

                {(data.contentType === 'video' || data.contentType === 'stream' || data.contentType === 'gif') && (
                  <Collapsible mt={4} title="Init">
                    <Flex>
                      <Text
                        color="muted"
                        fontSize={13}
                        text="Write a script to intialize and reset state."
                      />
                    </Flex>
                    <Field
                      mt={2}
                      name="initScript"
                      component={
                        (
                          <CodeInput
                            minLines={6}
                            readOnly={data.managed}
                            onValidate={(e) => setInitCodeValid(e)}
                          />
                        )
                      }
                    />
                  </Collapsible>
                )}
                {(data.contentType === 'video' || data.contentType === 'stream' || data.contentType === 'gif') && (
                  <Collapsible mt={4} title="Sample frame">
                    <Flex>
                      {/* eslint-disable no-nested-ternary */}
                      <Text
                        color="muted"
                        fontSize={13}
                        text={`Write a script to specify how to sample frames from a ${data.contentType === 'video' ? 'video.' : data.contentType === 'gif' ? 'gif.' : 'live stream.'}`}
                      />
                      {/* eslint-enable no-nested-ternary */}
                    </Flex>
                    <Field
                      mt={2}
                      name="sampleFrameScript"
                      component={
                        (
                          <CodeInput
                            minLines={6}
                            readOnly={data.managed}
                            onValidate={(e) => setSampleCodeValid(e)}
                          />
                        )
                      }
                    />
                  </Collapsible>
                )}
                {mediaType && (
                  <Collapsible mt={4} title={`Process ${data.contentType === 'image' ? 'image' : 'frame'}`}>
                    <Flex>
                      <Text
                        color="muted"
                        fontSize={13}
                        text={`Write a script to process the ${data.contentType === 'image' ? 'image' : 'frame'} and return the result.`}
                      />
                    </Flex>

                    <Field
                      mt={2}
                      name="processFrameScript"
                      component={
                        (
                          <CodeInput
                            minLines={6}
                            readOnly={data.managed}
                            onValidate={(e) => setPfCodeValid(e)}
                          />
                        )
                      }
                    />
                  </Collapsible>
                )}
                {data.contentType === 'image' && (
                  <Collapsible mt={4} title="Process image batch">
                    <Flex>
                      <Text
                        color="muted"
                        fontSize={13}
                        text="Write a script to process the image batch and return the result."
                      />
                    </Flex>

                    <Field
                      mt={2}
                      name="processFrameBatchScript"
                      component={
                        (
                          <CodeInput
                            minLines={6}
                            readOnly={data.managed}
                            onValidate={(e) => setPfCodeValid(e)}
                          />
                        )
                      }
                    />
                  </Collapsible>
                )}
                {data.contentType === 'text' && (
                  <Collapsible mt={4} title="Process text">
                    <Flex>
                      <Text
                        color="muted"
                        fontSize={13}
                        text="Write a script to process the text and return the result"
                      />
                    </Flex>

                    <Field
                      mt={2}
                      name="processTextScript"
                      component={
                        (
                          <CodeInput
                            minLines={6}
                            readOnly={data.managed}
                            onValidate={(e) => setPtCodeValid(e)}
                          />
                        )
                      }
                    />
                  </Collapsible>
                )}
                {data.contentType === 'text' && (
                  <Collapsible mt={4} title="Process text batch">
                    <Flex>
                      <Text
                        color="muted"
                        fontSize={13}
                        text="Write a script to process the batch of text and return the result"
                      />
                    </Flex>

                    <Field
                      mt={2}
                      name="processTextBatchScript"
                      component={
                        (
                          <CodeInput
                            minLines={6}
                            readOnly={data.managed}
                            onValidate={(e) => setPtbCodeValid(e)}
                          />
                        )
                      }
                    />
                  </Collapsible>
                )}
                {data.contentType === 'audio' && (
                  <Collapsible mt={4} title="Process audio">
                    <Flex>
                      <Text
                        color="muted"
                        fontSize={13}
                        text="Write a script to process the audio and return the result"
                      />
                    </Flex>

                    <Field
                      mt={2}
                      name="processAudioScript"
                      component={
                        (
                          <CodeInput
                            minLines={6}
                            readOnly={data.managed}
                            onValidate={(e) => setAudioCodeValid(e)}
                          />
                        )
                      }
                    />
                  </Collapsible>
                )}
                {data.contentType === 'custom' && (
                  <Collapsible mt={4} title="Process custom">
                    <Flex>
                      <Text
                        color="muted"
                        fontSize={13}
                        text="Write a script to process data"
                      />
                    </Flex>
                    <Field
                      mt={2}
                      name="processScript"
                      component={
                        (
                          <CodeInput
                            minLines={6}
                            readOnly={data.managed}
                            onValidate={(e) => setCustomCodeValid(e)}
                          />
                        )
                      }
                    />
                  </Collapsible>
                )}
                {(data.contentType === 'video' || data.contentType === 'stream' || data.contentType === 'gif') && (
                  <Collapsible mt={4} title="Store frame">
                    <Flex>
                      <Text
                        color="muted"
                        fontSize={13}
                        text="Write a script to specify whether frame needs to be stored."
                      />
                    </Flex>
                    <Field
                      mt={2}
                      name="storeFrameScript"
                      component={
                        (
                          <CodeInput
                            minLines={6}
                            readOnly={data.managed}
                            onValidate={(e) => setSfCodeValid(e)}
                          />
                        )
                      }
                    />
                  </Collapsible>
                )}
                {(data.contentType === 'video' || data.contentType === 'gif') && (
                  <Collapsible mt={4} title="Aggregate frames">
                    <Flex>
                      <Text
                        color="muted"
                        fontSize={13}
                        text="Write a script to aggregate frame results."
                      />
                    </Flex>

                    <Field
                      mt={2}
                      name="aggregateFramesScript"
                      component={
                        (
                          <CodeInput
                            minLines={6}
                            readOnly={data.managed}
                            onValidate={(e) => setAfCodeValid(e)}
                          />
                        )
                      }
                    />
                  </Collapsible>
                )}
              </Box>
            )}
          />
        )}
      </Section>
      {showSkillComponent && (
        <Modal
          title="Model/Component"
          width={['100%', '576px', '700px']}
          overlayStyle={{
            overflowX: 'auto',
            overflowY: 'scroll',
            position: 'fixed',
            zIndex: 11,
          }}
        >
          <ComponentDetails
            component={skillComponent.component}
            handle={skillComponent.handle}
          />
        </Modal>
      )}
      {testSkill && data && skillNameUsed && (
        <Modal
          title="Test Skill"
          width={['100%', '576px', '700px']}
          overlayStyle={{
            overflowX: 'auto',
            overflowY: 'scroll',
            position: 'fixed',
            zIndex: 11,
          }}
        >
          <TestContent
            type={type}
            skillIdFromSkillPage={data.skillName ? null : data.id}
            skillNameFromSkillPage={data.skillName}
            skillVersionFromSkillPage={data.version}
          />
        </Modal>
      )}
      {createNewerVersionedSkill && data && skillVersionsDropdownData && (
        <CreateNewerVersionedSkill
          type={type}
          skillName={skillNameUsed}
          newSkillVersion={nextHigherSkillVersion}
          cloneSkillVersion={data.version}
          onCreateNewerVersionedSkill={() => {
            modalState.push({ createNewerVersionedSkill: false });
          }}
        />
      )}
      {processRequestTemplate && data && (
        <ProcessRequestTemplate
          type={type}
          skillId={data.id}
          skillName={data.skillName}
          skillVersion={data.version}
          isActiveSkill={isActiveSkill}
        />
      )}
      {createSkill && data && (
        <CreateSkill
          type={type}
          cloneSkillName={cloneSkillName}
          skillVersion={data.version}
        />
      )}
      {deleteSkill && data && (
        <DeleteSkill
          type={type}
          skillName={skillName}
          skillVersion={data.version}
        />
      )}
      {setDefaultSkill && data && (
        <SetDefaultSkill
          type={type}
          skillName={skillName}
          skillVersion={skillVersion}
          onSetDefault={() => setData({ ...data, default: true })}
        />
      )}
      {activateSkill && !activeSkillIsLoading && !isActiveSkill && data && (
        <SetActiveSkill
          type={type}
          skillName={skillName}
          skillVersion={data.version}
          setDefault={setDefault}
          onSetActive={() => modalState.push({ activateSkill: false })}
        />
      )}
    </Section>
  );
}

SkillSection.propTypes = {
  type: PropTypes.string.isRequired,
  skillName: PropTypes.string.isRequired,
  paramSkillVersion: PropTypes.string,
  hideTitlebar: PropTypes.bool,
  className: PropTypes.string,
};

SkillSection.defaultProps = {
  hideTitlebar: false,
  className: null,
  paramSkillVersion: null,
};

export default stylex(SkillSection);
