import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Divider, Dropdown, Flex, IconButton, MenuItem,
  Page, Section, Text, Time, useApi, useModalState,
} from 'controls';

import api from 'api';
import Utils from 'components/Utils';
import CreateOrEditDataset from './CreateOrEditDataset';
import DeleteDataset from './DeleteDataset';

const DatasetItem = (props) => {
  const {
    dataset,
    onUpdate,
    onDelete,
  } = props;

  return (
    <Box
      pt={3}
    >
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box>
          <Flex alignItems="center" width="100%">
            <Button
              format="inline"
              text={dataset.name}
              to={`/datasets/${dataset.id}`}
            />
            <Text
              ml={2}
              borderRadius={4}
              bg="border"
              px={2}
              fontSize={12}
              text={Utils.toPascalCase(dataset.contentType)}
            />
          </Flex>
          <Text
            fontSize={14}
            color="muted"
            text={dataset.id}
          />
        </Box>
        <Box>
          <Dropdown
            alignRight
            closeOnClick={false}
            toggle={(
              <IconButton p={1} color="muted" icon="ellipsis-v" />
            )}
            menuStyle={{
              marginTop: '5px',
            }}
            menuContent={(
              <>
                <MenuItem
                  divider
                  fontSize="small"
                  py={2}
                  text="Update dataset"
                  onClick={(e) => {
                    e.stopPropagation();
                    onUpdate();
                  }}
                />
                <MenuItem
                  fontSize="small"
                  py={2}
                  text="Delete dataset"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete();
                  }}
                />
              </>
            )}
          />
        </Box>
      </Flex>
      <Divider mt={3} />
    </Box>
  );
};

DatasetItem.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  dataset: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    contentType: PropTypes.string,
  }).isRequired,
};

function Datasets() {
  const {
    data, isLoading, setDependency,
  } = useApi(async () => api.getDatasets(), 0);

  const modalState = useModalState();
  const {
    createDataset, updateDataset,
    deleteDataset, dataset,
  } = modalState.get();

  return (
    <Page mt={2}>
      <Section pt={6}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <Text
            fontWeight="bold"
            text="Datasets"
          />

          <Button
            format="inline"
            text="+ Create dataset"
            onClick={() => modalState.push({
              createDataset: true,
            })}
          />
        </Flex>
        <Divider />
      </Section>
      <Section loading={isLoading}>
        {data && data.map((d) => (
          <DatasetItem
            dataset={d}
            key={d.id}
            onUpdate={() => modalState.push({
              updateDataset: true,
              dataset: d,
            })}
            onDelete={() => modalState.push({
              deleteDataset: true,
              dataset: d,
            })}
          />
        ))}
        {data && data.length === 0 && (
          <Box mt={6} textAlign="center">
            <Text
              color="muted"
              text="No datasets yet"
            />
          </Box>
        )}
      </Section>
      {createDataset && (
        <CreateOrEditDataset
          create
          onUpdate={() => setDependency(Time.now())}
        />
      )}
      {updateDataset && (
        <CreateOrEditDataset
          create={false}
          dataset={dataset}
          onUpdate={() => setDependency(Time.now())}
        />
      )}
      {deleteDataset && (
        <DeleteDataset
          dataset={dataset}
          onDelete={() => setDependency(Time.now())}
        />
      )}
    </Page>
  );
}

export default Datasets;
