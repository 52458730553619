import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Box, Field, Form, Input, Modal, useHistory,
} from 'controls';

function EditHandle(props) {
  const { onEditHandle, skillComponent } = props;
  const history = useHistory();

  return (
    <Modal
      title="Edit handle"
      width={['100%', '100%', '500px']}
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      <Form
        initialValues={{
          handle: skillComponent.handle,
        }}
        validationSchema={Yup.object().shape({
          handle: Yup.string().required('Required'),
        })}
        onSubmit={async (values) => {
          if (onEditHandle) {
            onEditHandle(skillComponent, values.handle);
            history.goBack();
          }

          return null;
        }}
        action="Update"
        fields={() => (
          <Box>
            <Field
              label={`${skillComponent.component.name} handle`}
              name="handle"
              component={(
                <Input
                  placeholder="Handle"
                />
              )}
            />
          </Box>
        )}
      />
    </Modal>
  );
}

EditHandle.propTypes = {
  onEditHandle: PropTypes.func,
  skillComponent: PropTypes.shape({
    component: PropTypes.shape({
      name: PropTypes.string,
    }),
    handle: PropTypes.string,
  }).isRequired,
};

EditHandle.defaultProps = {
  onEditHandle: null,
};

export default EditHandle;
