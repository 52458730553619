import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Form, Modal, Text, useHistory,
} from 'controls';

import api from 'api';

function DeleteSkill(props) {
  const {
    type, skillName, onDelete, batchDelete, skillVersion,
  } = props;

  const history = useHistory();
  let title = null;
  let warningTxt = null;
  if (batchDelete) {
    title = `Delete all versioned skill(s) with skill name ${skillName}`;
    warningTxt = `Are you sure you want to delete all versioned skill(s) with skill name ${skillName}?`;
  } else {
    title = `Delete skill ${skillName} version ${skillVersion}`;
    warningTxt = `Are you sure you want to delete skill ${skillName} version ${skillVersion}?`;
  }

  return (
    <Modal
      title={title}
      width={['100%', '100%', '500px']}
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      <Form
        onSubmit={async () => {
          const response = await api.deleteSkill(type, skillName, skillVersion);
          if (!response.error) {
            if (onDelete) {
              onDelete();
            }

            history.push(`/${type}/skills`);
            return null;
          }

          if (response.error.status === 409) {
            return 'Default and managed skills cannot be deleted';
          }

          if (response.error.status === 404) {
            return 'Skill not found';
          }

          return null;
        }}
        action="Delete"
        actionVariant="error"
        fields={() => (
          <Box>
            <Text
              mb={2}
              color="muted"
              text={warningTxt}
            />
          </Box>
        )}
      />
    </Modal>
  );
}

DeleteSkill.propTypes = {
  onDelete: PropTypes.func,
  skillName: PropTypes.string,
  batchDelete: PropTypes.bool,
  skillVersion: PropTypes.number,
  type: PropTypes.string.isRequired,
};

DeleteSkill.defaultProps = {
  onDelete: null,
  skillName: null,
  skillVersion: null,
  batchDelete: false,
};

export default DeleteSkill;
