import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Form, Modal, Text, useHistory,
} from 'controls';

import api from 'api';

function DeleteLayout(props) {
  const {
    layout, onDelete,
  } = props;
  const history = useHistory();

  return (
    <Modal
      title={`Delete layout ${layout.id}`}
      width={['100%', '100%', '500px']}
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      <Form
        onSubmit={async () => {
          const response = await api.deleteLayout(layout.id);
          if (!response.error) {
            if (onDelete) {
              onDelete();
            }

            history.goBack();
            return null;
          }

          if (response.error.status === 404) {
            return 'Layout not found';
          }

          return null;
        }}
        action="Delete"
        actionVariant="error"
        fields={() => (
          <Box>
            <Text
              mb={2}
              color="muted"
              text={`Are you sure you want to delete layout ${layout.id}?`}
            />
          </Box>
        )}
      />
    </Modal>
  );
}

DeleteLayout.propTypes = {
  layout: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func,
};

DeleteLayout.defaultProps = {
  onDelete: null,
};

export default DeleteLayout;
