import React from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import {
  Flex, Modal, useModalState,
} from 'controls';

import DatasetContentItem from './DatasetContentItem';
import CustomLayout from './CustomLayout';

function DatasetContentList(props) {
  const {
    contents, contentLayout,
    onUpdate, onDelete,
  } = props;

  const modalState = useModalState();
  const { showDetails, details } = modalState.get();

  return (
    <>
      {!contentLayout && (
        <Flex flexWrap="wrap">
          {contents.map((content, index) => (
            <DatasetContentItem
              index={index}
              width={['100%', '100%', '50%']}
              key={content.id}
              content={content}
              onDetails={(d) => {
                modalState.push({
                  showDetails: true,
                  details: d,
                });
              }}
              onUpdate={() => onUpdate(content)}
              onDelete={() => onDelete(content)}
            />
          ))}
        </Flex>
      )}
      {contentLayout && (
        <CustomLayout
          data={contents}
          layout={contentLayout}
          onDetails={(d) => {
            modalState.push({
              showDetails: true,
              details: d,
            });
          }}
        />
      )}
      {showDetails && (
        <Modal
          width={['100%', '100%', '700px']}
          title="Details"
          overlayStyle={{
            overflowX: 'auto',
            overflowY: 'scroll',
            position: 'fixed',
            zIndex: 11,
          }}
        >
          <ReactJson
            src={details}
            displayDataTypes={false}
          />
        </Modal>
      )}
    </>
  );
}

DatasetContentList.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  contentLayout: PropTypes.shape({}),
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};

DatasetContentList.defaultProps = {
  contents: null,
  contentLayout: null,
  onUpdate: null,
  onDelete: null,
};

export default DatasetContentList;
