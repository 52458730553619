import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import {
  Box, Button, Flex, Input, Label, Modal, Section, stylex, Tabs, TabItem, Time,
  Text, Textarea, useApi, useHistory, useModalState,
} from 'controls';

import api from 'api';
import FrameList from 'components/FrameList';
import ContentList from 'components/ContentList';

const STRINGS = {
  placeholders: {
    content: 'Prepend fields with "c." (e.g. c.result.gore = true AND c.result.adult = true).',
    frames: 'Prepend fields with "c." (e.g. c.result.adult = true AND c.result.classifiers.bingAdultV3.adultScore >= 0.9).',
  },
};

function QuerySection(props) {
  const history = useHistory();

  const { className, type, queryParams } = props;
  const frames = queryParams.get('frames') === 'true';
  const count = queryParams.get('count') === 'true';
  const [filter, setFilter] = useState(queryParams.get('filter'));
  const [orderBy, setOrderBy] = useState(queryParams.get('orderBy') || 'c.createdAt DESC');
  const [limit, setLimit] = useState(queryParams.get('limit') || 100);

  let dataApiMethod = null;
  let formatDataApiMethod = null;
  if (!frames) {
    dataApiMethod = async () => {
      if (!count) {
        return api.query(type, false, false, filter, orderBy, limit);
      }
      return api.query(type, false, true, filter, null, 0);
    };

    formatDataApiMethod = async () => api.query(type, false, false, null, orderBy, 1);
  } else {
    dataApiMethod = async () => {
      if (!count) {
        return api.query(type, true, false, filter, orderBy, limit);
      }
      return api.query(type, true, true, filter, null, 0);
    };

    formatDataApiMethod = async () => api.query(type, true, false, null, orderBy, 1);
  }

  const {
    data, isLoading, error, setDependency,
  } = useApi(dataApiMethod, 0);

  const {
    data: formatData, isLoading: isFormatLoading,
  } = useApi(formatDataApiMethod);

  const modalState = useModalState();
  const { showFormat } = modalState.get();

  return (
    <Section className={className} mt={2}>
      {(type === 'videos' || type === 'streams' || type === 'gifs') && (
        <Tabs mt={2}>
          <TabItem
            text={`Query ${type}`}
            active={!frames}
            to={`/${type}`}
          />
          <TabItem
            text="Query frames"
            active={frames}
            to={`/${type}?frames=true`}
          />
        </Tabs>
      )}

      <Box
        mt={4}
      >
        <Label
          text="WHERE"
        />
        <Textarea
          defaultValue={filter}
          placeholder={!frames ? STRINGS.placeholders.content : STRINGS.placeholders.frames}
          onBlur={(e) => setFilter(e.target.value)}
          spellCheck={false}
          minRows={3}
        />
      </Box>
      <Flex mt={1} justifyContent="space-between">
        <Box
          width="50%"
          mr={2}
        >
          <Label
            text="ORDER BY"
          />
          <Input
            defaultValue={orderBy}
            onBlur={(e) => setOrderBy(e.target.value)}
          />
        </Box>
        <Box
          width="50%"
          ml={2}
        >
          <Label
            text="LIMIT"
          />
          <Input
            width="100%"
            type="number"
            defaultValue={limit}
            onBlur={(e) => setLimit(e.target.value)}
          />
        </Box>
      </Flex>
      <Flex mt={4} justifyContent="space-between" alignItems="center">
        <Flex>
          <Button
            pt={1}
            pb={1}
            width={100}
            height={30}
            text="Query"
            loading={isLoading && !count}
            onClick={() => history.push(`/${type}?filter=${filter || ''}&orderBy=${orderBy || ''}&frames=${frames}&limit=${limit}`)}
          />
          <Button
            ml={2}
            pt={1}
            pb={1}
            width={100}
            height={30}
            text="Count"
            loading={isLoading && count}
            onClick={() => history.push(`/${type}?filter=${filter || ''}&frames=${frames}&count=true`)}
          />
        </Flex>
        <Flex>
          <Flex alignItems="center">
            <Button
              format="inline"
              text={!frames ? 'Content format' : 'Frame format'}
              onClick={() => modalState.push({ showFormat: true })}
            />
            <Text
              ml={2}
              mr={2}
              diplay="inline-block"
              text=" | "
            />
            <Button
              format="inline"
              text="Refresh"
              onClick={() => setDependency(Time.now())}
            />
          </Flex>
        </Flex>
      </Flex>
      <Section mt={4} loading={isLoading} error={error && error.map()}>
        {data && Array.isArray(data) && !frames && (
          <ContentList contents={data} type={type} />
        )}
        {data && !Array.isArray(data) && !frames && (
          <Text
            fontsize="large"
            text={`Count: ${data}`}
          />
        )}
        {data && Array.isArray(data) && frames && (
          <FrameList frames={data} type={type} />
        )}
        {data && !Array.isArray(data) && frames && (
          <Text
            fontsize="large"
            text={`Count: ${data}`}
          />
        )}
      </Section>
      {showFormat && (
        <Modal
          width={['100%', '100%', '700px']}
          title={!frames ? 'Content format' : 'Frame format'}
          loading={isFormatLoading}
          overlayStyle={{
            overflowX: 'auto',
            overflowY: 'scroll',
            position: 'fixed',
            zIndex: 11,
          }}
        >
          {formatData && formatData.length > 0 && (
            <ReactJson
              src={formatData[0]}
              displayDataTypes={false}
            />
          )}
        </Modal>
      )}
    </Section>
  );
}

QuerySection.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  queryParams: PropTypes.shape({
    get: PropTypes.func,
  }).isRequired,
};

QuerySection.defaultProps = {
  className: null,
};

export default stylex(QuerySection);
