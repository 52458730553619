import React from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yDark, a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {
  Box, Divider, ExtLink, Flex, Text, stylex,
} from 'controls';

function ComponentDetails(props) {
  const { className, component, handle } = props;
  const {
    apis, author, defaultHandle,
    description, link, name, properties, samples,
  } = component;

  const replaceHandle = handle || defaultHandle;
  return (
    <Box
      mt={2}
      className={className}
    >

      <Text
        fontWeight="bold"
        text={name}
      />
      <Text
        mt={1}
        fontSize={14}
        textAlign="justify"
        color="fg-light"
        text={`Author: ${author}`}
      />
      <Text
        mt={1}
        fontSize={14}
        textAlign="justify"
        color="muted"
        text={description}
      />
      {link && (
        <Box mt={1}>
          <ExtLink
            fontSize={14}
            to={link}
            text={link}
            target="_blank"
          />
        </Box>
      )}
      <Divider mt={4} mb={4} />
      {apis.length > 0 && (
        <Box mt={2}>
          <Text
            mb={3}
            color="muted"
            fontWeight="bold"
            text="Methods"
          />
          {apis.map((api) => (
            <Box
              bg="bg-light"
              p={2}
              borderRadius={4}
              fontSize={14}
              mb={4}
              key={api.name}
            >
              <Flex
                fontWeight="bold"
                alignItems="center"
              >
                <Text
                  fontWeight="bold"
                  text={api.name}
                />
                <Text
                  ml="2px"
                  text="("
                />
                {api.parameters.length > 0 && api.parameters.map((parameter, index) => (
                  <Flex key={parameter.description}>
                    <Text
                      text={parameter.type}
                    />
                    {index !== api.parameters.length - 1 && (
                      <Text
                        mr={1}
                        text=","
                      />
                    )}
                  </Flex>
                ))}
                <Text
                  text=")"
                />
              </Flex>
              <Text
                mt="2px"
                text={api.description}
              />
              {api.parameters.length > 0 && (
                <Box mt={4}>
                  <Text
                    color="muted"
                    fontWeight="bold"
                    text="Parameters"
                  />
                  {api.parameters.map((parameter) => (
                    <Box mt={1} key={parameter.description}>
                      <Flex>
                        <Text
                          color="muted"
                          text={`[${parameter.type}]`}
                        />
                        <Text
                          ml={2}
                          text={parameter.description}
                        />
                      </Flex>
                    </Box>
                  ))}
                </Box>
              )}
              {api.result && (
                <Box mt={4} mb={2}>
                  <Flex>
                    <Text
                      color="muted"
                      fontWeight="bold"
                      text="Returns"
                    />
                    <Text
                      ml={2}
                      color="muted"
                      text={`[${api.result.type}]`}
                    />
                  </Flex>
                  <Text
                    text={api.result.description}
                  />
                </Box>
              )}
              {api.result && api.result.model && (
                <ReactJson
                  name={false}
                  src={JSON.parse(api.result.model)}
                  displayDataTypes={false}
                />
              )}
              <Box mt={4}>
                <Text
                  mb={1}
                  color="muted"
                  fontWeight="bold"
                  text="Usage"
                />
                <SyntaxHighlighter language="javascript" style={a11yLight}>
                  {api.usage.split('{0}').join(replaceHandle)}
                </SyntaxHighlighter>
              </Box>
            </Box>
          ))}
          <Divider mt={4} mb={4} />
        </Box>
      )}
      {properties.length > 0 && (
        <Box mt={2}>
          <Text
            mb={3}
            color="muted"
            fontWeight="bold"
            text="Properties"
          />
          {properties.map((property) => (
            <Box
              bg="bg-light"
              p={2}
              borderRadius={4}
              key={property.name}
              mb={4}
            >
              <Flex alignItems="center">
                <Text
                  fontWeight="bold"
                  fontSize={14}
                  text={property.name}
                />
                <Text
                  ml={2}
                  color="muted"
                  fontSize={14}
                  text={`[${property.type}]`}
                />
                {property.required && (
                  <Box
                    display="inline-block"
                    ml={2}
                  >
                    <Text
                      bg="error"
                      color="bg"
                      borderRadius={4}
                      px={1}
                      text="Required"
                      fontSize={12}
                    />
                  </Box>
                )}
              </Flex>
              <Text
                fontSize={14}
                text={property.description}
              />
            </Box>
          ))}
          <Divider mt={4} mb={4} />
        </Box>
      )}
      {samples && (
        <Box mt={2}>
          <Text
            mb={3}
            color="muted"
            fontWeight="bold"
            text="Samples"
          />
          <SyntaxHighlighter language="javascript" style={a11yDark}>
            {samples.split('{0}').join(replaceHandle)}
          </SyntaxHighlighter>
          <Divider mt={4} mb={4} />
        </Box>
      )}
    </Box>
  );
}

ComponentDetails.propTypes = {
  component: PropTypes.shape({
    apis: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        usage: PropTypes.string,
        parameters: PropTypes.arrayOf(
          PropTypes.shape({
            type: PropTypes.string,
            description: PropTypes.string,
          }),
        ),
        result: PropTypes.shape({
          type: PropTypes.string,
          description: PropTypes.string,
          model: PropTypes.string,
        }),
      }),
    ),
    author: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
    name: PropTypes.string,
    defaultHandle: PropTypes.string,
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        type: PropTypes.string,
        required: PropTypes.bool,
        defaultValue: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]),
      }),
    ),
    samples: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  handle: PropTypes.string,
};

ComponentDetails.defaultProps = {
  className: null,
  handle: null,
};

export default stylex(ComponentDetails);
