import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Box, Field, Form, Input, Modal, Select, useApi, useHistory,
} from 'controls';

import api from 'api';

function CreateOrEditRun(props) {
  const {
    create,
    run,
    onCreate, onUpdate,
  } = props;
  const history = useHistory();

  const {
    data: datasets,
  } = useApi(async () => api.getDatasets());

  const {
    data: skills, setData: setSkills,
  } = useApi(async () => api.getAllSkills());

  let datasetOptions = [];
  if (datasets) {
    datasetOptions = datasets.map((d) => ({ label: d.name, value: d.id, type: d.contentType }));
  }

  let skillOptions = [];
  if (skills) {
    skillOptions = skills.map((s) => ({ label: s.id, value: s.id, type: s.contentType }));
  }

  return (
    <Modal
      title={`${create ? 'Create' : 'Edit'} run`}
      width={['100%', '100%', '500px']}
      overflow="visible"
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      <Form
        initialValues={{
          name: run.name,
          datasetId: run.datasetId,
          skillId: run.skillId,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required'),
        })}
        onSubmit={async (values) => {
          if (!values.datasetId || !values.skillId) {
            return 'Dataset and skill required';
          }
          if (create) {
            const response = await api.createRun(
              values.name,
              values.datasetId,
              values.skillId,
            );

            if (!response.error) {
              if (onCreate) {
                onCreate();
              }

              history.goBack();
              return null;
            }
          }

          if (!create) {
            const response = await api.updateRun(
              run.id,
              values.name,
            );

            if (!response.error) {
              if (onUpdate) {
                onUpdate(response.data);
              }

              history.goBack();
              return null;
            }

            if (response.error.status === 404) {
              return 'Run not found';
            }
          }

          return null;
        }}
        action={create ? 'Create' : 'Update'}
        fields={(fprops) => (
          <Box>
            <Field
              label="Run name"
              name="name"
              component={(
                <Input />
              )}
            />
            {create && (
              <>
                <Field
                  label="Dataset"
                  name="datasetId"
                  onChange={(e) => {
                    const typeSkills = skills.filter(
                      (s) => s.contentType === e.target.type,
                    );
                    setSkills(typeSkills);
                    fprops.setFieldValue('datasetId', e.target.value);
                  }}
                  component={(
                    <Select
                      options={datasetOptions}
                    />
                  )}
                />
                <Field
                  label="Skill"
                  name="skillId"
                  component={(
                    <Select
                      options={skillOptions}
                    />
                  )}
                />
              </>
            )}
          </Box>
        )}
      />
    </Modal>
  );
}

CreateOrEditRun.propTypes = {
  create: PropTypes.bool,
  onUpdate: PropTypes.func,
  onCreate: PropTypes.func,
  run: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    datasetId: PropTypes.string,
    skillId: PropTypes.string,
  }),
};

CreateOrEditRun.defaultProps = {
  create: true,
  onUpdate: null,
  onCreate: null,
  run: {
    id: '',
    name: '',
    datasetId: '',
    skillId: '',
  },
};

export default CreateOrEditRun;
