import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Flex, Link, Page, Section, Text, useApi, useParams,
} from 'controls';

import api from 'api';
import Utils from 'components/Utils';

const STRINGS = {
  labels: {
    analyzed: 'analyzed',
    time: '(last 24 hours)',
  },
};

const NavContainer = (props) => {
  const {
    title, description, width, to,
  } = props;
  return (
    <Box width={width} p={3} m={1} borderWidth={1} borderStyle="solid" borderColor="border" borderRadius={4}>
      {!to && (
        <Text
          fontSize="small"
          fontWeight={500}
          text={title}
        />
      )}
      {to && (
        <Link
          to={to}
        >
          <Text fontWeight={600} fontSize={18}>
            {title}
          </Text>
        </Link>
      )}
      {description && (
        <Text p={2} color="muted" fontSize={14}>
          {description}
        </Text>
      )}
    </Box>
  );
};

NavContainer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  width: PropTypes.string.isRequired,
  to: PropTypes.string,
};

NavContainer.defaultProps = {
  description: null,
  to: null,
};

function Home(props) {
  const params = useParams();
  const { type: paramType } = params;

  const { contentType } = props;
  const propType = contentType ? Utils.mapContentTypeToRestType(contentType) : '';

  const type = paramType || propType;
  const {
    data: summary, isLoading,
  } = useApi(async () => api.getSummary(type));

  return (
    <Page mt={2}>
      <Section mt={6}>
        <Flex justifyContent="center">
          <Flex width="100%" textAlign="center">
            <NavContainer
              width="33%"
              title="Documentation"
              description="Learn how to use APIs, Skills, Boards, Metrics, ..."
              to="/documentation"
            />
            <NavContainer
              width="33%"
              title="APIs"
              description="Play with the APIs through Swagger"
              to="/documentation/workflow"
            />
            <NavContainer
              width="33%"
              title="Repository"
              description="Browse models and components in the repository"
              to="/repository"
            />
          </Flex>
        </Flex>

        <Flex justifyContent="center">
          <Flex width="100%" textAlign="center">
            <NavContainer
              width="33%"
              title="Skills"
              description="Program custom analysis pipelines"
              to={`/${type}/skills`}
            />
            <NavContainer
              width="33%"
              title="Boards"
              description="Create boards to review results and take actions"
              to={`/${type}/boards`}
            />
            <NavContainer
              width="33%"
              title="Search"
              description="Search for content and results using SQL queries"
              to={`/${type}`}
            />
          </Flex>
        </Flex>

        <Flex justifyContent="center">
          <Flex width="100%" textAlign="center">
            <NavContainer
              width="33%"
              title="Hashes"
              description="Create and manage image hash databases for matching"
              to="/hashes"
            />
            <NavContainer
              width="33%"
              title="Templates"
              description="Create and manage custom image templates"
              to="/templates"
            />
            <NavContainer
              width="33%"
              title="Models"
              description="Create and manage custom AI models"
              to="/models"
            />
          </Flex>
        </Flex>

        <Flex justifyContent="center">
          <Flex width="100%" textAlign="center">
            <NavContainer
              width="33%"
              title="Metrics"
              description="Query and visualize custom metrics using SQL"
              to={`/${type}/metrics`}
            />
            <NavContainer
              width="33%"
              title="Status"
              description="View the current load, scale and errors in the deployment"
              to="/status"
            />
            <NavContainer
              width="33%"
              title="Manage"
              description="Manage UI, Processor, Node, and Autoscale settings"
              to="/manage"
            />
          </Flex>
        </Flex>
        <Flex justifyContent="center">
          <Flex width="100%" textAlign="center">
            <NavContainer
              width="33%"
              title="Autopack"
              description="Test optimal processor and thread configurations."
              to={`/${type}/autopack`}
            />
            <NavContainer
              width="33%"
              title="Layouts"
              description="Fully customize how content is visualized."
              to="/layouts"
            />
            <NavContainer
              width="33%"
              title="Test"
              description="Test a job with your custom inputs"
              to="/test"
            />
          </Flex>
        </Flex>
      </Section>

      <Section mt={4} loading={isLoading}>
        {summary && (
          <Box textAlign="center" m={1}>
            <Box p={3} borderWidth={1} borderStyle="solid" borderColor="border" borderRadius={4}>
              <Text fontSize="medium" fontWeight={600}>
                {`${Utils.toPascalCase(type)} ${STRINGS.labels.analyzed}`}
              </Text>
              <Text fontSize="xx-small" color="muted">
                {STRINGS.labels.time}
              </Text>
              <Text fontWeight={500} fontSize={40}>
                {summary.totalContent}
              </Text>
            </Box>

            {(type === 'videos' || type === 'streams' || type === 'gifs') && (
              <Box mt={6} p={3} borderWidth={1} borderStyle="solid" borderColor="border" borderRadius={4}>
                <Text fontSize="medium" fontWeight={600}>
                  {`Frames ${STRINGS.labels.analyzed}`}
                </Text>
                <Text fontSize="xx-small" color="muted">
                  {STRINGS.labels.time}
                </Text>
                <Text fontWeight={500} fontSize={40}>
                  {summary.totalFrames}
                </Text>
              </Box>
            )}
          </Box>
        )}
      </Section>
    </Page>
  );
}

Home.propTypes = {
  contentType: PropTypes.string,
};

Home.defaultProps = {
  contentType: null,
};

export default Home;
