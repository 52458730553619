import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck as fasCheck,
  faEllipsisV as fasEllipsisV,
  faExclamationTriangle as fasExclamationTriangle,
  faStarOfLife as fasStarOfLife,
  faClone as fasClone,
  faSearch as fasSearch,
  faEye as fasEye,
  faPen as fasPen,
  faChartLine as fasChartLine,
  faPoll as fasPoll,
  faChevronDown as fasChevronDown,
  faChevronUp as fasChevronUp,
  faList as fasList,
  faSquare as fasSquare,
  faThumbtack as fasThumbtack,
} from '@fortawesome/free-solid-svg-icons';

import {
  faSquare as farSquare,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  fasCheck,
  fasEllipsisV,
  fasExclamationTriangle,
  fasStarOfLife,
  fasClone,
  fasSearch,
  fasEye,
  fasPen,
  fasChartLine,
  fasPoll,
  fasChevronDown,
  fasChevronUp,
  fasList,
  fasSquare,
  farSquare,
  fasThumbtack,
);
