import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, EllipsisText, Link, Page, Section,
  Table, TabItem, Tabs, Text, TruncatedText,
  Time, Tooltip, useApi, useQuery, useResponsive,
} from 'controls';

import api from 'api';
import Utils from 'components/Utils';

const STRINGS = {
  title: 'Errors',
};

const TableHeader = (props) => {
  const { text, tooltip } = props;
  return (
    <Text textAlign="center" p={2}>
      {text}
      {tooltip && (
        <Tooltip ml={1} tooltip={tooltip} />
      )}
    </Text>
  );
};

TableHeader.propTypes = {
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

TableHeader.defaultProps = {
  tooltip: null,
};

function Errors(props) {
  const { contentType } = props;
  const type = Utils.mapContentTypeToRestType(contentType);

  const queryParams = useQuery();
  const limit = queryParams.get('limit') || 100;
  const category = queryParams.get('category') || '';
  const regionId = queryParams.get('regionId') || '';

  const isMd = useResponsive('sm');
  const { data, isLoading } = useApi(
    async () => api.getErrors(category, contentType, regionId, limit),
  );

  /* eslint-disable react/prop-types */
  const columns = [{
    Header: <TableHeader text="Id" />,
    accessor: 'contentId',
    Cell: ({ row }) => (
      <Box p={2} fontSize="xxx-small">
        {row.values.contentId && (
          <Link
            to={`/${type}/${row.values.contentId}`}
          >
            <EllipsisText
              text={row.values.contentId}
            />
          </Link>
        )}
      </Box>
    ),
    width: '60',
  },
  {
    Header: <TableHeader text="Service" />,
    accessor: 'error.serviceType',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={cell.value}
        />
      </Box>
    ),
    width: 80,
  },
  {
    Header: <TableHeader text="Type" />,
    accessor: 'error.errorType',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={cell.value}
        />
      </Box>
    ),
    width: 60,
  },
  {
    Header: <TableHeader text="Exception" />,
    accessor: 'error.exception',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xxx-small">
        <TruncatedText
          text={cell.value}
        />
      </Box>
    ),
    width: 'auto',
  },
  {
    Header: <TableHeader text="Node" />,
    accessor: 'nodeId',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={cell.value}
        />
      </Box>
    ),
    width: 60,
  },
  {
    Header: <TableHeader text="Region" />,
    accessor: 'regionId',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={cell.value}
        />
      </Box>
    ),
    width: 70,
  },
  {
    Header: <TableHeader text="T" />,
    accessor: 'createdAt',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={Time.ago(cell.value)}
        />
      </Box>
    ),
    width: 40,
  }];
  /* eslint-enable react/prop-types */

  return (
    <Page mt={2}>
      <Tabs mt={2}>
        <TabItem
          text="Job"
          active={category === 'job'}
          to={`/errors?category=job&contentType=${contentType}&regionId=${regionId}`}
        />
        <TabItem
          text="Output"
          active={category === 'output'}
          to={`/errors?category=output&contentType=${contentType}&regionId=${regionId}`}
        />
        <TabItem
          text="Processor"
          active={category === 'processor'}
          to={`/errors?category=processor&contentType=${contentType}&regionId=${regionId}`}
        />
        <TabItem
          text="Input"
          active={category === 'input'}
          to={`/errors?category=input&contentType=${contentType}&regionId=${regionId}`}
        />
        <TabItem
          text="All"
          active={category === ''}
          to={`/errors?category=&contentType=${contentType}&regionId=${regionId}`}
        />
      </Tabs>
      <Section mt={4} loading={isLoading}>
        {data && (
          <>
            <Text fontSize="medium-large" fontWeight="bold" mb={4}>
              {`${STRINGS.title} (${data.length})`}
            </Text>
            <Table
              data={data}
              columns={columns}
              hiddenColumns={[...(isMd ? [] : ['createdAt', 'nodeId', 'regionId'])]}
            />
          </>
        )}
      </Section>
    </Page>
  );
}

Errors.propTypes = {
  contentType: PropTypes.string.isRequired,
};

export default Errors;
