import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Box, Button, Card, Divider, Page, Section, Text,
  Time, useApi, useModalState, Select,
} from 'controls';

import api from 'api';
import Utils from 'components/Utils';
import { logout } from 'auth/msal';
import { actionCreators } from 'stores/Settings';

import EditUiSettings from './EditUiSettings';
import EditProcessorSettings from './EditProcessorSettings';
import EditNodeSettings from './EditNodeSettings';
import EditAutoscaleSettings from './EditAutoscaleSettings';
import EditSearchSettings from './EditSearchSettings';

const Setting = (props) => {
  const {
    title, value,
  } = props;
  return (
    <Box mt={2}>
      <Text
        text={title}
        color="muted"
        fontSize={13}
      />
      <Text
        text={value}
      />
    </Box>
  );
};

Setting.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};

function Manage(props) {
  const { initSettings } = props;
  const {
    data: uiSettingsData,
    isLoading: uiSettingsLoading,
    setDependency: setUiSettingsDependency,
  } = useApi(async () => api.getUiSettings(), 0);

  const {
    data: processorSettingsData,
    isLoading: processorSettingsLoading,
    setDependency: setProcessorSettingsDependency,
  } = useApi(async () => api.getProcessorSettings(), 0);

  const {
    data: nodeSettingsData,
    isLoading: nodeSettingsLoading,
    setDependency: setNodeSettingsDependency,
  } = useApi(async () => api.getNodeSettings(), 0);

  const [nodeSettingsRegion, setNodeSettingsRegion] = useState('');

  const [nodeSettingsRegionalData, setnodeSettingsRegionalData] = useState(null);

  useEffect(() => {
    if (nodeSettingsData && nodeSettingsData.regionalNodeSettings) {
      setNodeSettingsRegion(Object.keys(nodeSettingsData.regionalNodeSettings)[0]);
    }
  }, [nodeSettingsData]);

  useEffect(() => {
    if (nodeSettingsRegion) {
      setnodeSettingsRegionalData(nodeSettingsData.regionalNodeSettings[nodeSettingsRegion]);
    }
  }, [nodeSettingsRegion]);

  const {
    data: autoscaleSettingsData,
    isLoading: autoscaleSettingsLoading,
    setDependency: setAutoscaleSettingsDependency,
  } = useApi(async () => api.getAutoscaleSettings(), 0);

  const {
    data: searchSettingsData,
    isLoading: searchSettingsLoading,
    setDependency: setSearchSettingsDependency,
  } = useApi(async () => api.getSearchSettings(), 0);

  const modalState = useModalState();
  const {
    editUiSettings, uiSettings,
    editProcessorSettings, processorSettings,
    editNodeSettings, nodeSettings, region,
    editAutoscaleSettings, autoscaleSettings,
    editSearchSettings, searchSettings,
  } = modalState.get();

  let capsContentType = null;
  if (uiSettingsData) {
    const { contentType } = uiSettingsData;
    capsContentType = `${Utils.toPascalCase(contentType)}`;
  }

  useEffect(() => {
    if (uiSettingsData) {
      initSettings(uiSettingsData);
    }
  }, [uiSettingsData]);

  return (
    <Page mt={6}>
      <Section loading={uiSettingsLoading}>
        {uiSettingsData && (
          <Card
            title={(
              <Text
                text="UI Settings"
                fontSize={16}
                fontWeight="bold"
              />
            )}
            action={(
              <Button
                format="inline"
                text="Edit"
                onClick={() => modalState.push({
                  editUiSettings: true,
                  uiSettings: uiSettingsData,
                })}
              />
            )}
          >
            <Divider mt={2} mb={2} />
            <Setting
              title="Content type"
              value={capsContentType}
            />
          </Card>
        )}
      </Section>
      <Section
        mt={6}
        loading={processorSettingsLoading}
      >
        {processorSettingsData && (
          <Card
            title={(
              <Text
                text="Processor Settings"
                fontSize={16}
                fontWeight="bold"
              />
            )}
            action={(
              <Button
                format="inline"
                text="Edit"
                onClick={() => modalState.push({
                  editProcessorSettings: true,
                  processorSettings: processorSettingsData,
                })}
              />
            )}
          >
            <Divider mt={2} />
            <Setting
              title="Store results"
              value={processorSettingsData.storeResults ? 'Yes' : 'No'}
            />
            <Setting
              title="Send events"
              value={(processorSettingsData.sendEvents ? 'Yes' : 'No')}
            />
            <Setting
              title="Sas token expiry for frames"
              value={`${processorSettingsData.frameSasExpiry} seconds`}
            />
            <Setting
              title="Frame height in store"
              value={processorSettingsData.frameHeight}
            />
          </Card>
        )}
      </Section>
      <Section
        mt={6}
        loading={nodeSettingsLoading}
      >
        {nodeSettingsData && nodeSettingsData.regionalNodeSettings && nodeSettingsRegionalData && nodeSettingsRegion !== '' && (
          <Card
            title={(
              <Text
                text="Node Settings"
                fontSize={16}
                fontWeight="bold"
              />
            )}
            action={(
              <Button
                format="inline"
                text="Edit"
                onClick={() => modalState.push({
                  editNodeSettings: true,
                  region: nodeSettingsRegion,
                  nodeSettings: nodeSettingsRegionalData,
                })}
              />
            )}
          >
            <Box mt={2}>
              <Text
                text="Region"
                color="muted"
                fontSize={13}
              />
              <Select
                value={nodeSettingsRegion}
                onChange={(val) => setNodeSettingsRegion(val.target.value)}
                options={Object.keys(nodeSettingsData.regionalNodeSettings)
                  .map((r) => ({ label: r, value: r }))}
              />
            </Box>
            <Divider mt={2} />
            <Setting
              title="Run one process per core"
              value={nodeSettingsRegionalData.processPerCore ? 'Yes' : 'No'}
            />
            {!nodeSettingsRegionalData.processPerCore && (
              <>
                <Setting
                  title="Number of processes per node"
                  value={nodeSettingsRegionalData.processes}
                />
                <Setting
                  title="CPU offset"
                  value={nodeSettingsRegionalData.cpuOffset}
                />
                <Setting
                  title="Pin process to core"
                  value={nodeSettingsRegionalData.pin ? 'Yes' : 'No'}
                />
              </>
            )}
            <Setting
              title="Number of threads per process"
              value={nodeSettingsRegionalData.threads}
            />
          </Card>
        )}
      </Section>
      <Section
        mt={6}
        loading={autoscaleSettingsLoading}
      >
        {autoscaleSettingsData && (
          <Card
            title={(
              <Text
                text="Autoscale Settings"
                fontSize={16}
                fontWeight="bold"
              />
            )}
            action={(
              <Button
                format="inline"
                text="Edit"
                onClick={() => modalState.push({
                  editAutoscaleSettings: true,
                  autoscaleSettings: autoscaleSettingsData,
                })}
              />
            )}
          >
            <Divider mt={2} />
            <Setting
              title="Refresh delay"
              value={`${autoscaleSettingsData.refreshDelay} seconds`}
            />
            <Setting
              title="Min replicas"
              value={autoscaleSettingsData.minReplicas}
            />
            <Setting
              title="Max replicas"
              value={autoscaleSettingsData.maxReplicas}
            />
            <Setting
              title="Scale up smoothing parameter"
              value={autoscaleSettingsData.scaleUpSmoothingParameter}
            />
            <Setting
              title="Scale down smoothing parameter"
              value={autoscaleSettingsData.scaleDownSmoothingParameter}
            />
            <Setting
              title="Scale up utilization percentage"
              value={autoscaleSettingsData.scaleUpUtilizationPercentage}
            />
            <Setting
              title="Scale down utilization percentage"
              value={autoscaleSettingsData.scaleDownUtilizationPercentage}
            />
            <Setting
              title="Scale up cooldown time"
              value={`${autoscaleSettingsData.scaleUpCooldownTimeInSeconds} seconds`}
            />
          </Card>
        )}
      </Section>
      <Section
        mt={6}
        loading={searchSettingsLoading}
      >
        {searchSettingsData && (
          <Card
            title={(
              <Text
                text="Search Settings"
                fontSize={16}
                fontWeight="bold"
              />
            )}
            action={(
              <Button
                format="inline"
                text="Edit"
                onClick={() => modalState.push({
                  editSearchSettings: true,
                  searchSettings: searchSettingsData,
                })}
              />
            )}
          >
            <Divider mt={2} />
            <Setting
              title="Endpoint"
              value={searchSettingsData.endpoint}
            />
            <Setting
              title="Index name"
              value={searchSettingsData.indexName}
            />
            <Setting
              title="Api key (shows as ***** if value is set, not viewable in ui)"
              type="password"
              value={searchSettingsData.apiKeyRef != null ? '*****' : ''}
            />
          </Card>
        )}
      </Section>

      <Section
        mt={6}
      >
        <Card>
          <Button
            format="inline"
            text="Sign out"
            onClick={() => logout()}
          />
        </Card>
      </Section>
      {editUiSettings && (
        <EditUiSettings
          settings={uiSettings}
          onUpdate={() => setUiSettingsDependency(Time.now())}
        />
      )}
      {editProcessorSettings && (
        <EditProcessorSettings
          settings={processorSettings}
          onUpdate={() => setProcessorSettingsDependency(Time.now())}
        />
      )}
      {editNodeSettings && (
        <EditNodeSettings
          region={region}
          settings={nodeSettings}
          onUpdate={() => setNodeSettingsDependency(Time.now())}
        />
      )}
      {editAutoscaleSettings && (
        <EditAutoscaleSettings
          settings={autoscaleSettings}
          onUpdate={() => setAutoscaleSettingsDependency(Time.now())}
        />
      )}
      {editSearchSettings && (
        <EditSearchSettings
          settings={searchSettings}
          onUpdate={() => setSearchSettingsDependency(Time.now())}
        />
      )}
    </Page>
  );
}

Manage.propTypes = {
  initSettings: PropTypes.func.isRequired,
};

export default connect(
  (state) => state.settings,
  actionCreators,
)(Manage);
