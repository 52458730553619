import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Box, Field, Form, Input, Modal, Select, useHistory,
} from 'controls';

import api from 'api';

function EditProcessorSettings(props) {
  const {
    settings, onUpdate,
  } = props;
  const history = useHistory();

  return (
    <Modal
      overflow="visible"
      title="Edit Processor Settings"
      width={['100%', '100%', '480px']}
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      <Form
        initialValues={{
          storeResults: settings.storeResults,
          sendEvents: settings.sendEvents,
          frameSasExpiry: settings.frameSasExpiry,
          frameHeight: settings.frameHeight,
        }}
        validationSchema={Yup.object().shape({
          frameSasExpiry: Yup.number().required('Required'),
          frameHeight: Yup.number().required('Required'),
        })}
        onSubmit={async (values) => {
          const response = await api.updateProcessorSettings(
            values.storeResults,
            values.sendEvents,
            values.frameSasExpiry,
            values.frameHeight,
          );
          if (!response.error) {
            if (onUpdate) {
              onUpdate();
            }

            history.goBack();
            return null;
          }

          return null;
        }}
        action="Update"
        fields={() => (
          <Box>
            <Field
              label="Store results"
              name="storeResults"
              component={(
                <Select
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                />
              )}
            />
            <Field
              label="Send events"
              name="sendEvents"
              component={(
                <Select
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                />
              )}
            />
            <Field
              label="Sas token expiry for frames"
              name="frameSasExpiry"
              component={(
                <Input
                  type="number"
                />
              )}
            />
            <Field
              label="Frame height in store"
              name="frameHeight"
              component={(
                <Input
                  type="number"
                />
              )}
            />
          </Box>

        )}
      />
    </Modal>
  );
}

EditProcessorSettings.propTypes = {
  onUpdate: PropTypes.func,
  settings: PropTypes.shape({
    sendEvents: PropTypes.bool,
    storeResults: PropTypes.bool,
    frameSasExpiry: PropTypes.number,
    frameHeight: PropTypes.number,
  }).isRequired,
};

EditProcessorSettings.defaultProps = {
  onUpdate: null,
};

export default EditProcessorSettings;
