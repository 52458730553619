import React from 'react';
import {
  Box, Button, Dropdown, Flex, Form, Icon, IconButton, Link,
  MenuItem, Modal, Page, Section, Text, Time, useApi, useParams, useQuery,
  useModalState,
} from 'controls';
import ReactJson from 'react-json-view';

import WatchVideo from 'components/WatchVideo';

import api from 'api';
import FrameList from 'components/FrameList';
import ContentList from 'components/ContentList';
import Utils from 'components/Utils';
import CreateOrEditBoard from './CreateOrEditBoard';
import DeleteBoard from './DeleteBoard';
import ContentSection from './ContentSection';

const STRINGS = {
  none: 'There are no items in the board',
  actions: {
    allSafe: 'All safe',
  },
};

function Board() {
  const params = useParams();
  const { type, id } = params;
  const queryParams = useQuery();
  const offset = queryParams.get('offset') || '';

  const modalState = useModalState();
  const {
    updateBoard, deleteBoard, showContent,
    showContentId, showContentDetails, contentDetails, watchContentVideo,
  } = modalState.get();
  const singularType = Utils.toSingular(type);

  const {
    data, isLoading, setData, setDependency,
  } = useApi(async () => api.getBoard(type, id, offset), 0);

  const updateList = (contentId) => {
    if (data.board.reviewable) {
      const newItems = data.items.filter((d) => d.id !== contentId);
      if (newItems.length === 0) {
        setDependency(Time.now());
      } else {
        setData({
          board: data.board,
          items: newItems,
          count: data.count - 1,
          offset: data.offset,
        });
      }
    }
  };

  const actions = data && (
    <Flex mt={4} mb={2} justifyContent="space-between" alignItems="center">
      <Flex>
        {data.board.reviewable && (
          <Form
            errorPosition="none"
            onSubmit={async () => {
              const requestItems = data.items.map((item) => ({
                contentId: data.board.frames ? item.contentId : item.id,
                frameId: data.board.frames ? item.id : null,
                reviewed: true,
                flagged: false,
              }));
              const response = await api.bulkUpdateReview(
                type, requestItems,
              );
              if (!response.error) {
                setDependency(Time.now());
              }
              return null;
            }}
            action={(fprops) => (
              <Button
                type="submit"
                width="110px"
                py={2}
                fontSize="small"
                variant="accent"
                loading={fprops.isSubmitting}
              >
                <Icon icon="check" mr={2} />
                {STRINGS.actions.allSafe}
              </Button>
            )}
          />
        )}
      </Flex>
      {data.offset && data.items.length > 0 && (
        <Link
          text="Next >>"
          to={`/${type}/boards/${data.board.id}?offset=${data.offset}`}
        />
      )}
    </Flex>
  );

  return (
    <Page mt={2}>
      <Section pt={2} loading={isLoading}>
        {data && data.board && (
          <Flex
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Box>
              <Text
                fontWeight="bold"
                text={data.board.name}
              />
              <Text
                fontSize={14}
                color="muted"
                text={data.board.id}
              />
            </Box>
            <Flex alignItems="center">
              <Button
                mr={3}
                mb={1}
                format="inline"
                text="Refresh"
                onClick={() => setDependency(Time.now())}
              />
              <Box>
                <Dropdown
                  alignRight
                  closeOnClick={false}
                  toggle={(
                    <IconButton p={1} color="muted" icon="ellipsis-v" />
                  )}
                  menuStyle={{
                    marginTop: '5px',
                  }}
                  menuContent={(
                    <>
                      <MenuItem
                        divider
                        fontSize="small"
                        py={2}
                        text="Update board"
                        onClick={(e) => {
                          e.stopPropagation();
                          modalState.push({
                            updateBoard: true,
                          });
                        }}
                      />
                      <MenuItem
                        fontSize="small"
                        py={2}
                        text="Delete board"
                        onClick={(e) => {
                          e.stopPropagation();
                          modalState.push({
                            deleteBoard: true,
                          });
                        }}
                      />
                    </>
                  )}
                />
              </Box>
            </Flex>
          </Flex>
        )}
        {data && data.items && data.items.length > 0 && (
          <Box>
            <Box
              borderWidth={1}
              borderColor="border"
              borderStyle="solid"
              borderRadius={4}
              mt={2}
              mb={2}
              p={2}
              textAlign="center"
            >
              <Text fontSize={18}>
                {`${data.count} ${!data.board.frames ? ` ${type}` : ` ${singularType.toLowerCase()} frames`}`}
              </Text>
            </Box>
            {actions}
            {!data.board.frames && (
              <ContentList
                contents={data.items}
                type={type}
                onPinned={(contentId, pinned) => pinned && updateList(contentId)}
                onReviewed={(contentId) => updateList(contentId)}
                onClick={(contentId) => {
                  modalState.push({
                    showContent: true,
                    showContentId: contentId,
                  });
                }}
                contentLayout={data.board.contentLayout}
                moderate={data.board.reviewable}
              />
            )}

            {data.board.frames && (
              <FrameList
                frames={data.items}
                type={type}
                onPinned={(frameId, pinned) => pinned && updateList(frameId)}
                onReviewed={(frameId) => updateList(frameId)}
                onClick={(contentId) => {
                  modalState.push({
                    showContent: true,
                    showContentId: contentId,
                  });
                }}
                frameLayout={data.board.frameLayout}
                moderate={data.board.reviewable}
              />
            )}
          </Box>
        )}

        {data && data.items && data.items.length === 0 && (
          <Text color="muted" mb={2} mt={4}>
            {STRINGS.none}
          </Text>
        )}

        {data && data.items && data.items.length > 0 && actions}
      </Section>
      {updateBoard && data && (
        <CreateOrEditBoard
          type={type}
          frames={data.board.frames}
          create={false}
          board={data.board}
          onUpdate={() => setDependency(Time.now())}
        />
      )}
      {deleteBoard && data && (
        <DeleteBoard
          type={type}
          frames={data.board.frames}
          board={data.board}
        />
      )}
      {showContent && data && (
        <Modal
          width={['100%', '100%', '800px']}
          marginTop={[0, 0, 40]}
          minHeight="600px"
          title="Content"
          overflow="visible"
          overlayStyle={{
            overflowX: 'auto',
            overflowY: 'scroll',
            position: 'fixed',
            zIndex: 11,
          }}
        >
          <ContentSection
            type={type}
            id={showContentId}
            onContentDetails={(content) => {
              modalState.push({
                showContentDetails: true,
                contentDetails: content,
              });
            }}
            onWatch={(content) => {
              modalState.push({
                watchContentVideo: true,
                contentDetails: content,
              });
            }}
            layoutId={data.board.frameLayout ? data.board.frameLayout.id : ''}
            moderate={data.board.reviewable}
          />
        </Modal>
      )}
      {watchContentVideo && (
        <Modal
          title={contentDetails.id}
          width={['100%', '100%', '700px']}
          overlayStyle={{
            overflowX: 'auto',
            overflowY: 'scroll',
            position: 'fixed',
            zIndex: 11,
          }}
        >
          <WatchVideo video={contentDetails} />
        </Modal>
      )}
      {showContentDetails && (
        <Modal
          width={['100%', '100%', '700px']}
          title="Details"
          overlayStyle={{
            overflowX: 'auto',
            overflowY: 'scroll',
            position: 'fixed',
            zIndex: 11,
          }}
        >
          <ReactJson
            src={contentDetails}
            displayDataTypes={false}
          />
        </Modal>
      )}
    </Page>
  );
}

export default Board;
