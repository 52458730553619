import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Form, Modal, Text, useHistory,
} from 'controls';

import api from 'api';
import Utils from 'components/Utils';

function DeleteFolder(props) {
  const {
    resourceType, name, onDelete,
  } = props;
  const history = useHistory();
  const singularType = Utils.toSingular(resourceType);

  return (
    <Modal
      title={`Delete ${singularType.toLowerCase()} folder`}
      width={['100%', '100%', '500px']}
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      <Form
        onSubmit={async () => {
          const response = await api.deleteFolder(resourceType, name);
          if (!response.error) {
            if (onDelete) {
              onDelete();
            }

            history.goBack();
            return null;
          }

          return null;
        }}
        action="Delete"
        actionVariant="error"
        fields={() => (
          <Box>
            <Text
              mb={2}
              color="muted"
              text={`Are you sure you want to delete folder ${name}?`}
            />
          </Box>
        )}
      />
    </Modal>
  );
}

DeleteFolder.propTypes = {
  name: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
};

DeleteFolder.defaultProps = {
  onDelete: null,
};

export default DeleteFolder;
