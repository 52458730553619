import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Divider, Dropdown, Flex, Form, Icon, IconButton, Link, MenuItem,
  Section, Select, stylex, Text, Time, useApi, useHistory,
} from 'controls';

import api from 'api';
import FrameList from 'components/FrameList';
import ContentItem from 'components/ContentItem';
import AudioContent from 'components/AudioContent';

const STRINGS = {
  none: 'There are no frames',
  actions: {
    safe: 'Safe',
    flag: 'Flag',
    marked: 'Marked as safe',
    flagged: 'Flagged as unsafe',
  },
};

function ContentSection(props) {
  const { type, id, frameType } = props;
  const {
    className, onReviewChange,
    onContentDetails, onWatch,
    layoutId, moderate,
  } = props;
  const history = useHistory();

  const {
    data: contentAndLayout,
    isLoading: isContentLoading,
    setData: setContent,
    setDependency: setContentDependency,
    error,
  } = useApi(
    async () => {
      const contentResponse = await api.getContent(type, id);
      const c = contentResponse.data;
      let finalLayoutId = layoutId;
      if (!layoutId && c && c.result) {
        finalLayoutId = c.result.layoutId;
      }

      let l = null;
      if (finalLayoutId) {
        const layoutResponse = await api.getLayout(finalLayoutId);
        l = layoutResponse.data;
      }

      return {
        data: { content: c, layout: l },
        error: contentResponse.error,
      };
    },
    { refresh: null },
  );

  let content = null;
  let layout = null;
  if (contentAndLayout) {
    content = contentAndLayout.content;
    layout = contentAndLayout.layout;
  }

  const {
    data: frames, isLoading: isFramesLoading, setDependency, dependency,
  } = useApi(
    (dep) => api.getFrames(type, id, dep.type),
    { type: frameType, refresh: null },
  );

  let contentLink = `/${type}/${id}`;
  if (layoutId) {
    contentLink = `/${type}/${id}?layoutId=${layoutId}`;
  }

  return (
    <Section mt={2} className={className} loading={isContentLoading}>
      {error && (
        <Box textAlign="center" mt={6}>
          <Text
            color="error"
            text="Error fetching content"
          />
        </Box>
      )}
      {content && (
        <>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              <Link to={contentLink}>
                <Text fontSize="large" fontWeight="bold">
                  {id}
                </Text>
              </Link>
            </Box>
            <Flex>
              <IconButton
                icon="thumbtack"
                color={content.pinned ? 'primary' : 'border'}
                mr={3}
                onClick={async () => {
                  const response = await api.updatePin(
                    type, content.id, null, !content.pinned,
                  );
                  if (!response.error) {
                    setContent(response.data);
                  }
                }}
              />
              <IconButton
                icon="list"
                color="muted"
                onClick={() => {
                  if (onContentDetails) {
                    onContentDetails(content);
                  }
                }}
              />
              {!content.private && type === 'videos' && (
                <Button
                  ml={4}
                  format="inline"
                  text="Watch video"
                  onClick={() => {
                    if (onWatch) {
                      onWatch(content);
                    }
                  }}
                />
              )}
            </Flex>
          </Flex>
          {content.stats && type === 'videos' && (
            <Flex mb={2} justifyContent="space-between" alignItems="center">
              <Text
                fontSize="xx-small"
                color="muted"
                text={`Video (${content.stats.duration.toFixed(1)}s) ${Time.format(content.createdAt, 'YYYY-MM-DD hh:mm:ss A Z')}`}
              />
            </Flex>
          )}

          {content.stats && type === 'streams' && (
            <Flex mb={2} justifyContent="space-between" alignItems="center">
              <Text
                fontSize="xx-small"
                color="muted"
                text="Stream"
              />
            </Flex>
          )}

          {content.stats && type === 'images' && (
            <Flex mb={2} justifyContent="space-between" alignItems="center">
              <Text
                fontSize="xx-small"
                color="muted"
                text="Image"
              />
            </Flex>
          )}

          {content.stats && type === 'gifs' && (
            <Flex mb={2} justifyContent="space-between" alignItems="center">
              <Text
                fontSize="xx-small"
                color="muted"
                text="Gif"
              />
            </Flex>
          )}

          <Divider mb={4} mt={2} />
          {!content.private && moderate && (type === 'videos' || type === 'streams' || type === 'gifs') && (
            <Flex justifyContent="space-between" alignItems="center">
              <Select
                width="160px"
                value={dependency.type}
                options={
                  [
                    { value: 'marked', label: 'Marked' },
                    { value: 'flagged', label: 'Flagged' },
                    { value: 'all', label: 'All' },
                  ]
                }
                onChange={(e) => {
                  history.push(`/${type}/${id}/${e.target.value}`);
                }}
              />
              <Flex>
                {!content.reviewed && (
                  <>
                    <Form
                      mr={2}
                      errorPosition="none"
                      onSubmit={async () => {
                        const response = await api.updateReview(type, id, null, true, false);
                        if (!response.error) {
                          setDependency({ type: dependency.type, refresh: Time.now() });
                          setContentDependency({ refresh: Time.now() });
                          if (onReviewChange) {
                            onReviewChange();
                          }
                        }
                        return null;
                      }}
                      action={(fprops) => (
                        <Button
                          type="submit"
                          variant="accent"
                          loading={fprops.isSubmitting}
                        >
                          <Icon icon="check" mr={2} />
                          {STRINGS.actions.safe}
                        </Button>
                      )}
                    />
                    <Form
                      errorPosition="none"
                      onSubmit={async () => {
                        const response = await api.updateReview(type, id, null, true, true);
                        if (!response.error) {
                          setDependency({ type: dependency.type, refresh: Time.now() });
                          setContentDependency({ refresh: Time.now() });
                          if (onReviewChange) {
                            onReviewChange();
                          }
                        }
                        return null;
                      }}
                      action={(fprops) => (
                        <Button
                          type="submit"
                          variant="error"
                          loading={fprops.isSubmitting}
                        >
                          <Icon icon="exclamation-circle" mr={2} />
                          {STRINGS.actions.flag}
                        </Button>
                      )}
                    />
                  </>
                )}
                {content.reviewed && (
                  <Flex>
                    {!content.flagged && (
                      <Text color="accent-dark">
                        <Icon icon="check" mr={2} />
                        {STRINGS.actions.marked}
                      </Text>
                    )}
                    {content.flagged && (
                      <Text color="error">
                        <Icon icon="exclamation-circle" mr={2} />
                        {STRINGS.actions.flagged}
                      </Text>
                    )}
                  </Flex>
                )}
                <Dropdown
                  ml={4}
                  alignRight
                  menuStyle={{ overflow: 'visible' }}
                  toggle={(
                    <IconButton color="muted" icon="ellipsis-v" />
                  )}
                  menuContent={(
                    <>
                      {content.reviewed && (
                        <MenuItem
                          fontSize="small"
                          py={2}
                          text="Remove review"
                          onClick={async () => {
                            const response = await api.updateReview(
                              type, content.id, null, false, false,
                            );
                            if (!response.error) {
                              setDependency({ type: dependency.type, refresh: Time.now() });
                              setContentDependency({ refresh: Time.now() });
                              if (onReviewChange) {
                                onReviewChange();
                              }
                            }
                          }}
                        />
                      )}
                      {(type === 'videos' || type === 'streams' || type === 'gifs') && (
                        <>
                          <MenuItem
                            fontSize="small"
                            py={2}
                            text="Remove frame reviews"
                            onClick={async () => {
                              const requestItems = frames.map((item) => ({
                                contentId: item.contentId,
                                frameId: item.id,
                                reviewed: false,
                                flagged: false,
                              }));
                              const response = await api.bulkUpdateReview(
                                type, requestItems,
                              );
                              if (!response.error) {
                                setDependency({ type: dependency.type, refresh: Time.now() });
                              }
                            }}
                          />
                          <MenuItem
                            fontSize="small"
                            py={2}
                            text="Mark all frames safe"
                            onClick={async () => {
                              const requestItems = frames.map((item) => ({
                                contentId: item.contentId,
                                frameId: item.id,
                                reviewed: true,
                                flagged: false,
                              }));
                              const response = await api.bulkUpdateReview(
                                type, requestItems,
                              );
                              if (!response.error) {
                                setDependency({ type: dependency.type, refresh: Time.now() });
                              }
                            }}
                          />
                          <MenuItem
                            fontSize="small"
                            py={2}
                            text="Flag all frames"
                            onClick={async () => {
                              const requestItems = frames.map((item) => ({
                                contentId: item.contentId,
                                frameId: item.id,
                                reviewed: true,
                                flagged: true,
                              }));
                              const response = await api.bulkUpdateReview(
                                type, requestItems,
                              );
                              if (!response.error) {
                                setDependency({ type: dependency.type, refresh: Time.now() });
                              }
                            }}
                          />
                          <MenuItem
                            fontSize="small"
                            py={2}
                            text="Delete frames"
                            onClick={async () => {
                              const response = await api.deleteFrames(type, content.id);
                              if (!response.error) {
                                setDependency({ type: dependency.type, refresh: Time.now() });
                                setContentDependency({ refresh: Time.now() });
                              }
                            }}
                          />
                        </>
                      )}
                    </>
                  )}
                />
              </Flex>
            </Flex>
          )}
          {(type === 'images') && (
            <ContentItem
              content={content}
              type={type}
              onDetails={() => onContentDetails(content)}
            />
          )}
          {(type === 'audios') && (
            <AudioContent
              content={content}
              onWatchVideo={onWatch}
            />
          )}
        </>
      )}

      {content && !content.private && (type === 'videos' || type === 'streams' || type === 'gifs') && (
        <Section loading={isFramesLoading} mt={2}>
          {frames && frames.length > 0 && (
            <FrameList
              frames={frames}
              onReviewChange={onReviewChange}
              type={type}
              frameLayout={layout}
              moderate={moderate}
            />
          )}
          {frames && frames.length === 0 && (
            <Text fontSize="medium-large" fontWeight="bold" pl={1} mb={2} mt={6}>
              {STRINGS.none}
            </Text>
          )}
        </Section>
      )}
    </Section>
  );
}

ContentSection.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  frameType: PropTypes.string,
  onContentDetails: PropTypes.func,
  onReviewChange: PropTypes.func,
  onWatch: PropTypes.func,
  type: PropTypes.string.isRequired,
  layoutId: PropTypes.string,
  moderate: PropTypes.bool,
};

ContentSection.defaultProps = {
  className: null,
  frameType: 'all',
  onContentDetails: null,
  onReviewChange: null,
  onWatch: null,
  layoutId: null,
  moderate: false,
};

export default stylex(ContentSection);
