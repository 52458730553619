import React from 'react';
import PropTypes from 'prop-types';

import {
  Box, Button, Divider, Dropdown, ExtLink, Flex, IconButton, Image,
  MenuItem, Page, Section, Text, Time, useApi, useModalState, useParams,
} from 'controls';

import api from 'api';
import Utils from 'components/Utils';
import UploadTemplate from './UploadTemplate';
import UploadModel from './UploadModel';
import DeleteResource from './DeleteResource';

const ResourceItem = (props) => {
  const {
    resourceType,
    resource,
    onDelete,
  } = props;

  const singularType = Utils.toSingular(resourceType);

  return (
    <Box
      pt={3}
    >
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box>
          <Box>
            <ExtLink
              text={resource.resourceName}
              to={resource.resourceUri}
              target="_blank"
            />
          </Box>
          {resourceType === 'templates' && (
            <Box>
              <Text
                fontSize={14}
                mb={1}
                color="muted"
                text={`Original Image Height: ${resource.metadata.imageHeight}`}
              />
              <ExtLink
                to={resource.resourceUri}
                target="_blank"
              >
                <Image src={resource.resourceUri} height="40" />
              </ExtLink>
            </Box>
          )}
        </Box>
        <Box>
          <Dropdown
            alignRight
            closeOnClick={false}
            toggle={(
              <IconButton p={1} color="muted" icon="ellipsis-v" />
            )}
            menuStyle={{
              marginTop: '5px',
            }}
            menuContent={(
              <>
                <MenuItem
                  fontSize="small"
                  py={2}
                  text={`Delete ${singularType.toLowerCase()}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete();
                  }}
                />
              </>
            )}
          />
        </Box>
      </Flex>
      <Divider mt={3} />
    </Box>
  );
};

ResourceItem.propTypes = {
  resourceType: PropTypes.string.isRequired,
  resource: PropTypes.shape({
    folderName: PropTypes.string,
    resourceName: PropTypes.string,
    resourceUri: PropTypes.string,
    metadata: PropTypes.shape({
      imageHeight: PropTypes.string,
    }),
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
};

function Resources(props) {
  const { resourceType } = props;
  const pascalType = Utils.toPascalCase(resourceType);
  const singularType = Utils.toSingular(resourceType);

  const params = useParams();
  const { folderName } = params;

  const {
    data, isLoading, setDependency,
  } = useApi(async () => api.getResources(resourceType, folderName), 0);

  const modalState = useModalState();
  const {
    uploadResource, deleteResource, actionResource,
  } = modalState.get();

  return (
    <Page mt={2}>
      <Section mt={6}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <Box>
            <Flex alignItems="center">
              <Text
                fontWeight="bold"
                text={`${pascalType}`}
              />

              <Button
                ml={4}
                format="inline"
                text="Root"
                to={`/${resourceType}`}
              />
              <Text
                ml={2}
                text={` > ${folderName}`}
              />
            </Flex>
          </Box>
          <Button
            format="inline"
            text={`+ Upload ${singularType.toLowerCase()}`}
            onClick={() => modalState.push({
              uploadResource: true,
            })}
          />
        </Flex>
        <Divider />
      </Section>
      <Section loading={isLoading}>
        {data && data.map((resource) => (
          <ResourceItem
            key={resource.resourceUri}
            resourceType={resourceType}
            resource={resource}
            onDelete={() => modalState.push({
              deleteResource: true,
              actionResource: resource,
            })}
          />
        ))}
        {data && data.length === 0 && (
          <Box mt={6} textAlign="center">
            <Text
              color="muted"
              text={`No ${resourceType} found`}
            />
          </Box>
        )}
      </Section>
      {uploadResource && resourceType === 'templates' && (
        <UploadTemplate
          resourceType={resourceType}
          folderName={folderName}
          onCreate={() => setDependency(Time.now())}
        />
      )}
      {uploadResource && resourceType === 'models' && (
        <UploadModel
          resourceType={resourceType}
          folderName={folderName}
          onCreate={() => setDependency(Time.now())}
        />
      )}
      {deleteResource && (
        <DeleteResource
          resourceType={resourceType}
          folderName={folderName}
          resourceName={actionResource.resourceName}
          onDelete={() => setDependency(Time.now())}
        />
      )}
    </Page>
  );
}

Resources.propTypes = {
  resourceType: PropTypes.string.isRequired,
};

export default Resources;
