import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, Content, ExtLink, Flex, Footer, Hamburger, Header,
  Layout, Menu, MenuItem, NavItem, Route, Switch, Text, ToggleMenu, useApi,
} from 'controls';
import Utils from 'components/Utils';
import Runs from 'pages/Runs';
import Datasets from 'pages/Datasets';
import Dataset from 'pages/Dataset';
import ActiveStreams from 'pages/ActiveStreams';
import Home from 'pages/Home';
import Query from 'pages/Query';
import ContentPage from 'pages/Content';
import Metrics from 'pages/Metrics';
import Status from 'pages/Status';
import Errors from 'pages/Errors';
import Skills from 'pages/Skills';
import Skill from 'pages/Skill';
import Boards from 'pages/Boards';
import Board from 'pages/Board';
import Layouts from 'pages/Layouts';
import Components from 'pages/Components';
import Manage from 'pages/Manage';
import ResourcesRoot from 'pages/ResourcesRoot';
import Resources from 'pages/Resources';
import DocumentationWorkflow from 'documentation/Workflow';
import DocumentationSkills from 'documentation/Skills';
import DocumentationRepository from 'documentation/Repository';
import DocumentationBoards from 'documentation/Boards';
import DocumentationMetrics from 'documentation/Metrics';
import Transition from 'pages/Transition';
import Autopack from 'pages/Autopack';
import TestLanding from 'pages/TestLanding';
import TestHome from 'pages/TestHome';
import api from 'api';
import { actionCreators } from 'stores/Settings';

import HackHome from 'hack/HackHome';
import HackDetect from 'hack/HackDetect';
import HackDetectContent from 'hack/HackDetectContent';
import HackDetectQueue from 'hack/HackDetectQueue';

import Documentation from './documentation';

const STRINGS = {
  footer: [
    <ExtLink href="mailto:watchfor@microsoft.com" key="contact">Contact Us</ExtLink>,
    ' | ',
    <ExtLink href="https://privacy.microsoft.com/en-US/data-privacy-notice" key="privacy">Data Privacy Notice</ExtLink>,
    ' | ',
    <ExtLink href="https://www.microsoft.com/en-us/legal/intellectualproperty/copyright/default" key="terms">Terms of Use</ExtLink>,
    ' | ',
    <ExtLink href="https://www.microsoft.com/en-us/legal/intellectualproperty/Trademarks/EN-US.aspx" key="trademarks">Trademarks</ExtLink>,
  ],
};

const currentYear = new Date().getFullYear();
const COPYRIGHT = `\u00A9 ${currentYear} Microsoft`;

function GetQueryParam(wantedToken, props) {
  const query = new URLSearchParams(props.location.search);
  return query.get(wantedToken);
}

function AuthApp(props) {
  const { settings, initSettings } = props;
  const { contentType } = settings;
  const {
    data,
  } = useApi(async () => api.getUiSettings());

  const loading = (
    <Transition text="Fetching settings" />
  );
  let type = null;

  useEffect(() => {
    if (data && !contentType) {
      initSettings(data);
    }
  }, [data]);

  if (contentType) {
    type = Utils.mapContentTypeToRestType(contentType);
  }

  const app = (
    <Layout>
      <Header
        zIndex={10}
        left={(
          <Button
            color="fg"
            pl={[3, 0]}
            fontSize="large"
            fontWeight="bold"
            to="/"
            format="link"
            text="Watch For"
          />
        )}
        right={(
          <Flex pr={[3, 0]}>
            <NavItem ml={4} display={['none', 'none', 'inline-flex']} to="/test" text="Test" />
            <NavItem ml={4} display={['none', 'none', 'inline-flex']} to={`/${type}/skills`} text="Skills" />
            <NavItem ml={4} display={['none', 'none', 'inline-flex']} to={`/${type}/boards`} text="Boards" />
            <NavItem ml={4} display={['none', 'none', 'inline-flex']} to={`/${type}`} text="Search" />
            <NavItem ml={4} display={['none', 'none', 'inline-flex']} to={`/${type}/metrics`} text="Metrics" />
            <NavItem ml={4} display={['none', 'none', 'inline-flex']} to="/status" text="Status" />
            <ToggleMenu
              ml={4}
              display={['none', 'none', 'inline-flex']}
              toggle={<Hamburger />}
              menu={(
                <Menu
                  container
                  position="fixed"
                  left={0}
                  right={0}
                  top="3rem"
                  width="100%"
                  openStyle={{ height: '100vh' }}
                  closeStyle={{ height: 0 }}
                  style={{ transition: '0.4s' }}
                >
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Documentation"
                    to="/documentation"
                  />
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Repository"
                    to="/repository"
                  />
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Hashes"
                    to="/hashes"
                  />
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Templates"
                    to="/templates"
                  />
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Custom Models"
                    to="/models"
                  />
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Manage"
                    to="/manage"
                  />
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Autopack"
                    to={`/${type}/autopack`}
                  />
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Layouts"
                    to="/layouts"
                  />
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Datasets"
                    to="/datasets"
                  />
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Runs"
                    to="/runs"
                  />
                </Menu>
              )}
            />
            <ToggleMenu
              ml={4}
              display={['inline-flex', 'inline-flex', 'none']}
              toggle={<Hamburger />}
              menu={(
                <Menu
                  container
                  position="fixed"
                  left={0}
                  right={0}
                  top="3rem"
                  width="100%"
                  openStyle={{ height: '100vh' }}
                  closeStyle={{ height: 0 }}
                  style={{ transition: '0.4s' }}
                >
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Documentation"
                    to="/documentation"
                  />
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Test"
                    to="/test"
                  />
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Repository"
                    to="/repository"
                  />
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Skills"
                    to={`/${type}/skills`}
                  />
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Boards"
                    to={`/${type}/boards`}
                  />
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Search"
                    to={`/${type}`}
                  />
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Metrics"
                    to={`/${type}/metrics`}
                  />
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Status"
                    to="/status"
                  />
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Hashes"
                    to="/hashes"
                  />
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Templates"
                    to="/templates"
                  />
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Custom Models"
                    to="/models"
                  />
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Manage"
                    to="/manage"
                  />
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Autopack"
                    to={`/${type}/autopack`}
                  />
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Layouts"
                    to="/layouts"
                  />
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Datasets"
                    to="/datasets"
                  />
                  <MenuItem
                    p={4}
                    textAlign="center"
                    text="Runs"
                    to="/runs"
                  />
                </Menu>
              )}
            />
          </Flex>
        )}
      />
      {/* eslint-disable react/prop-types */}
      <Content pt="3rem" px={[3, 0]}>
        <Switch>
          <Route exact path="/" render={() => <Home contentType={contentType} />} />
          <Route exact path="/hack" render={() => <HackHome />} />
          <Route exact path="/detect" render={() => <HackDetect />} />
          <Route exact path="/detect/:type" render={(rprops) => <HackDetectContent key={rprops.match.params.type} />} />
          <Route exact path="/detect/:type/:queue/:tab" render={(rprops) => <HackDetectQueue key={`${rprops.match.params.type}-${rprops.match.params.tab}`} />} />
          <Route exact path="/runs" render={() => <Runs />} />
          <Route exact path="/datasets" render={() => <Datasets />} />
          <Route exact path="/datasets/:id" render={(rprops) => <Dataset key={`${rprops.match.params.id}-${rprops.location.search}`} />} />
          <Route exact path="/status" render={(rprops) => <Status key={rprops.location.search} contentType={contentType} />} />
          <Route exact path="/errors" render={(rprops) => <Errors key={rprops.location.search} contentType={contentType} />} />
          <Route exact path="/activestreams" component={ActiveStreams} />
          <Route exact path="/layouts" component={Layouts} />
          <Route exact path="/test" component={TestHome} />
          <Route exact path="/test/:type" render={(rprops) => <TestLanding key={rprops.match.params.type} />} />
          <Route exact path="/manage" component={Manage} />
          <Route exact path="/repository" component={Components} />
          <Route exact path="/templates" render={() => <ResourcesRoot key="templates" resourceType="templates" />} />
          <Route exact path="/templates/:folderName" render={(rprops) => <Resources key={`templates-${rprops.match.params.folderName}`} resourceType="templates" />} />
          <Route exact path="/models" render={() => <ResourcesRoot key="models" resourceType="models" />} />
          <Route exact path="/models/:folderName" render={(rprops) => <Resources key={`models-${rprops.match.params.folderName}`} resourceType="models" />} />
          <Route exact path="/documentation" component={Documentation} />
          <Route exact path="/documentation/workflow" component={DocumentationWorkflow} />
          <Route exact path="/documentation/skills" component={DocumentationSkills} />
          <Route exact path="/documentation/repository" component={DocumentationRepository} />
          <Route exact path="/documentation/boards" component={DocumentationBoards} />
          <Route exact path="/documentation/metrics" component={DocumentationMetrics} />
          <Route exact path="/:type/summary" render={(rprops) => <Home key={rprops.match.params.type} />} />
          <Route exact path="/:type/skills" render={(rprops) => <Skills key={rprops.match.params.type} />} />
          <Route exact path="/:type/skills/:skillName" render={() => <Skill key={window.location.pathname} />} />
          <Route exact path="/:type/skills/:skillName/versions/:skillVersion" render={(rprops) => <Skill key={window.location.pathname} skillName={rprops.match.params.skillName} skillVersion={rprops.match.params.skillVersion} />} />
          <Route exact path="/:type/boards" render={(rprops) => <Boards key={`${rprops.match.params.type}-${rprops.location.search}`} />} />
          <Route exact path="/:type/boards/:id" render={(rprops) => <Board key={`${rprops.match.params.id}-${rprops.location.search}`} />} />
          <Route exact path="/:type/metrics" render={(rprops) => <Metrics key={`${rprops.match.params.type}-${rprops.location.search}`} />} />
          <Route exact path="/:type/autopack" render={(rprops) => <Autopack key={`${rprops.match.params.type}-${GetQueryParam('frames', rprops)}-${GetQueryParam('rawData', rprops)}`} />} />
          <Route exact path="/:type" render={(rprops) => <Query key={`${rprops.match.params.type}-${rprops.location.search}`} />} />
          <Route exact path="/:type/:id" component={ContentPage} />
          <Route exact path="/:type/:id/:frameType" render={(rprops) => <ContentPage key={rprops.match.params.frameType} />} />
        </Switch>
      </Content>
      {/* eslint-enable react/prop-types */}
      <Footer px={[3, 0]} mt={4} pt={4} pb="4rem" textAlign="center">
        {STRINGS.footer}
        <Text text={COPYRIGHT} />
      </Footer>
    </Layout>
  );

  if (!contentType) {
    return loading;
  }

  return app;
}

AuthApp.propTypes = {
  initSettings: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    contentType: PropTypes.string,
  }),
};

AuthApp.defaultProps = {
  settings: {
    contentType: null,
  },
};

export default connect(
  (state) => state.settings,
  actionCreators,
)(AuthApp);
