import React from 'react';
import ReactDOM from 'react-dom';
import { MsalProvider } from '@azure/msal-react';

import { msalInstance } from 'auth/msal';
import { FabProvider } from 'controls';
import * as Settings from 'stores/Settings';

import App from './App';
import * as serviceWorker from './serviceWorker';

import 'components/fontawesome';
import './index.css';

const rootElement = document.getElementById('root');

ReactDOM.render(
  <FabProvider rootElement={rootElement} reducers={{ settings: Settings.reducer }}>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </FabProvider>,
  rootElement,
);

serviceWorker.unregister();
