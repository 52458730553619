import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Image, stylex, useDimensions,
} from 'controls';
import {
  Stage, Layer, Line, Rect, Text,
} from 'react-konva';

function AnnotatedImage(props) {
  const {
    alt, blur, className, src,
    imageWidth, imageHeight, result,
    annotate,
  } = props;
  const ref = useRef();
  const { width: containerWidth, height: containerHeight } = useDimensions(ref);
  let canvasWidth = containerWidth;
  let canvasHeight = containerHeight;

  if (imageWidth && imageHeight) {
    if (imageWidth <= containerWidth && imageHeight <= containerHeight) {
      canvasWidth = imageWidth;
      canvasHeight = imageHeight;
    } else {
      const aspectRatio = imageHeight / imageWidth;
      if (aspectRatio < 0.5625) {
        canvasHeight = (containerWidth / imageWidth) * imageHeight;
      } else if (aspectRatio > 0.5625) {
        canvasWidth = (containerHeight / imageHeight) * imageWidth;
      }
    }
  }

  let showObjects = false;
  let showFaces = false;
  let showLines = false;
  if (imageWidth && imageHeight && annotate
    && canvasWidth !== 0 && canvasHeight !== 0) {
    showObjects = result && result.objects && result.objects.length >= 0;
    showFaces = result && result.faces && result.faces.length >= 0;
    showLines = result && result.ocr && result.ocr.lines && result.ocr.lines.length >= 0;
  }

  return (
    <Box
      ref={ref}
      className={className}
      pb="56.25%"
      positive="relative"
    >
      <Image
        position="absolute"
        src={src}
        style={{
          filter: blur ? 'blur(10px) grayscale(100%)' : null,
        }}
        alt={alt}
      />
      {annotate && (showObjects || showFaces || showLines) && (
        <Box position="absolute">
          <Stage width={canvasWidth} height={canvasHeight}>
            <Layer>
              {showObjects && result.objects.map((o) => (
                <React.Fragment key={`${o.left.toString()}-${o.top.toString()}-${o.area.toString()}-${o.class}`}>
                  <Rect
                    x={o.left * canvasWidth}
                    y={o.top * canvasHeight}
                    width={o.width * canvasWidth}
                    height={o.height * canvasHeight}
                    stroke="yellow"
                  />
                  <Text
                    fill="yellow"
                    padding={4}
                    x={o.left * canvasWidth}
                    y={o.top * canvasHeight}
                    text={`${o.class} (${o.score.toFixed(2)})`}
                  />
                </React.Fragment>
              ))}
              {showFaces && result.faces.map((o) => (
                <React.Fragment key={`${o.left}-${o.top}-${o.area}`}>
                  <Rect
                    x={o.left * canvasWidth}
                    y={o.top * canvasHeight}
                    width={o.width * canvasWidth}
                    height={o.height * canvasHeight}
                    stroke="orange"
                  />
                </React.Fragment>
              ))}
              {showLines && result.ocr.lines.map((o) => (
                <React.Fragment key={`${o.points[0].x.toString()}-${o.points[0].y.toString()}}`}>
                  <Line
                    points={o.points.flatMap(
                      (p) => [p.x * canvasWidth, p.y * canvasHeight],
                    )}
                    stroke="red"
                    fill="black"
                    strokeWidth={3}
                    opacity={0.5}
                    closed
                  />
                  <Text
                    fill="white"
                    padding={4}
                    x={o.x * canvasWidth}
                    y={o.y * canvasHeight}
                    text={o.text}
                  />
                </React.Fragment>
              ))}
            </Layer>
          </Stage>
        </Box>
      )}
    </Box>
  );
}

AnnotatedImage.propTypes = {
  alt: PropTypes.string,
  blur: PropTypes.bool,
  className: PropTypes.string,
  src: PropTypes.string,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  result: PropTypes.shape({
    objects: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
    faces: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
    ocr: PropTypes.shape({
      lines: PropTypes.arrayOf(
        PropTypes.shape({}),
      ),
    }),
  }),
  annotate: PropTypes.bool,
};

AnnotatedImage.defaultProps = {
  alt: null,
  blur: false,
  className: null,
  src: null,
  imageWidth: null,
  imageHeight: null,
  result: null,
  annotate: false,
};

export default stylex(AnnotatedImage);
