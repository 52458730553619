import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Divider, Flex, Text, stylex,
} from 'controls';

import Utils from './Utils';

function ComponentItem(props) {
  const {
    add, className, onAdd, onClick, component,
  } = props;
  const {
    description, name,
    contentType, executionType, detectionType,
  } = component;
  const contentTypeSplits = contentType.split(',');
  let displayContentType = '';
  contentTypeSplits.forEach((s, i) => {
    displayContentType = `${displayContentType}${Utils.toPascalCase(s.trim())}`;
    if (i !== contentTypeSplits.length - 1) {
      displayContentType = `${displayContentType} | `;
    }
  });

  const displayDetectionType = Utils.toPascalCase(detectionType);

  return (
    <Button
      as="div"
      variant="fg"
      format="inline"
      width="100%"
      textAlign="left"
      className={className}
      onClick={() => {
        if (onClick) {
          onClick(component);
        }
      }}
    >
      <Box width="100%">
        <Flex justifyContent="space-between" alignItems="center" width="100%">
          <Text
            fontWeight="bold"
            text={name}
          />
          {add && (
            <Button
              format="outline"
              color="primary"
              fontSize={14}
              px={6}
              py={1}
              text="Add"
              onClick={(e) => {
                e.stopPropagation();
                if (onAdd) {
                  onAdd(component);
                }
              }}
            />
          )}
        </Flex>
        <Text
          mt={1}
          fontSize={14}
          textAlign="justify"
          color="muted"
          text={description}
        />
        <Flex mt={1} justifyContent="space-between" alignItems="center" width="100%">
          <Flex>
            <Text
              borderRadius={4}
              bg="accent"
              px={2}
              fontSize={12}
              text={displayDetectionType}
            />
            <Text
              ml={2}
              borderRadius={4}
              bg="border"
              px={2}
              fontSize={12}
              text={displayContentType}
            />
          </Flex>
          <Flex>
            <Text
              color="muted"
              borderWidth={0}
              borderStyle="solid"
              borderRadius={4}
              borderColor="muted"
              fontSize={12}
              text={Utils.toPascalCase(executionType)}
            />
          </Flex>
        </Flex>
        <Divider mt={3} mb={3} />
      </Box>
    </Button>
  );
}

ComponentItem.propTypes = {
  add: PropTypes.bool,
  component: PropTypes.shape({
    author: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
    name: PropTypes.string,
    contentType: PropTypes.string,
    executionType: PropTypes.string,
    detectionType: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onClick: PropTypes.func,
};

ComponentItem.defaultProps = {
  add: true,
  className: null,
  onAdd: null,
  onClick: null,
};

export default stylex(ComponentItem);
