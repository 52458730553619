import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Box, Field, Form, Input, Modal, Select, useHistory,
} from 'controls';

import api from 'api';

function CreateOrEditDataset(props) {
  const {
    create, dataset,
    onUpdate,
  } = props;
  const history = useHistory();

  return (
    <Modal
      title={`${create ? 'Create' : 'Edit'} dataset`}
      width={['100%', '100%', '500px']}
      overflow="visible"
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      <Form
        initialValues={{
          id: dataset.id,
          name: dataset.name,
          contentType: dataset.contentType,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required'),
          contentType: Yup.string().required('Required'),
        })}
        onSubmit={async (values) => {
          if (create) {
            const response = await api.createDataset(
              values.name,
              values.contentType,
            );

            if (!response.error) {
              history.push(`/datasets/${response.data.id}`);
              return null;
            }
          }

          if (!create) {
            const response = await api.updateDataset(
              values.id,
              values.name,
            );

            if (!response.error) {
              if (onUpdate) {
                onUpdate(response.data);
              }

              history.goBack();
              return null;
            }

            if (response.error.status === 404) {
              return 'Dataset not found';
            }
          }

          return null;
        }}
        action={create ? 'Create' : 'Update'}
        fields={() => (
          <Box>
            <Field
              label="Dataset name"
              name="name"
              component={(
                <Input />
              )}
            />
            {create && (
              <Field
                label="Content type"
                name="contentType"
                component={(
                  <Select
                    options={[
                      { label: 'Image', value: 'image' },
                      { label: 'Video', value: 'video' },
                      { label: 'Live Stream', value: 'stream' },
                      { label: 'Gif', value: 'gif' },
                      { label: 'Text', value: 'text' },
                      { label: 'Audio', value: 'audio' },
                    ]}
                  />
                )}
              />
            )}
          </Box>
        )}
      />
    </Modal>
  );
}

CreateOrEditDataset.propTypes = {
  create: PropTypes.bool,
  onUpdate: PropTypes.func,
  dataset: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    contentType: PropTypes.string,
  }),
};

CreateOrEditDataset.defaultProps = {
  create: true,
  onUpdate: null,
  dataset: {
    id: '',
    name: '',
    contentType: 'image',
  },
};

export default CreateOrEditDataset;
