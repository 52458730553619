import React from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import {
  Flex, Modal, useModalState,
} from 'controls';

import FrameItem from './FrameItem';
import CustomLayout from './CustomLayout';

function FrameList(props) {
  const {
    frames, onClick, onPinned,
    onReviewChange, onReviewed, type,
    frameLayout, moderate,
  } = props;

  const modalState = useModalState();
  const { showDetails, details } = modalState.get();

  return (
    <>
      {!(frameLayout && frameLayout.descriptor) && (
        <Flex flexWrap="wrap">
          {frames.map((frame, index) => (
            <FrameItem
              index={index}
              width={['100%', '100%', '50%']}
              key={frame.id}
              frame={frame}
              onClick={onClick}
              onDetails={(d) => {
                modalState.push({
                  showDetails: true,
                  details: d,
                });
              }}
              onPinned={onPinned}
              onReviewChange={onReviewChange}
              onReviewed={onReviewed}
              type={type}
              moderate={moderate}
            />
          ))}
        </Flex>
      )}
      {frameLayout && frameLayout.descriptor && (
        <CustomLayout
          data={frames}
          layout={frameLayout}
          type={type}
          frames
          onDetails={(d) => {
            modalState.push({
              showDetails: true,
              details: d,
            });
          }}
        />
      )}
      {showDetails && (
        <Modal
          width={['100%', '100%', '700px']}
          title="Details"
          overlayStyle={{
            overflowX: 'auto',
            overflowY: 'scroll',
            position: 'fixed',
            zIndex: 11,
          }}
        >
          <ReactJson
            src={details}
            displayDataTypes={false}
          />
        </Modal>
      )}
    </>
  );
}

FrameList.propTypes = {
  frames: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  onClick: PropTypes.func,
  onPinned: PropTypes.func,
  onReviewChange: PropTypes.func,
  onReviewed: PropTypes.func,
  type: PropTypes.string,
  frameLayout: PropTypes.shape({
    descriptor: PropTypes.string,
  }),
  moderate: PropTypes.bool,
};

FrameList.defaultProps = {
  frames: null,
  onClick: null,
  onPinned: null,
  onReviewChange: null,
  onReviewed: null,
  type: null,
  frameLayout: null,
  moderate: false,
};

export default FrameList;
