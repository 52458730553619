import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Divider, Dropdown, Flex, IconButton, MenuItem, Text, stylex,
} from 'controls';

import Utils from './Utils';

function SkillComponentItem(props) {
  const {
    className, index,
    onClick, onEditHandle, onEditProperties,
    onRemove, skillComponent, disabled,
  } = props;

  const { contentType, executionType, detectionType } = skillComponent.component;

  const contentTypeSplits = contentType.split(',');
  let displayContentType = '';
  contentTypeSplits.forEach((s, i) => {
    displayContentType = `${displayContentType}${Utils.toPascalCase(s.trim())}`;
    if (i !== contentTypeSplits.length - 1) {
      displayContentType = `${displayContentType} | `;
    }
  });

  const displayDetectionType = Utils.toPascalCase(detectionType);

  return (
    <Box
      px={0}
      pt={2}
      borderRadius={4}
      borderWidth={1}
      borderColor="border"
      borderStyle="none"
      className={className}
    >
      <Box width="100%">
        <Flex
          width="100%"
          alignItems="flex-start"
        >
          <Box
            width="100%"
          >
            <Flex justifyContent="space-between" alignItems="center" width="100%">
              <Flex>
                <Button
                  variant="fg"
                  display="block"
                  format="inline"
                  text={skillComponent.component.name}
                  onClick={() => {
                    if (onClick) {
                      onClick(skillComponent);
                    }
                  }}
                />
                <Text
                  ml={3}
                  borderWidth={1}
                  borderStyle="solid"
                  borderRadius={4}
                  borderColor="border"
                  color="muted"
                  px={2}
                  fontSize={12}
                  text={displayDetectionType}
                />
                <Text
                  ml={2}
                  borderWidth={1}
                  borderStyle="solid"
                  borderRadius={4}
                  borderColor="border"
                  color="muted"
                  px={2}
                  fontSize={12}
                  text={displayContentType}
                />
                <Text
                  ml={2}
                  color="muted"
                  borderWidth={0}
                  borderStyle="solid"
                  borderRadius={4}
                  borderColor="muted"
                  fontSize={0}
                  text={Utils.toPascalCase(executionType)}
                />
              </Flex>
            </Flex>
            <Button
              mt={1}
              display="block"
              format="inline"
              fontSize={14}
              color="muted"
              text={`Handle: ${skillComponent.handle}`}
              onClick={(e) => {
                e.stopPropagation();
                if (onEditHandle) {
                  onEditHandle(skillComponent);
                }
              }}
              disabled={disabled}
            />
            {skillComponent.properties.length > 0 && skillComponent.valid && (
              <Button
                mt={2}
                display="block"
                format="inline"
                fontSize={12}
                color="primary-light"
                text="View properties"
                onClick={(e) => {
                  e.stopPropagation();
                  if (onEditProperties) {
                    onEditProperties(skillComponent);
                  }
                }}
                disabled={disabled}
              />
            )}
            {!skillComponent.valid && (
              <Button
                mt={2}
                display="block"
                format="inline"
                fontSize={12}
                color="error"
                text="Required properties not set"
                onClick={(e) => {
                  e.stopPropagation();
                  if (onEditProperties) {
                    onEditProperties(skillComponent);
                  }
                }}
                disabled={disabled}
              />
            )}
          </Box>
          <Box>
            {!disabled && (
              <Dropdown
                alignRight
                closeOnClick={false}
                toggle={(
                  <IconButton p={1} color="muted" icon="ellipsis-v" />
                )}
                menuStyle={{
                  marginTop: '5px',
                }}
                menuContent={(
                  <>
                    {skillComponent.properties.length > 0 && (
                      <MenuItem
                        fontSize="small"
                        py={2}
                        text="Edit properties"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (onEditProperties) {
                            onEditProperties(skillComponent);
                          }
                        }}
                        divider
                      />
                    )}
                    <MenuItem
                      fontSize="small"
                      py={2}
                      text="Edit handle"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (onEditHandle) {
                          onEditHandle(skillComponent);
                        }
                      }}
                      divider
                    />
                    <MenuItem
                      fontSize="small"
                      py={2}
                      text="Remove"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (onRemove) {
                          onRemove(skillComponent, index);
                        }
                      }}
                    />
                  </>
                )}
              />
            )}
          </Box>
        </Flex>
        <Divider mt={2} style={{ opacity: 0.5 }} />
      </Box>
    </Box>
  );
}

SkillComponentItem.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  index: PropTypes.number,
  onClick: PropTypes.func,
  onEditHandle: PropTypes.func,
  onEditProperties: PropTypes.func,
  onRemove: PropTypes.func,
  skillComponent: PropTypes.shape({
    component: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      contentType: PropTypes.string,
      executionType: PropTypes.string,
      detectionType: PropTypes.string,
    }),
    properties: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
    handle: PropTypes.string,
    valid: PropTypes.bool,
  }).isRequired,
};

SkillComponentItem.defaultProps = {
  className: null,
  disabled: false,
  index: 0,
  onClick: null,
  onEditHandle: null,
  onEditProperties: null,
  onRemove: null,
};

export default stylex(SkillComponentItem);
