import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Divider, Dropdown, Flex, IconButton, MenuItem,
  Page, Section, Tabs, TabItem, Text, Time, useApi, useParams, useQuery, useModalState,
} from 'controls';

import api from 'api';
import Utils from 'components/Utils';
import CreateOrEditBoard from './CreateOrEditBoard';
import DeleteBoard from './DeleteBoard';

const BoardItem = (props) => {
  const {
    type,
    board,
    onUpdate,
    onDelete,
  } = props;

  return (
    <Box
      pt={3}
    >
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box>
          <Button
            format="inline"
            text={board.id}
            to={`/${type}/boards/${board.id}`}
          />
          <Text
            fontSize={14}
            color="muted"
            text={board.name}
          />
          <Text
            fontSize={12}
            color="muted"
            text={`Updated: ${Time.format(board.updatedAt, 'MMM DD, YYYY LTS')}`}
          />
        </Box>
        <Box>
          <Dropdown
            alignRight
            closeOnClick={false}
            toggle={(
              <IconButton p={1} color="muted" icon="ellipsis-v" />
            )}
            menuStyle={{
              marginTop: '5px',
            }}
            menuContent={(
              <>
                <MenuItem
                  divider
                  fontSize="small"
                  py={2}
                  text="Update board"
                  onClick={(e) => {
                    e.stopPropagation();
                    onUpdate();
                  }}
                />
                <MenuItem
                  fontSize="small"
                  py={2}
                  text="Delete board"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete();
                  }}
                />
              </>
            )}
          />
        </Box>
      </Flex>
      <Divider mt={3} />
    </Box>
  );
};

BoardItem.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  board: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    updatedAt: PropTypes.number,
  }).isRequired,
  type: PropTypes.string.isRequired,
};

function Boards() {
  const params = useParams();
  const { type } = params;
  const queryParams = useQuery();
  const frames = queryParams.get('frames') === 'true';
  const singularType = Utils.toSingular(type);

  const {
    data, isLoading, setDependency,
  } = useApi(async () => api.getBoards(type, frames), 0);

  const modalState = useModalState();
  const {
    createBoard, updateBoard,
    deleteBoard, board, mframes,
  } = modalState.get();

  return (
    <Page mt={2}>
      {(type === 'videos' || type === 'streams' || type === 'gifs') && (
        <Tabs mt={2}>
          <TabItem
            text={`${singularType} boards`}
            active={!frames}
            to={`/${type}/boards`}
          />
          <TabItem
            text="Frame boards"
            active={frames}
            to={`/${type}/boards?frames=true`}
          />
        </Tabs>
      )}

      <Section pt={6} loading={isLoading}>
        {data && (
          <Flex
            justifyContent="space-between"
            alignItems="center"
            mb={3}
          >
            <Text
              fontWeight="bold"
              text={`${singularType} ${frames ? 'frame' : ''} boards`}
            />

            <Button
              format="inline"
              text="+ Create board"
              onClick={() => modalState.push({
                createBoard: true,
                mframes: frames,
              })}
            />
          </Flex>
        )}
        <Divider />
        {data && data.map((b) => (
          <BoardItem
            type={type}
            board={b}
            key={b.id}
            onUpdate={() => modalState.push({
              updateBoard: true,
              board: b,
              mframes: frames,
            })}
            onDelete={() => modalState.push({
              deleteBoard: true,
              board: b,
              mframes: frames,
            })}
          />
        ))}
        {data && data.length === 0 && (
          <Box mt={6} textAlign="center">
            <Text
              color="muted"
              text={`No ${singularType.toLowerCase()} ${frames ? 'frame' : ''} boards found`}
            />
          </Box>
        )}
      </Section>
      {createBoard && (
        <CreateOrEditBoard
          type={type}
          frames={mframes}
          create
        />
      )}
      {updateBoard && (
        <CreateOrEditBoard
          type={type}
          frames={mframes}
          create={false}
          board={board}
          onUpdate={() => setDependency(Time.now())}
        />
      )}
      {deleteBoard && (
        <DeleteBoard
          type={type}
          frames={mframes}
          board={board}
          onDelete={() => setDependency(Time.now())}
        />
      )}
    </Page>
  );
}

export default Boards;
