import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, ExtLink, IconButton, Image, Link, Table, Text,
} from 'controls';

function CustomLayout(props) {
  const {
    data, layout, onDetails,
    type, frames,
  } = props;

  let layoutError = null;

  /* eslint-disable react/prop-types */
  const tableColumns = [];
  if (layout && layout.descriptor) {
    try {
      const cols = JSON.parse(layout.descriptor);
      cols.forEach((c) => {
        let cell = null;
        let innerCell = null;
        let { field } = c;
        if (c.type === 'details') {
          field = '_details';
          innerCell = ({ row }) => (
            <IconButton
              icon="list"
              color="muted"
              m={2}
              onClick={() => onDetails(row.original)}
            />
          );
        } else if (!c.type || c.type === 'text') {
          innerCell = ({ row }) => (
            <Text
              m={2}
              fontSize="small"
              text={!c.fixed ? row.values[c.field] : row.values[c.field].toFixed(c.fixed)}
            />
          );
        } else if (c.type === 'image') {
          innerCell = ({ row }) => (
            <Box m={2}>
              <Image
                src={row.values[c.field]}
              />
            </Box>
          );
        }

        if (c.extLinkField) {
          cell = ({ row }) => (
            <ExtLink href={row.original[c.extLinkField]} target="_blank">
              {innerCell({ row })}
            </ExtLink>
          );
        } else if (c.linkContent) {
          cell = ({ row }) => (
            <Link to={`/${type}/${frames ? row.original.contentId : row.original.id}`}>
              {innerCell({ row })}
            </Link>
          );
        } else {
          cell = innerCell;
        }

        const tableColumn = {
          accessor: field,
          Cell: cell,
        };
        if (c.header) {
          tableColumn.Header = (
            <Text
              p={2}
              fontSize="small"
              textAlign="left"
              text={c.header}
            />
          );
        }
        if (c.width) {
          tableColumn.width = c.width;
        }
        tableColumns.push(tableColumn);
      });
    } catch {
      layoutError = 'Error in layout';
    }
  }
  /* eslint-enable react/prop-types */

  return (
    <>
      {!layoutError && (
        <Table
          mt={2}
          data={data}
          columns={tableColumns}
        />
      )}
      {layoutError && (
        <Text
          mt={2}
          text={layoutError}
          color="error"
        />
      )}
    </>
  );
}

CustomLayout.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  layout: PropTypes.shape({
    descriptor: PropTypes.string,
  }),
  onDetails: PropTypes.func,
  type: PropTypes.string,
  frames: PropTypes.bool,
};

CustomLayout.defaultProps = {
  data: null,
  layout: null,
  onDetails: null,
  type: null,
  frames: false,
};

export default CustomLayout;
