import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import ReactJson from 'react-json-view';
import {
  Box, Field, Form, Section, Textarea,
} from 'controls';

import api from 'api';

function HackDetectTextTest(props) {
  const { skillId } = props;
  const [data, setData] = useState(null);

  return (
    <Section mt={4}>
      <Section mt={8}>
        <Form
          initialValues={{
            text: '',
          }}
          validationSchema={Yup.object().shape({
            text: Yup.string().required('Required'),
          })}
          onSubmit={async (values) => {
            const response = await api.hackPostText(
              values.text,
              skillId,
            );

            if (!response.error) {
              setData(response.data);
              return null;
            }

            return response.error.status.toString();
          }}
          action="Run"
          fields={() => (
            <Box mb={2}>
              <Field
                name="text"
                component={(
                  <Textarea
                    placeholder="Type text here"
                  />
                )}
              />
            </Box>
          )}
        />
      </Section>
      {data && (
        <Section mt={6}>
          <ReactJson
            src={data}
            displayDataTypes={false}
          />
        </Section>
      )}
    </Section>
  );
}

HackDetectTextTest.propTypes = {
  skillId: PropTypes.string.isRequired,
};

export default HackDetectTextTest;
