const INIT_SETTINGS = 'INIT_SETTINGS';

export const actionCreators = {
  initSettings: (settings) => ({ type: INIT_SETTINGS, settings }),
};

export const reducer = (state, action) => {
  if (action.type === INIT_SETTINGS) {
    const { settings } = action;
    return {
      ...state,
      settings,
    };
  }

  return state || {};
};
