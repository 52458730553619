import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Form, Modal, Text, useHistory,
} from 'controls';

import api from 'api';

function SetActiveSkill(props) {
  const {
    type, skillName, onSetActive, skillVersion, setDefault,
  } = props;
  const history = useHistory();
  const versionStr = `version ${skillVersion}`;
  const defaultStr = setDefault ? ' and set default ' : '';
  return (
    <Modal
      title={`Activate ${type} skill`}
      width={['100%', '100%', '500px']}
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      <Form
        onSubmit={async () => {
          const response = await api.setActiveSkill(type, skillName, skillVersion);
          if (!response.error) {
            if (onSetActive) {
              onSetActive();
            }
            history.push(`/${type}/skills/${skillName}`);
            return null;
          }

          if (response.error.status === 404) {
            return 'Skill not found';
          }

          return null;
        }}
        action="Set active"
        fields={() => (
          <Box>
            <Text
              mb={2}
              color="muted"
              text={`Are you sure you want to activate ${defaultStr} the skill with skillName: ${skillName}, version: ${versionStr}?`}
            />
          </Box>
        )}
      />
    </Modal>
  );
}

SetActiveSkill.propTypes = {
  onSetActive: PropTypes.func,
  skillName: PropTypes.string,
  setDefault: PropTypes.bool,
  skillVersion: PropTypes.number,
  type: PropTypes.string.isRequired,
};

SetActiveSkill.defaultProps = {
  onSetActive: null,
  skillName: null,
  setDefault: false,
  skillVersion: null,
};

export default SetActiveSkill;
