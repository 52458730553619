import React from 'react';

import {
  Box, Page, Text,
} from 'controls';

function Unauthorized() {
  return (
    <Page mt={6}>
      <Box width="100%" textAlign="center" mt={6}>
        <Text
          text="Not Authorized"
        />
      </Box>
    </Page>
  );
}

export default Unauthorized;
