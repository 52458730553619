import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Box, Field, Form, Input, Modal, useHistory, Select,
} from 'controls';

import api from 'api';

function EditAutopackSettings(props) {
  const {
    settings, onUpdate,
  } = props;
  const history = useHistory();

  return (
    <Modal
      overflow="visible"
      title="Edit Autopack Settings"
      width={['100%', '100%', '480px']}
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      <Form
        initialValues={{
          minutesToRunExperiment: settings.minutesToRunExperiment,
          refreshDelay: settings.refreshDelay,
          minProcesses: settings.minProcesses,
          maxProcesses: settings.maxProcesses,
          minThreads: settings.minThreads,
          maxThreads: settings.maxThreads,
          maxConcurrentExperiments: settings.maxConcurrentExperiments,
          pinSettings: settings.pinSettings,
          contentType: settings.contentType,
          containerImage: settings.containerImage,
        }}
        validationSchema={Yup.object().shape({
          minutesToRunExperiment: Yup.number().required('Required').positive(),
          refreshDelay: Yup.number().required('Required').positive('Must be positive'),
          minProcesses: Yup.number().required('Required').positive('Must be positive'),
          maxProcesses: Yup.number().required('Required').positive('Must be positive'),
          minThreads: Yup.number().required('Required').positive('Must be positive'),
          maxThreads: Yup.number().required('Required').positive('Must be positive'),
          maxConcurrentExperiments: Yup.number().required('Required').positive('Must be positive'),
        })}
        onSubmit={async (values) => {
          const response = await api.updateAutopackSettings(
            values.minutesToRunExperiment,
            values.refreshDelay,
            values.minProcesses,
            values.maxProcesses,
            values.minThreads,
            values.maxThreads,
            values.maxConcurrentExperiments,
            values.pinSettings,
            values.contentType,
            values.containerImage,
          );
          if (!response.error) {
            if (onUpdate) {
              onUpdate();
            }

            history.goBack();
            return null;
          }

          return null;
        }}
        action="Update"
        fields={() => (
          <Box>
            <Field
              label="Minutes to run experiment"
              name="minutesToRunExperiment"
              component={(
                <Input
                  type="number"
                />
              )}
            />
            <Field
              label="Refresh delay"
              name="refreshDelay"
              component={(
                <Input
                  type="number"
                />
              )}
            />
            <Field
              label="Min Processes"
              name="minProcesses"
              component={(
                <Input
                  type="number"
                />
              )}
            />
            <Field
              label="Max Processes"
              name="maxProcesses"
              component={(
                <Input
                  type="number"
                />
              )}
            />
            <Field
              label="Min Threads"
              name="minThreads"
              component={(
                <Input
                  type="number"
                />
              )}
            />
            <Field
              label="Max Threads"
              name="maxThreads"
              component={(
                <Input
                  type="number"
                />
              )}
            />
            <Field
              label="Max Concurrent Experiments"
              name="maxConcurrentExperiments"
              component={(
                <Input
                  type="number"
                />
              )}
            />
            <Field
              label="Pin Settings"
              name="pinSettings"
              component={(
                <Select
                  options={[
                    { label: 'Pin', value: 'pin' },
                    { label: 'UnPin', value: 'unPin' },
                    { label: 'Both', value: 'both' },
                  ]}
                />
              )}
            />
            <Field
              label="Content Type"
              name="contentType"
              component={(
                <Select
                  options={[
                    { label: 'Audio', value: 'audio' },
                    { label: 'Image', value: 'image' },
                    { label: 'Video', value: 'video' },
                    { label: 'Text', value: 'text' },
                    { label: 'Gif', value: 'gif' },
                  ]}
                />
              )}
            />
            <Field
              label="Container Image"
              name="containerImage"
              component={(
                <Input
                  type="string"
                />
              )}
            />
          </Box>

        )}
      />
    </Modal>
  );
}

EditAutopackSettings.propTypes = {
  onUpdate: PropTypes.func,
  settings: PropTypes.shape({
    minutesToRunExperiment: PropTypes.number,
    refreshDelay: PropTypes.number,
    minProcesses: PropTypes.number,
    maxProcesses: PropTypes.number,
    minThreads: PropTypes.number,
    maxThreads: PropTypes.number,
    maxConcurrentExperiments: PropTypes.number,
    pinSettings: PropTypes.string,
    contentType: PropTypes.string,
    containerImage: PropTypes.number,
  }).isRequired,
};

EditAutopackSettings.defaultProps = {
  onUpdate: null,
};

export default EditAutopackSettings;
