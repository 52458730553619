import React from 'react';
import {
  Button, Page, Section, Text,
} from 'controls';

import Collapsible from 'components/Collapsible';

function HackHome() {
  return (
    <Page mt={4}>
      <Section mt={6} mb={4}>
        <Collapsible mt={4} title="Risk Assessment" defaultToggle={false}>
          <Text mt={4} color="muted" text="TODO" />
        </Collapsible>
        <Collapsible mt={4} title="User Reporting" defaultToggle={false}>
          <Text mt={4} color="muted" text="TODO" />
        </Collapsible>
        <Collapsible mt={4} title="Automatic Detection" defaultToggle={false}>
          <Text
            mt={4}
            color="muted"
            fontSize={14}
            text="Automatically detect negative and positive traits in various content types with built-in models, components, services and various detection assets in the company."
          />
          <Button
            mt={4}
            mb={2}
            fontSize={14}
            format="inline"
            to="/detect"
            text="Get Started"
          />
        </Collapsible>
        <Collapsible mt={4} title="Human Moderation" defaultToggle={false}>
          <Text mt={4} color="muted" text="TODO" />
        </Collapsible>
        <Collapsible mt={4} title="Product Actions" defaultToggle={false}>
          <Text mt={4} color="muted" text="TODO" />
        </Collapsible>
        <Collapsible mt={4} title="Appeals Handling" defaultToggle={false}>
          <Text mt={4} color="muted" text="TODO" />
        </Collapsible>
        <Collapsible mt={4} title="Legal Escalation" defaultToggle={false}>
          <Text mt={4} color="muted" text="TODO" />
        </Collapsible>
        <Collapsible mt={4} title="Transparency Reporting" defaultToggle={false}>
          <Text mt={4} color="muted" text="TODO" />
        </Collapsible>
      </Section>
    </Page>
  );
}

export default HackHome;
