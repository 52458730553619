import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Flex, IconButton, Text, stylex,
} from 'controls';

function Collapsible(props) {
  const {
    children, className, title,
    defaultToggle,
  } = props;
  const [toggle, setToggle] = useState(defaultToggle);

  return (
    <Box
      className={className}
      px={3}
      py={2}
      borderColor="border"
      borderStyle="solid"
      borderRadius={4}
      borderWidth={1}
    >
      <Button
        as="div"
        variant="fg"
        format="inline"
        width="100%"
        onClick={() => setToggle(!toggle)}
      >
        <Flex
          width="100%"
          justifyContent="space-between"
        >
          <Text
            fontWeight="bold"
            text={title}
          />
          <IconButton
            icon={toggle ? 'chevron-up' : 'chevron-down'}
          />
        </Flex>
      </Button>
      {toggle && children}
    </Box>
  );
}

Collapsible.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  title: PropTypes.string,
  defaultToggle: PropTypes.bool,
};

Collapsible.defaultProps = {
  children: null,
  className: null,
  title: '',
  defaultToggle: true,
};

export default stylex(Collapsible);
