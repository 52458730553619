import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import ReactJson from 'react-json-view';
import {
  Box, Form, Section,
} from 'controls';

import api from 'api';

function HackDetectCustomTest(props) {
  const { skillId } = props;
  const [data, setData] = useState(null);

  return (
    <Section mt={4}>
      <Section mt={8}>
        <Form
          initialValues={{
          }}
          validationSchema={Yup.object().shape({})}
          onSubmit={async () => {
            const response = await api.hackPostCustom(
              skillId,
            );

            if (!response.error) {
              setData(response.data);
              return null;
            }

            return response.error.status.toString();
          }}
          action="Run"
          fields={() => (
            <Box mb={2} />
          )}
        />
      </Section>
      {data && (
        <Section mt={6}>
          <ReactJson
            src={data}
            displayDataTypes={false}
          />
        </Section>
      )}
    </Section>
  );
}

HackDetectCustomTest.propTypes = {
  skillId: PropTypes.string.isRequired,
};

export default HackDetectCustomTest;
