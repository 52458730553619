import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Flex, stylex, TruncatedText, Button, Text,
} from 'controls';

function AudioContent(props) {
  const {
    content,
    onWatchVideo,
  } = props;

  return (
    <Box position="relative" pb={2}>
      <Flex alignItems="center">
        <Button
          borderColor="muted"
          py={1}
          px={2}
          fontSize="xx-small"
          text="Listen"
          bg="rgba(0, 0, 0, 0.5)"
          onClick={() => {
            if (onWatchVideo) {
              onWatchVideo(content);
            }
          }}
        />
        {content.stats && (
          <Text
            ml={2}
            color="muted"
            fontSize={12}
            text={`${content.stats.duration.toFixed(1)}s`}
          />
        )}
      </Flex>

      {content.result && content.result.text && (
        <Box mt={4}>
          <TruncatedText
            text={content.result.text}
          />
        </Box>
      )}

      {content.result && content.result.overlay && (
        <Text
          mt={2}
          color="muted"
          fontSize={14}
          text={content.result.overlay}
        />
      )}

    </Box>
  );
}

AudioContent.propTypes = {
  content: PropTypes.shape({
    createdAt: PropTypes.number,
    contentUri: PropTypes.string.isRequired,
    result: PropTypes.shape({
      text: PropTypes.string,
      overlay: PropTypes.string,
    }),
    stats: PropTypes.shape({
      duration: PropTypes.number.isRequired,
    }),
  }).isRequired,
  onWatchVideo: PropTypes.func,
};

AudioContent.defaultProps = {
  onWatchVideo: null,
};

export default stylex(AudioContent);
