import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Box, Field, Form, Input, Modal, useHistory,
} from 'controls';

import api from 'api';
import Utils from 'components/Utils';

function CreateFolder(props) {
  const { resourceType, onCreate } = props;
  const history = useHistory();
  const singularType = Utils.toSingular(resourceType);

  return (
    <Modal
      title={`Create ${singularType.toLowerCase()} folder`}
      width={['100%', '100%', '500px']}
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      <Form
        initialValues={{
          name: '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Required'),
        })}
        onSubmit={async (values) => {
          const response = await api.createFolder(resourceType, values.name);
          if (!response.error) {
            if (onCreate) {
              onCreate();
            }

            history.goBack();
            return null;
          }

          return null;
        }}
        action="Create"
        fields={() => (
          <Box>
            <Field
              label="New folder name"
              name="name"
              component={(
                <Input />
              )}
            />
          </Box>
        )}
      />
    </Modal>
  );
}

CreateFolder.propTypes = {
  resourceType: PropTypes.string.isRequired,
  onCreate: PropTypes.func,
};

CreateFolder.defaultProps = {
  onCreate: null,
};

export default CreateFolder;
