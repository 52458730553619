import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Form, Modal, Text,
} from 'controls';

import api from 'api';

function SetDefaultSkill(props) {
  const {
    type, skillName, onSetDefault, skillVersion,
  } = props;
  const versionStr = skillVersion === null ? '' : `version ${skillVersion}`;
  return (
    <Modal
      title={`Set default ${type} skill`}
      width={['100%', '100%', '500px']}
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      <Form
        goBackOnSuccess
        onSubmit={async () => {
          const response = await api.setDefaultSkill(type, skillName, skillVersion);
          if (!response.error) {
            if (onSetDefault) {
              onSetDefault();
            }

            return null;
          }

          if (response.error.status === 404) {
            return 'Skill not found';
          }

          return null;
        }}
        action="Set default"
        fields={() => (
          <Box>
            <Text
              mb={2}
              color="muted"
              text={`Are you sure you want to set skill ${skillName} ${versionStr} as the default skill?`}
            />
          </Box>
        )}
      />
    </Modal>
  );
}

SetDefaultSkill.propTypes = {
  onSetDefault: PropTypes.func,
  skillName: PropTypes.string,
  skillVersion: PropTypes.number,
  type: PropTypes.string.isRequired,
};

SetDefaultSkill.defaultProps = {
  onSetDefault: null,
  skillName: null,
  skillVersion: null,
};

export default SetDefaultSkill;
