import React from 'react';
import PropTypes from 'prop-types';

function WatchVideo(props) {
  const { video } = props;
  return (
    <>
      {/* eslint-disable */}
      <video autoPlay controls width="100%">
        <source
          src={video.contentUri}
          type="video/mp4"
        />
      </video>
      {/* eslint-enable */}
    </>
  );
}

WatchVideo.propTypes = {
  video: PropTypes.shape({
    contentUri: PropTypes.string,
  }).isRequired,
};

export default WatchVideo;
