import React from 'react';

import Markdown from './Markdown';

const markdown = `
## Repository and Components
Watch For manages a growing [repository](/repository) of AI models and libraries, called *Components*.
These components include models to analyze media (e.g. for inappropriate content, face detection, OCR) 
as well as general-purpose libraries to perform various tasks (e.g. image hashing).
The components come from three sources:
* Components built by teams across Microsoft (e.g. CVS, Xbox, Bing)
* State-of-the art components built by the open source community
* Components built by the Watch For team

Watch For standardizes the interfaces to components so that they can be 
invoked in a consistent manner from [skills](/documentation/skills) when building a custom analysis pipeline. 
An onboarding team can also bring in their own components to the repository and combine them
with existing models to address context and fill gaps in coverage.
A component can also call into external APIs (e.g. cognitive services, self-hosted) and can be invoked in the
same way as other components.

### Onboarding custom components
Watch For team is working on tools and SDK to make it easy to add custom models and libraries to the repository.
For now, directly work with the Watch For team to onboard them.
Components can be private to a deployment or can be shared across deployments.
**AI models require CELA approval before they can be run on production data.**
Watch For team's CELA will work with the CELA of the team that built the models to get approval.

### Building new models
Watch For team has built an in-house framework, called Pixie, that can used to quickly train and manage 
custom AI image models. Pixie is similar to Azure custom vision, however, significantly optimized for videos.
Using Pixie, a team can ingest large amounts of videos, explore them, label images, train models
and iteratively improve them. Contact the Watch For team for more information on Pixie.

### WIP
The following components are currently being onboarded (pending CELA approval):
* Xbox micro-classifiers to identify various forms of inappropriate content.
* LinkedIn's adult and shocking classifiers that can detect adult and gore content.
* Text detection model to efficiently detect text in images before invoking a full OCR.
* Object detection model to find real-world objects

We are also looking at a few open source models:
* [NSFW Detection](https://github.com/gantman/nsfw_model) open source model that can classify drawing, hentai, porn and sexy images.
* [Yahoo's NSFW model](https://github.com/yahoo/open_nsfw) that can detect adult content.

<br/><br/>
`;

export default function Doc() {
  return <Markdown markdown={markdown} />;
}
