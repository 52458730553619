import React from 'react';
import {
  Box, Button, Flex, Page, useParams, Divider, Section, Text,
} from 'controls';
import Utils from '../components/Utils';
import TestContent from './TestContent';

function TestLanding() {
  const params = useParams();
  const { type } = params;
  return (
    <Page mt={4}>
      <Section mt={6} mb={4}>
        <Box>
          <Flex alignItems="center">
            <Button
              fontSize={17}
              format="inline"
              text="Test"
              to="/test"
            />
            <Text
              ml={2}
              mr={1}
              fontSize={17}
              text=">"
            />
            <Text
              ml={2}
              fontSize={17}
              text={Utils.toPascalCase(type)}
            />
          </Flex>
        </Box>
      </Section>
      <Divider />
      <Section mt={4}>
        <TestContent type={type} />
      </Section>
    </Page>
  );
}

export default TestLanding;
