import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Box, Field, Form, Input, Modal, useHistory,
} from 'controls';

import api from 'api';
import Utils from 'components/Utils';

function UploadTemplate(props) {
  const { resourceType, folderName, onCreate } = props;
  const history = useHistory();
  const singularType = Utils.toSingular(resourceType);

  return (
    <Modal
      title={`Upload ${singularType.toLowerCase()}`}
      width={['100%', '100%', '500px']}
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      <Form
        initialValues={{
          height: '',
          resourceName: '',
          resource: null,
        }}
        validationSchema={Yup.object().shape({
          height: Yup.number().required('Required'),
          resourceName: Yup.string().required('Required'),
        })}
        onSubmit={async (values) => {
          const response = await api.uploadResource(
            resourceType,
            folderName,
            values.resourceName,
            values.resource,
            { imageHeight: values.height },
          );
          if (!response.error) {
            if (onCreate) {
              onCreate();
            }

            history.goBack();
            return null;
          }

          if (response.error.status === 409) {
            return 'Conflict';
          }

          return null;
        }}
        action="Upload"
        fields={(fprops) => (
          <Box>
            <Input
              mb={4}
              type="file"
              onChange={(e) => {
                fprops.setFieldValue('resourceName', e.target.files[0].name);
                fprops.setFieldValue('resource', e.target.files[0]);
              }}
            />
            <Field
              label="Original image height"
              name="height"
              component={(
                <Input type="number" />
              )}
            />
          </Box>
        )}
      />
    </Modal>
  );
}

UploadTemplate.propTypes = {
  resourceType: PropTypes.string.isRequired,
  folderName: PropTypes.string.isRequired,
  onCreate: PropTypes.func,
};

UploadTemplate.defaultProps = {
  onCreate: null,
};

export default UploadTemplate;
