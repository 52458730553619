import * as msal from '@azure/msal-browser';
import { env } from '../env';

const config = {
  auth: {
    clientId: env.REACT_APP_AAD_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${env.REACT_APP_AAD_TENANT_ID}`,
    redirectUri: document.getElementById('root').baseURI,
  },
};

const msalInstance = new msal.PublicClientApplication(config);

const getAccessToken = () => new Promise((resolve, reject) => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.acquireTokenSilent({
      account: accounts[0],
      scopes: [`api://${env.REACT_APP_AAD_CLIENT_ID}/access_as_user`],
    }).then((tokenResponse) => resolve(tokenResponse.accessToken))
      .catch((error) => reject(error));
  } else {
    resolve(null);
  }
});

const logout = () => msalInstance.logout();

export {
  msalInstance,
  getAccessToken,
  logout,
};
