import React from 'react';

import Markdown from './Markdown';

const markdown = `
[Swagger API documentation](/swagger)

[SyncApi Swagger documentation](/api/v1/sync/swagger)

## Workflow
The following describes the workflow when a team onboards with Watch For.
### Deployment
* **PPE and PROD**: The team first gets a PPE tenant deployment for continuous testing and eventually a PROD tenant deployment to send production traffic. Tenant deployments are fully isolated (all resources are replicated) and have unique endpoints ([tenant].azurewebsites.net). The endpoint provides an **API** for integration and a **Dashboard** to configure skills, query results and view metrics.
* **Security group**: Before a tenant is created, the onboarding team will provide a security group (created through idweb). The dashboard access will be restricted to the members of the security group.
* **Retention policy**: The onboarding team will also provide guidance on data rentention. By default, Watch For sends results through an Event Hub as soon as data is processed. The results can also be stored for *n* days and queried through APIs and the dashboard. For videos and live streams, Watch For can also store certain sampled frames for *m* days and send links to them as part of results, which are typically used by human moderation as evidence. The values for *n* and *m* are provided by the onboarding team. By default, *n* and *m* are 28 days. These values can be 0 (no storage) and can be different for PPE and PROD.
* **Keys**: The team will get an API key for authenticating with the API endpoint. The team will also get read keys for Azure Event Hub to read the results asynchronously. Keys are shared through a shared Azure Key Vault.
* **IP restrictions**: In addition to using an API key, the API endpoint is restricted to an IP range provided by the team. The dashboard and storage (where frames are stored) are restricted to corpnet.

### Processing content
Once the deployment is set up, the team can use the APIs to process content (images, videos, live streams, and gifs). Processing is done asynchronously, and results are pushed to Event Hub, which can be read through the shared keys.
A separate Event Hub exists per content type (images / videos / live streams / gifs).
Watch For also provides APIs to read (poll for) results (if data retention policy allows for results to be stored). Full API Swagger documentation is [here](/swagger). Below, we describe the important ones.
Processing logic (how to analyze content, which models to run, how to run, how to sample frames from videos, how to aggregate frame results, etc.) is fully configurable through [skills](documentation/skills). The deployment comes with a built-in skill for each content type and the team can extend/modify them or create new ones. 

For all API calls, API key should be in the *x-api-key* header.

#### Processing images

~~~
POST /images
~~~
Start processing an image. Input is specified in the body. The main fields are:
* **id** (required): Unique id for the image. It can be maximum 1024 characters with [0-9a-zA-Z-_]+. The results in storage are overwritten when multiple images are passed with the same id. *We recommend not to pass any PII information as part of id.*
* **imageUri** (required): Url to the image.
* **skillId** (optional): Skill to run to process the image. If no skillId is specified, the default skill is invoked. Learn more about skills [here](/documentation/skills).
* **props** (optional): Properties exposed to the skill. Props has to be formatted as a JSON ({ ... }).
* **context** (optional): A string that is simply echoed back. Pass any value that needs to be read together with the results (e.g. to correlate). Context is attached to the results sent to Event Hub and also stored and returned as part of the GET results API. *We recommend not to pass any PII information here*.
* **privateContext** (optional): Similar to *context* except that it is not stored and not returned as part of the GET results API. It will only be echoed back with the results sent to Event Hub.

~~~
GET /images/{id}
~~~
Get results for a processed image. If retention policy doesn't allow results to be stored, this API only returns the processing status.
The following describes the main fields in the response:
* **id**: Id in the request.
* **status**: Contains one of four values (waiting / processing / success / error) that specifies whether the image is waiting to be processed, is currently being processed, has successfully completed or completed with an error. If the value is *waiting* or *processing*, poll again. If the value is *error*, the *error* field has a descriptive message. If the value is *success*, read the other fields.
* **result**: Contains the results. The *result* field has all the data returned by the skill (<code>processImage</code> method) being run. For example, the [system-managed skill](/images/skills/managed-image-skill) returns the following values:
  * result.adult: Whether the image is classified as adult.
  * result.racy: Whether the image is classified as racy.
  * result.gore: Whether the image is classified as gore.
  * result.classifiers: Raw results from individual models.
  * result.hash: Perceptual (d)hash of the image.
* **skillId**: The skill that was run to process the image.
* **context**: The context value passed in the POST request.
* **stats**: Stats such as image attributes, timestamps and processing durations.
* **error**: Descriptive error if status is returned as *error*. 

~~~
READ Image Event Hub
~~~
Results are pushed to Event Hub (both on success and on error) as soon as an image is finished processing. We recommend that results are read through Event Hub instead of polling the GET API.
One event is generated per image. The result format is same as described in the GET API above.

##### Example
The following shows a sample request:

~~~
POST /images

{
  "id": "test-image",
  "imageUri": "https://www.gstatic.com/webp/gallery/1.jpg"
}
~~~

The following is the result:
~~~
{
  "id": "test-image",
  "status": "success",
  "result": {
    "adult": false,
    "racy": false,
    "gore": false
  },
  ...
}
~~~

#### Processing videos
A video is sampled to extract frames and analysis is done on each sampled frame. Frame results are aggregated to produce a result for the entire video. Sampling rate, per-frame analysis and aggregate analysis are all configurable through skills.

~~~
POST /videos
~~~
Start processing a video. Input is specified in the body. The main fields are:
* **id** (required): Unique id for the video. It can be maximum 1024 characters with [0-9a-zA-Z-_]+. The results in storage are overwritten when multiple videos are passed with the same id. *We recommend not to pass any PII information as part of id.*
* **videoUri** (required): Url to the video. We currently support mp4 format.
* **skillId** (optional): Skill to run to process the video. If no skillId is specified, the default skill is invoked. Learn more about skills [here](/documentation/skills).
* **props** (optional): Properties exposed to the skill. Props has to be formatted as a JSON ({ ... }).
* **context** (optional): A string that is simply echoed back. Pass any value that needs to be read together with the results (e.g. to correlate). Context is attached to the results sent to Event Hub and also stored and returned as part of the GET results API. *We recommend not to pass any PII information here*.
* **privateContext** (optional): Similar to *context* except that it is not stored and not returned as part of the GET results API. It will only be echoed back with the results sent to Event Hub.

~~~
GET /videos/{id}
~~~
Get results for a processed video. If retention policy doesn't allow results to be stored, this API only returns the processing status.
The following describes the main fields in the response:
* **id**: Id in the request.
* **status**: Contains one of four values (waiting / processing / success / error) that specifies whether the video is waiting to be processed, is currently being processed, has successfully completed or completed with an error. If the value is *waiting* or *processing*, poll again. If the value is *error*, the *error* field has a descriptive message. If the value is *success*, read the other fields.
* **result**: Contains the results. The *result* field has all the data returned by the skill (<code>aggregateFrameResults</code> method) being run. For example, the [system-managed skill](/videos/skills/managed-video-skill) returns the following values after aggregating frame results:
  * result.adult: Whether the video is classified as adult.
  * result.racy: Whether the video is classified as racy.
  * result.gore: Whether the video is classified as gore.
* **skillId**: The skill that was run to process the video.
* **context**: The context value passed in the POST request.
* **stats**: Stats such as video attributes, timestamps and processing durations.
* **error**: Descriptive error if status is returned as *error*. 

~~~
GET /videos/{id}/frames
~~~
Get results for individual sampled frames. If retention policy doesn't allow results to be stored, this API returns empty list.
The response is an array of frame results. The following describes the main fields in the response:
* **timestamp**: Timestamp of the frame in the video.
* **result**: Contains the frame results. The *result* field has all the data returned by the skill (<code>processFrame</code> method) being run on each frame. For example, the [system-managed skill](/videos/skills/managed-video-skill) returns the following values for each frame:
  * result.adult: Whether the video frame is classified as adult.
  * result.racy: Whether the video frame is classified as racy.
  * result.gore: Whether the video frame is classified as gore.
  * result.classifiers: Raw results from individual models.
  * result.hash: Perceptual (d)hash of the video frame.
* **frameUri**: Url to the image, if retention policy and skill allows for storing video frames. Images are stored at 480p resolution.
* **stats**: Stats such as processing durations.

~~~
READ Video Event Hub
~~~
Results are pushed to Event Hub (both on success and on error) as soon as a full video is finished processing. We recommend that results are read through Event Hub instead of polling the GET API.
One event is generated per video. The result format is same as described in the GET /videos/{id} API. In addition, there is also a *frames* field that includes the individual frame results 
with the format described in GET /videos/{id}/frames API.

##### Example
The following shows a sample request:

~~~
POST /videos

{
  "id": "test-video",
  "videoUri": "https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4"
}
~~~

The following is the result:
~~~
{
  "id": "test-video",
  "status": "success",
  "result": {
    "adult": false,
    "racy": false,
    "gore": false
  },
  ...
}
~~~

#### Processing live video streams
A live video stream is sampled to extract frames and analysis is done on each sampled frame. Results are produced in real-time as each sampled frame is processed. Sampling rate, per-frame analysis and aggregate analysis (across a time window) are all configurable through skills.

~~~
POST /streams
~~~
Start processing a live video stream. The stream is processed until the stop API is called. Input is specified in the body. The main fields are:
* **id** (required): Unique id for the live stream. It can be maximum 1024 characters with [0-9a-zA-Z-_]+. The results in storage are overwritten when multiple videos are passed with the same id. *We recommend not to pass any PII information as part of id.*
* **streamUri** (required): Url to the live stream. We currently support HLS streams and url refers to the HLS manifest file (.m3u8).
* **skillId** (optional): Skill to run to process the stream. If no skillId is specified, the default skill is invoked. Learn more about skills [here](/documentation/skills).
* **props** (optional): Properties exposed to the skill. Props has to be formatted as a JSON ({ ... }).
* **context** (optional): A string that is simply echoed back. Pass any value that needs to be read together with the results (e.g. to correlate). Context is attached to the results sent to Event Hub and also stored and returned as part of the GET results API. *We recommend not to pass any PII information here*.
* **privateContext** (optional): Similar to *context* except that it is not stored and not returned as part of the GET results API. It will only be echoed back with the results sent to Event Hub.
* **decryptionKey** (optional): A decryption key for the encrypted live stream. It should be encoded in Base64.

~~~
GET /streams/{id}
~~~
Get real-time results for a live stream. Results are updated after each sampled frame is processed. The API can be periodically polled to get results in real-time.
If retention policy doesn't allow results to be stored, this API only returns the processing status.
The following describes the main fields in the response:
* **id**: Id in the request.
* **status**: Contains one of four values (waiting / processing / success / error) that specifies whether the live stream is waiting to be processed, is currently being processed, has successfully processed the most recent sampled frame or the processing of a frame resulted in an error. If the value is *waiting* or *processing*, poll again. If the value is *error*, the *error* field has a descriptive message. If the value is *success*, read the other fields.
* **result**: Contains the results. The *result* field has all the data returned by the skill (<code>processFrame</code> method) being run. For example, the [system-managed skill](/streams/skills/managed-stream-skill) returns the following values after each frame is processed:
  * result.adult: Whether the most recent sampled frame is classified as adult.
  * result.racy: Whether the most recent sampled frame is classified as racy.
  * result.gore: Whether the most recent sampled frame is classified as gore.
  * result.hash: Perceptual (d)hash of the frame.
  * result.aggregate.adult: Whether the live stream is classified as adult based on a recent time window of processed frames.
  * result.aggregate.racy: Whether the live stream is classified as racy based on a recent time window of processed frames.
  * result.aggregate.gore: Whether the live stream is classified as gore based on a recent time window of processed frames.
* **skillId**: The skill that was run to process the video.
* **context**: The context value passed in the POST request.
* **stats**: Stats such as live stream attributes, timestamps and processing durations.
* **error**: Descriptive error if status is returned as *error*. 
* **chunkUri** and **frameIndex**: Url to the HLS chunk and index of the sampled frame inside the chunk (for the most recent frame processed).
* **frameUri**: Url to the most recent processed frame image, if retention policy and skill allows for storing frames. Images are stored at 480p resolution.

~~~
PUT /streams/{id}/stop
~~~
Stop processing a live stream. Live streams are continuously processed (at a configured sampling rate) until stop is called irrespective of if processing throws an error.

~~~
GET /streams/{id}/frames
~~~
Get results for individual sampled frames. The results are limited to the last 100 frames. If retention policy doesn't allow results to be stored, this API returns empty list.
The response is an array of frame results. The following describes the main fields in the response:
* **timestamp**: Timestamp of the frame in the live stream.
* **result**: Contains the frame results. The *result* field has all the data returned by the skill (<code>processFrame</code> method) being run on each frame. For example, the [system-managed skill](/streams/skills/managed-stream-skill) returns the following values for each frame:
  * result.adult: Whether the most recent sampled frame is classified as adult.
  * result.racy: Whether the most recent sampled frame is classified as racy.
  * result.gore: Whether the most recent sampled frame is classified as gore.
  * result.classifiers: Raw results from individual models.
  * result.hash: Perceptual (d)hash of the frame.
* **chunkUri** and **frameIndex**: Url to the HLS chunk and index of the sampled frame inside the chunk.
* **frameUri**: Url to the image, if retention policy and skill allows for storing frames. Images are stored at 480p resolution.
* **stats**: Stats such as processing durations.

~~~
GET /streams/active
~~~
Get all active streams being processed. This API can be periodically used to identify the streams that need to be started/stopped.
The response is an array of streams. The main field in the object is the stream *id*.

~~~
READ Stream Event Hub
~~~
Results are pushed to Event Hub (both on success and on error) as soon as every frame is processed.
One event is generated per sampled frame. Event Hub is partitioned by stream *id* and events for a specific stream are guaranteed to be in order.
The result format is same as described in the GET /streams/{id} API.

##### Example
The following shows a sample request:

~~~
POST /streams

{
  "id": "test-stream",
  "streamUri": "https://nmxlive.akamaized.net/hls/live/529965/Live_1/index.m3u8"
}
~~~

The following is the result. Note that results are continuously updated for live streams.
~~~
{
  "id": "test-stream",
  "status": "success",
  "result": {
    "adult": false,
    "racy": false,
    "gore": false
  }
  ...
}
~~~

#### Processing gifs
A gif is sampled to extract frames and analysis is done on each sampled frame. Frame results are aggregated to produce a result for the entire gif. Sampling rate, per-frame analysis and aggregate analysis are all configurable through skills.

~~~
POST /gifs
~~~
Start processing a gif. Input is specified in the body. The main fields are:
* **id** (required): Unique id for the gid. It can be maximum 1024 characters with [0-9a-zA-Z-_]+. The results in storage are overwritten when multiple gifs are passed with the same id. *We recommend not to pass any PII information as part of id.*
* **gifUri** (required): Url to the gif. We currently support only gif format.
* **skillId** (optional): Skill to run to process the gif. If no skillId is specified, the default skill is invoked. Learn more about skills [here](/documentation/skills).
* **props** (optional): Properties exposed to the skill. Props has to be formatted as a JSON ({ ... }).
* **context** (optional): A string that is simply echoed back. Pass any value that needs to be read together with the results (e.g. to correlate). Context is attached to the results sent to Event Hub and also stored and returned as part of the GET results API. *We recommend not to pass any PII information here*.
* **privateContext** (optional): Similar to *context* except that it is not stored and not returned as part of the GET results API. It will only be echoed back with the results sent to Event Hub.

~~~
GET /gifs/{id}
~~~
Get results for a processed gif. If retention policy doesn't allow results to be stored, this API only returns the processing status.
The following describes the main fields in the response:
* **id**: Id in the request.
* **status**: Contains one of four values (waiting / processing / success / error) that specifies whether the gif is waiting to be processed, is currently being processed, has successfully completed or completed with an error. If the value is *waiting* or *processing*, poll again. If the value is *error*, the *error* field has a descriptive message. If the value is *success*, read the other fields.
* **result**: Contains the results. The *result* field has all the data returned by the skill (<code>aggregateFrameResults</code> method) being run. For example, the [system-managed skill](/gifs/skills/managed-gif-skill) returns the following values after aggregating frame results:
  * result.adult: Whether the gif is classified as adult.
  * result.racy: Whether the gif is classified as racy.
  * result.gore: Whether the gif is classified as gore.
* **skillId**: The skill that was run to process the gif.
* **context**: The context value passed in the POST request.
* **stats**: Stats such as gif attributes, timestamps and processing durations.
* **error**: Descriptive error if status is returned as *error*. 

~~~
GET /gifs/{id}/frames
~~~
Get results for individual sampled frames. If retention policy doesn't allow results to be stored, this API returns empty list.
The response is an array of frame results. The following describes the main fields in the response:
* **timestamp**: Timestamp of the frame in the gif.
* **result**: Contains the frame results. The *result* field has all the data returned by the skill (<code>processFrame</code> method) being run on each frame. For example, the [system-managed skill](/gifs/skills/managed-gif-skill) returns the following values for each frame:
  * result.adult: Whether the gif frame is classified as adult.
  * result.racy: Whether the gif frame is classified as racy.
  * result.gore: Whether the gif frame is classified as gore.
  * result.classifiers: Raw results from individual models.
  * result.hash: Perceptual (d)hash of the gif frame.
* **frameUri**: Url to the image, if retention policy and skill allows for storing gif frames. Images are stored at 480p resolution.
* **stats**: Stats such as processing durations.

~~~
READ Gif Event Hub
~~~
Results are pushed to Event Hub (both on success and on error) as soon as a gif is finished processing. We recommend that results are read through Event Hub instead of polling the GET API.
One event is generated per gif. The result format is same as described in the GET /gifs/{id} API. In addition, there is also a *frames* field that includes the individual frame results 
with the format described in GET /gifs/{id}/frames API.

##### Example
The following shows a sample request:

~~~
POST /gifs

{
  "id": "test-gif",
  "gifUri": "https://media.giphy.com/media/1BGQQpzJ0N8K99RgzB/giphy.gif"
}
~~~

The following is the result:
~~~
{
  "id": "test-gif",
  "status": "success",
  "result": {
    "adult": false,
    "racy": false,
    "gore": false
  },
  ...
}
~~~

### Using the results
The results from Watch For can be used in different ways. 
* Filter content (e.g. from search results, trending content).
* Invoke human moderation (e.g. through a framework like Salus with manual moderators).
* Index content for search and discovery.

Note that Watch For does not provide manual moderation tools. Watch For's boards and query interfaces are only for testing and tuning the processing pipeline.

### Customizing the deployment
Watch For is highly programmable. The processing pipelines for images, videos, live streams, and gifs can be fully configured through skills.
Refer to the [skills section](/documentation/skills) for more details.

<br/><br/>
`;

export default function Doc() {
  return <Markdown markdown={markdown} />;
}
