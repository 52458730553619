import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Box, Field, Form, Input, Modal, Text, useHistory,
} from 'controls';

import api from 'api';
import Utils from 'components/Utils';

function CreateSkill(props) {
  const { type, cloneSkillName, skillVersion } = props;
  const history = useHistory();
  const singularType = Utils.toSingular(type);
  return (
    <Modal
      title={`${cloneSkillName ? 'Clone' : 'Create'} ${singularType.toLowerCase()} skill`}
      width={['100%', '100%', '500px']}
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      <Form
        initialValues={{
          id: '',
        }}
        validationSchema={Yup.object().shape({
          id: Yup.string().required('Required'),
        })}
        onSubmit={async (values) => {
          const response = await api.createSkill(type, values.id, cloneSkillName, skillVersion);
          if (!response.error) {
            if (response.data.skillName !== null && response.data.version !== null) {
              const url = `/${type}/skills/${response.data.skillName}/versions/${response.data.version}`;
              history.push(url);
            } else {
              history.push(`/${type}/skills/${response.data.id}`);
            }
            return null;
          }

          if (response.error.status === 409) {
            return response.error.data;
          }

          return null;
        }}
        action={cloneSkillName ? 'Clone' : 'Create'}
        fields={() => (
          <Box>
            <Field
              label="New skill id"
              name="id"
              component={(
                <Input />
              )}
            />
            {!cloneSkillName && (
              <Box mt={2} mb={2}>
                <Text
                  fontSize={12}
                  color="primary-dark"
                  text="New skill is cloned from the system managed skill"
                />
              </Box>
            )}
          </Box>
        )}
      />
    </Modal>
  );
}

CreateSkill.propTypes = {
  type: PropTypes.string.isRequired,
  cloneSkillName: PropTypes.string,
  skillVersion: PropTypes.number,
};

CreateSkill.defaultProps = {
  cloneSkillName: null,
  skillVersion: null,
};

export default CreateSkill;
