import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Form, Modal, Text, useHistory,
} from 'controls';

import api from 'api';

function DeleteDataset(props) {
  const {
    dataset, onDelete,
  } = props;
  const history = useHistory();

  return (
    <Modal
      title="Delete dataset"
      width={['100%', '100%', '500px']}
      overflow="visible"
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      <Form
        onSubmit={async () => {
          const response = await api.deleteDataset(dataset.id);
          if (!response.error) {
            if (onDelete) {
              onDelete();
            }

            history.push('/datasets');
            return null;
          }

          if (response.error.status === 404) {
            return 'Dataset not found';
          }

          return null;
        }}
        action="Delete"
        actionVariant="error"
        fields={() => (
          <Box>
            <Text
              mb={2}
              color="muted"
              text={`Are you sure you want to delete dataset ${dataset.name}?`}
            />
          </Box>
        )}
      />
    </Modal>
  );
}

DeleteDataset.propTypes = {
  dataset: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func,
};

DeleteDataset.defaultProps = {
  onDelete: null,
};

export default DeleteDataset;
