import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Divider, Dropdown, Flex, IconButton, MenuItem,
  Page, Section, Text, Time, useApi, useModalState,
} from 'controls';

import api from 'api';
import CreateOrEditLayout from './CreateOrEditLayout';
import DeleteLayout from './DeleteLayout';

const LayoutItem = (props) => {
  const {
    layout,
    onUpdate,
    onDelete,
  } = props;

  return (
    <Box
      pt={3}
    >
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box>
          <Button
            format="inline"
            fontSize={16}
            text={layout.id}
            onClick={() => onUpdate()}
          />
          <Text
            fontSize={12}
            color="muted"
            text={`Updated: ${Time.format(layout.updatedAt, 'MMM DD, YYYY LTS')}`}
          />
        </Box>
        <Box>
          <Dropdown
            alignRight
            closeOnClick={false}
            toggle={(
              <IconButton p={1} color="muted" icon="ellipsis-v" />
            )}
            menuStyle={{
              marginTop: '5px',
            }}
            menuContent={(
              <>
                <MenuItem
                  divider
                  fontSize="small"
                  py={2}
                  text="Update layout"
                  onClick={(e) => {
                    e.stopPropagation();
                    onUpdate();
                  }}
                />
                <MenuItem
                  fontSize="small"
                  py={2}
                  text="Delete layout"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete();
                  }}
                />
              </>
            )}
          />
        </Box>
      </Flex>
      <Divider mt={3} />
    </Box>
  );
};

LayoutItem.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  layout: PropTypes.shape({
    id: PropTypes.string,
    updatedAt: PropTypes.number,
  }).isRequired,
};

function Layouts() {
  const {
    data, isLoading, setDependency,
  } = useApi(async () => api.getLayouts(), 0);

  const modalState = useModalState();
  const {
    createLayout, updateLayout,
    deleteLayout, layout,
  } = modalState.get();

  return (
    <Page mt={2}>
      <Section pt={6} loading={isLoading}>
        {data && (
          <Flex
            justifyContent="space-between"
            alignItems="center"
            mb={3}
          >
            <Text
              fontWeight="bold"
              text="Layouts"
            />

            <Button
              format="inline"
              text="+ Create layout"
              onClick={() => modalState.push({
                createLayout: true,
              })}
            />
          </Flex>
        )}
        <Divider />
        {data && data.map((l) => (
          <LayoutItem
            layout={l}
            key={l.id}
            onUpdate={() => modalState.push({
              updateLayout: true,
              layout: l,
            })}
            onDelete={() => modalState.push({
              deleteLayout: true,
              layout: l,
            })}
          />
        ))}
        {data && data.length === 0 && (
          <Box mt={6} textAlign="center">
            <Text
              color="muted"
              text="No layouts found"
            />
          </Box>
        )}
      </Section>
      {createLayout && (
        <CreateOrEditLayout
          create
          onCreate={() => setDependency(Time.now())}
        />
      )}
      {(updateLayout) && (
        <CreateOrEditLayout
          create={false}
          layout={layout}
          onUpdate={() => setDependency(Time.now())}
        />
      )}
      {deleteLayout && (
        <DeleteLayout
          layout={layout}
          onDelete={() => setDependency(Time.now())}
        />
      )}
    </Page>
  );
}

export default Layouts;
