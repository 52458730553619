import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Box, CodeInput, Field, Form, Input, Modal, useHistory,
} from 'controls';

import api from 'api';

function CreateOrEditDatasetContent(props) {
  const {
    create,
    dataset,
    datasetContent,
    onCreate, onUpdate,
  } = props;
  const history = useHistory();

  return (
    <Modal
      title={`${create ? 'Create' : 'Edit'} dataset content`}
      width={['100%', '100%', '700px']}
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      <Form
        initialValues={{
          id: datasetContent.id,
          contentUri: datasetContent.contentUri,
          contentText: datasetContent.contentText,
          attributes: JSON.stringify(datasetContent.attributes, null, 2),
        }}
        validationSchema={Yup.object().shape({
          id: Yup.string().required('Required'),
        })}
        onSubmit={async (values) => {
          try {
            JSON.parse(values.attributes);
          } catch (e) {
            return 'Attributes JSON parsing error';
          }

          if (create) {
            const response = await api.createDatasetContent(
              dataset.id,
              values.id,
              values.contentUri,
              values.contentText,
              JSON.parse(values.attributes),
            );

            if (!response.error) {
              if (onCreate) {
                onCreate();
              }

              history.goBack();
              return null;
            }
          }

          if (!create) {
            const response = await api.updateDatasetContent(
              dataset.id,
              values.id,
              JSON.parse(values.attributes),
            );

            if (!response.error) {
              if (onUpdate) {
                onUpdate(response.data);
              }

              history.goBack();
              return null;
            }

            if (response.error.status === 404) {
              return 'Dataset not found';
            }
          }

          return null;
        }}
        action={create ? 'Create' : 'Update'}
        fields={() => (
          <Box>
            <Field
              label="Content id"
              name="id"
              component={(
                <Input
                  disabled={!create}
                />
              )}
            />
            {dataset.contentType !== 'text' && (
              <Field
                label="Content uri"
                name="contentUri"
                component={(
                  <Input
                    disabled={!create}
                  />
                )}
              />
            )}
            {dataset.contentType === 'text' && (
              <Field
                label="Content text"
                name="contentText"
                component={(
                  <Input
                    disabled={!create}
                  />
                )}
              />
            )}
            <Field
              mt={2}
              label="Content attributes"
              name="attributes"
              component={<CodeInput mt={1} fontSize={14} minLines={6} />}
            />
          </Box>
        )}
      />
    </Modal>
  );
}

CreateOrEditDatasetContent.propTypes = {
  create: PropTypes.bool,
  onUpdate: PropTypes.func,
  onCreate: PropTypes.func,
  dataset: PropTypes.shape({
    id: PropTypes.string,
    contentType: PropTypes.string,
  }).isRequired,
  datasetContent: PropTypes.shape({
    id: PropTypes.string,
    contentUri: PropTypes.string,
    contentText: PropTypes.string,
    attributes: PropTypes.shape({}),
  }),
};

CreateOrEditDatasetContent.defaultProps = {
  create: true,
  onUpdate: null,
  onCreate: null,
  datasetContent: {
    id: '',
    contentUri: '',
    contentText: '',
    attributes: {},
  },
};

export default CreateOrEditDatasetContent;
