import React from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import {
  Flex, Modal, useModalState,
} from 'controls';

import ContentItem from './ContentItem';
import WatchVideo from './WatchVideo';
import CustomLayout from './CustomLayout';

function ContentList(props) {
  const {
    contents, onClick, onPinned,
    onReviewChange, onReviewed, type,
    contentLayout, moderate,
  } = props;

  const modalState = useModalState();
  const { watch, watchVideo } = modalState.get();
  const { showDetails, details } = modalState.get();

  return (
    <>
      {!contentLayout && (
        <Flex flexWrap="wrap">
          {contents.map((content, index) => (
            <ContentItem
              index={index}
              width={['100%', '100%', '50%']}
              key={content.id}
              content={content}
              onClick={onClick}
              onDetails={(d) => {
                modalState.push({
                  showDetails: true,
                  details: d,
                });
              }}
              onWatchVideo={(v) => {
                modalState.push({
                  watch: true,
                  watchVideo: v,
                });
              }}
              onPinned={onPinned}
              onReviewChange={onReviewChange}
              onReviewed={onReviewed}
              type={type}
              moderate={moderate}
            />
          ))}
        </Flex>
      )}
      {contentLayout && (
        <CustomLayout
          data={contents}
          layout={contentLayout}
          type={type}
          onDetails={(d) => {
            modalState.push({
              showDetails: true,
              details: d,
            });
          }}
        />
      )}
      {watch && (
        <Modal
          title={watchVideo.id}
          width={['100%', '100%', '700px']}
          overlayStyle={{
            overflowX: 'auto',
            overflowY: 'scroll',
            position: 'fixed',
            zIndex: 11,
          }}
        >
          <WatchVideo video={watchVideo} />
        </Modal>
      )}
      {showDetails && (
        <Modal
          width={['100%', '100%', '700px']}
          title="Details"
          overlayStyle={{
            overflowX: 'auto',
            overflowY: 'scroll',
            position: 'fixed',
            zIndex: 11,
          }}
        >
          <ReactJson
            src={details}
            displayDataTypes={false}
          />
        </Modal>
      )}
    </>
  );
}

ContentList.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  onClick: PropTypes.func,
  onPinned: PropTypes.func,
  onReviewChange: PropTypes.func,
  onReviewed: PropTypes.func,
  type: PropTypes.string,
  contentLayout: PropTypes.shape({}),
  moderate: PropTypes.bool,
};

ContentList.defaultProps = {
  contents: null,
  onClick: null,
  onPinned: null,
  onReviewChange: null,
  onReviewed: null,
  type: null,
  contentLayout: null,
  moderate: false,
};

export default ContentList;
