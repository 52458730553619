import React from 'react';
import {
  Page, useParams,
} from 'controls';

import SkillSection from './SkillSection';

function Skill() {
  const params = useParams();
  const {
    type, skillName, skillVersion,
  } = params;
  return (
    <Page>
      <SkillSection type={type} skillName={skillName} paramSkillVersion={skillVersion} />
    </Page>
  );
}

export default Skill;
