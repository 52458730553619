import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Page, Section, Text, Flex,
  useApi, useParams, useQuery,
  useModalState, Card, Button, Divider, Box, Time,
  Link, Table, Tooltip, EllipsisText, Tabs, TabItem,
} from 'controls';

import api from 'api';
import Utils from 'components/Utils';
import EditAutopackSettings from './EditAutopackSettings';

const TableHeader = (props) => {
  const { text, tooltip } = props;
  return (
    <Text textAlign="left" p={2}>
      {text}
      {tooltip && (
        <Tooltip ml={1} tooltip={tooltip} />
      )}
    </Text>
  );
};

TableHeader.propTypes = {
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

TableHeader.defaultProps = {
  tooltip: null,
};

const Setting = (props) => {
  const {
    title, value,
  } = props;
  return (
    <Box mt={2}>
      <Text
        text={title}
        color="muted"
        fontSize={13}
      />
      <Text
        text={value}
      />
    </Box>
  );
};

Setting.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
};

function Autopack() {
  const params = useParams();
  const { type } = params;
  const queryParams = useQuery();
  const frames = queryParams.get('frames') === 'true';
  const rawData = queryParams.get('rawData') === 'true';
  const [filter] = useState(queryParams.get('filter'));
  const [orderBy] = useState(queryParams.get('orderBy') || 'c.startedAt DESC');
  const [limit] = useState(queryParams.get('limit') || 1000);
  const regionId = queryParams.get('regionId') || '';

  const fetchColumnName = 'Fetch';
  const processColumnName = 'Process';
  const latencyColumnName = 'Latency';
  const avgColumns = [
    'averageFetchTime',
    'averageProcessTime',
    'averageLatency',
  ];
  const fiftyPercentileColumns = [
    'fiftyPercentileFetchTime',
    'fiftyPercentileProcessTime',
    'fiftyPercentileLatency',
  ];
  const ninetyPercentileColumns = [
    'ninetyPercentileFetchTime',
    'ninetyPercentileProcessTime',
    'ninetyPercentileLatency',
  ];
  const ninetyFivePercentileColumns = [
    'ninetyFivePercentileFetchTime',
    'ninetyFivePercentileProcessTime',
    'ninetyFivePercentileLatency',
  ];
  const tabToShow = queryParams.get('tabToShow');
  let hiddenColumns = fiftyPercentileColumns
    .concat(ninetyPercentileColumns.concat(ninetyFivePercentileColumns));
  if (tabToShow === 'fiftyPercentile') {
    hiddenColumns = avgColumns
      .concat(ninetyPercentileColumns.concat(ninetyFivePercentileColumns));
  } else if (tabToShow === 'ninetyPercentile') {
    hiddenColumns = avgColumns
      .concat(fiftyPercentileColumns.concat(ninetyFivePercentileColumns));
  } else if (tabToShow === 'ninetyFivePercentile') {
    hiddenColumns = avgColumns
      .concat(fiftyPercentileColumns.concat(ninetyPercentileColumns));
  }

  const {
    data: autopackResults, setDependency: setAutopackResultsDependency,
  } = useApi(async () => api.getAutopackResults(frames, rawData, type, filter, orderBy, limit), 0);

  const {
    data: autopackSettingsData,
    setData: setAutopackSettingsData,
    setDependency: setAutopackSettingsDependency,
  } = useApi(async () => api.getAutopackSettings(), 0);

  const modalState = useModalState();
  const {
    editAutopackSettings, autopackSettings,
  } = modalState.get();

  /* eslint-disable react/prop-types */
  const columns = [{
    Header: <TableHeader text="Id" />,
    accessor: 'id',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xxx-small">
        {cell.value && (
          <Link
            to={`/${type}/metrics?filter=c.experimentId="${cell.value}"&orderBy=c.createdAt%20DESC&frames=false&limit=1000`}
          >
            <EllipsisText
              text={cell.value}
            />
          </Link>
        )}
      </Box>
    ),
    width: 25,
  },
  {
    Header: <TableHeader text="Per core" />,
    accessor: 'nodeSettings.processPerCore',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={cell.value === true ? 1 : 0}
        />
      </Box>
    ),
    width: 35,
  },
  {
    Header: <TableHeader text="Processes" />,
    accessor: 'nodeSettings.processes',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={cell.value}
        />
      </Box>
    ),
    width: 60,
  },
  {
    Header: <TableHeader text="Pin" />,
    accessor: 'nodeSettings.pin',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xxx-small">
        <Text
          text={cell.value === true ? 1 : 0}
        />
      </Box>
    ),
    width: 25,
  },
  {
    Header: <TableHeader text="Threads" />,
    accessor: 'nodeSettings.threads',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={cell.value}
        />
      </Box>
    ),
    width: 50,
  },
  {
    Header: <TableHeader text={fetchColumnName} />,
    accessor: 'averageFetchTime',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={cell.value.toFixed(2)}
        />
      </Box>
    ),
    width: 40,
  },
  {
    Header: <TableHeader text={fetchColumnName} />,
    accessor: 'fiftyPercentileFetchTime',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={cell.value.toFixed(2)}
        />
      </Box>
    ),
    width: 40,
  },
  {
    Header: <TableHeader text={fetchColumnName} />,
    accessor: 'ninetyPercentileFetchTime',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={cell.value.toFixed(2)}
        />
      </Box>
    ),
    width: 40,
  },
  {
    Header: <TableHeader text={fetchColumnName} />,
    accessor: 'ninetyFivePercentileFetchTime',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={cell.value.toFixed(2)}
        />
      </Box>
    ),
    width: 40,
  },
  {
    Header: <TableHeader text={processColumnName} />,
    accessor: 'averageProcessTime',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={cell.value.toFixed(2)}
        />
      </Box>
    ),
    width: 50,
  },
  {
    Header: <TableHeader text={processColumnName} />,
    accessor: 'fiftyPercentileProcessTime',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={cell.value.toFixed(2)}
        />
      </Box>
    ),
    width: 50,
  },
  {
    Header: <TableHeader text={processColumnName} />,
    accessor: 'ninetyPercentileProcessTime',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={cell.value.toFixed(2)}
        />
      </Box>
    ),
    width: 50,
  },
  {
    Header: <TableHeader text={processColumnName} />,
    accessor: 'ninetyFivePercentileProcessTime',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={cell.value.toFixed(2)}
        />
      </Box>
    ),
    width: 50,
  },
  {
    Header: <TableHeader text={latencyColumnName} />,
    accessor: 'averageLatency',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={cell.value.toFixed(2)}
        />
      </Box>
    ),
    width: 50,
  },
  {
    Header: <TableHeader text={latencyColumnName} />,
    accessor: 'fiftyPercentileLatency',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={cell.value.toFixed(2)}
        />
      </Box>
    ),
    width: 50,
  },
  {
    Header: <TableHeader text={latencyColumnName} />,
    accessor: 'ninetyPercentileLatency',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={cell.value.toFixed(2)}
        />
      </Box>
    ),
    width: 50,
  },
  {
    Header: <TableHeader text={latencyColumnName} />,
    accessor: 'ninetyFivePercentileLatency',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={cell.value.toFixed(2)}
        />
      </Box>
    ),
    width: 50,
  },
  {
    Header: <TableHeader text="Error %" />,
    accessor: 'errorRatePercentage',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={cell.value != null ? cell.value.toFixed(2) : 0}
        />
      </Box>
    ),
    width: 35,
  },
  {
    Header: <TableHeader text="Busy %" />,
    accessor: 'busyPercentage',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={cell.value.toFixed(2)}
        />
      </Box>
    ),
    width: 35,
  },
  {
    Header: <TableHeader text="Throughput" />,
    accessor: 'avgThroughput',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={cell.value.toFixed(2)}
        />
      </Box>
    ),
    width: 70,
  }];
  /* eslint-enable react/prop-types */

  return (
    <Page mt={2}>
      <Section
        mt={6}
        loading={false}
      >
        {autopackSettingsData && (
          <>
            <Card
              title={(
                <Text
                  text="Autopack Settings"
                  fontSize={16}
                  fontWeight="bold"
                />
              )}
              action={(
                <Button
                  format="inline"
                  text="Edit"
                  onClick={() => modalState.push({
                    editAutopackSettings: true,
                    autopackSettings: autopackSettingsData,
                  })}
                />
              )}
            >
              <Divider mt={2} />
              <Setting
                title="Experiment status"
                value={autopackSettingsData.experimentStatus}
              />
              <Setting
                title="Minutes to run experiment"
                value={`${autopackSettingsData.minutesToRunExperiment} minutes`}
              />
              <Setting
                title="Refresh delay"
                value={`${autopackSettingsData.refreshDelay} seconds`}
              />
              <Setting
                title="Min processes"
                value={`${autopackSettingsData.minProcesses} processes`}
              />
              <Setting
                title="Max processes"
                value={`${autopackSettingsData.maxProcesses} processes`}
              />
              <Setting
                title="Min threads"
                value={`${autopackSettingsData.minThreads} threads`}
              />
              <Setting
                title="Max threads"
                value={`${autopackSettingsData.maxThreads} threads`}
              />
              <Setting
                title="Max concurrent experiments per region"
                value={`${autopackSettingsData.maxConcurrentExperiments} experiments`}
              />
              <Setting
                title="Pin Settings"
                value={`${autopackSettingsData.pinSettings}`}
              />
              <Setting
                title="Content Type"
                value={`${autopackSettingsData.contentType}`}
              />
              <Setting
                title="Container Image"
                value={`${autopackSettingsData.containerImage}`}
              />
            </Card>
            <Flex mt={4} justifyContent="space-between" alignItems="center">
              <Button
                text={autopackSettingsData && autopackSettingsData.experimentStatus === 'inProgress' ? 'Cancel Experiment' : 'Start Experiment'}
                disabled={autopackSettingsData && autopackSettingsData.experimentStatus === 'cancelled'}
                onClick={async () => {
                  if (autopackSettingsData.experimentStatus === 'inProgress') {
                    setAutopackSettingsData(await api.cancelAutopackExperiment());
                  } else {
                    setAutopackSettingsData(await api.triggerAutopackExperiment());
                  }
                  setAutopackSettingsDependency(Time.now());
                  setAutopackResultsDependency(Time.now());
                  return null;
                }}
              />
              <Button
                format="inline"
                text="Refresh"
                onClick={() => {
                  setAutopackSettingsDependency(Time.now());
                  setAutopackResultsDependency(Time.now());
                }}
              />
            </Flex>
          </>
        )}
      </Section>
      <Tabs mt={2}>
        <TabItem
          text="All regions"
          active={!regionId}
          to={`/${type}/autopack?frames=${frames}&rawData=${rawData}`}
        />
        {autopackResults && autopackResults.regions.map((region) => (
          <TabItem
            key={region}
            text={region}
            active={regionId === region}
            to={`/${type}/autopack?frames=${frames}&regionId=${region}&rawData=true`}
          />
        ))}
      </Tabs>
      <Tabs mt={2}>
        <TabItem
          text="Raw Data"
          active={rawData}
          to={`/${type}/autopack?frames=${frames}&regionId=${regionId}&rawData=true`}
        />
        <TabItem
          text="Data as Ratio of Control"
          active={!rawData}
          to={`/${type}/autopack?frames=${frames}&regionId=${regionId}&rawData=false`}
        />
      </Tabs>
      <Tabs mt={2}>
        <TabItem
          text={`${Utils.toSingular(type)} stats`}
          active={!frames}
          to={`/${type}/autopack?frames=false&regionId=${regionId}&rawData=${rawData}`}
        />
        <TabItem
          text="Frame stats"
          active={frames}
          to={`/${type}/autopack?frames=true&regionId=${regionId}&rawData=${rawData}`}
        />
      </Tabs>
      <Tabs mt={2}>
        <TabItem
          text="Avg metrics"
          active={tabToShow === null}
          to={`/${type}/autopack?frames=${frames}&rawData=${rawData}&regionId=${regionId}`}
        />
        <TabItem
          text="50% metrics"
          active={tabToShow === 'fiftyPercentile'}
          to={`/${type}/autopack?frames=${frames}&rawData=${rawData}&regionId=${regionId}&tabToShow=fiftyPercentile`}
        />
        <TabItem
          text="90% metrics"
          active={tabToShow === 'ninetyPercentile'}
          to={`/${type}/autopack?frames=${frames}&rawData=${rawData}&regionId=${regionId}&tabToShow=ninetyPercentile`}
        />
        <TabItem
          text="95% metrics"
          active={tabToShow === 'ninetyFivePercentile'}
          to={`/${type}/autopack?frames=${frames}&rawData=${rawData}&regionId=${regionId}&tabToShow=ninetyFivePercentile`}
        />
      </Tabs>
      <Section mt={6} loading={false}>
        {autopackResults && (
        <>
          <Table
            data={regionId !== '' ? autopackResults.experimentMetrics.filter((metric) => metric.executingRegion === regionId) : autopackResults.experimentMetrics}
            columns={columns}
            hiddenColumns={hiddenColumns}
          />
        </>
        )}
      </Section>
      {editAutopackSettings && (
        <EditAutopackSettings
          settings={autopackSettings}
          onUpdate={() => setAutopackSettingsDependency(Time.now())}
        />
      )}
    </Page>
  );
}

export default Autopack;
