import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Box, Field, Form, Input, Modal, useHistory,
} from 'controls';

import api from 'api';

function EditAutoscaleSettings(props) {
  const {
    settings, onUpdate,
  } = props;
  const history = useHistory();

  return (
    <Modal
      overflow="visible"
      title="Edit Autoscale Settings"
      width={['100%', '100%', '480px']}
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      <Form
        initialValues={{
          refreshDelay: settings.refreshDelay,
          minReplicas: settings.minReplicas,
          maxReplicas: settings.maxReplicas,
          scaleUpSmoothingParameter: settings.scaleUpSmoothingParameter,
          scaleDownSmoothingParameter: settings.scaleDownSmoothingParameter,
          scaleUpUtilizationPercentage: settings.scaleUpUtilizationPercentage,
          scaleDownUtilizationPercentage: settings.scaleDownUtilizationPercentage,
          scaleUpCooldownTimeInSeconds: settings.scaleUpCooldownTimeInSeconds,
        }}
        validationSchema={Yup.object().shape({
          refreshDelay: Yup.number().required('Required'),
          minReplicas: Yup.number().required('Required'),
          maxReplicas: Yup.number().required('Required'),
          scaleUpSmoothingParameter: Yup.number().required('Required'),
          scaleDownSmoothingParameter: Yup.number().required('Required'),
          scaleUpUtilizationPercentage: Yup.number().required('Required'),
          scaleDownUtilizationPercentage: Yup.number().required('Required'),
          scaleUpCooldownTimeInSeconds: Yup.number().required('Required'),
        })}
        onSubmit={async (values) => {
          const response = await api.updateAutoscaleSettings(
            values.refreshDelay,
            values.minReplicas,
            values.maxReplicas,
            values.scaleUpSmoothingParameter,
            values.scaleDownSmoothingParameter,
            values.scaleUpUtilizationPercentage,
            values.scaleDownUtilizationPercentage,
            values.scaleUpCooldownTimeInSeconds,
          );
          if (!response.error) {
            if (onUpdate) {
              onUpdate();
            }

            history.goBack();
            return null;
          }

          return null;
        }}
        action="Update"
        fields={() => (
          <Box>
            <Field
              label="Refresh delay"
              name="refreshDelay"
              component={(
                <Input
                  type="number"
                />
              )}
            />
            <Field
              label="Min replicas"
              name="minReplicas"
              component={(
                <Input
                  type="number"
                />
              )}
            />
            <Field
              label="Max replicas"
              name="maxReplicas"
              component={(
                <Input
                  type="number"
                />
              )}
            />
            <Field
              label="Scale Up Smoothing Parameter"
              name="scaleUpSmoothingParameter"
              component={(
                <Input
                  type="number"
                />
              )}
            />
            <Field
              label="Scale Down Smoothing Parameter"
              name="scaleDownSmoothingParameter"
              component={(
                <Input
                  type="number"
                />
              )}
            />
            <Field
              label="Scale Up Utilization Percentage"
              name="scaleUpUtilizationPercentage"
              component={(
                <Input
                  type="number"
                />
              )}
            />
            <Field
              label="Scale Down Utilization Percentage"
              name="scaleDownUtilizationPercentage"
              component={(
                <Input
                  type="number"
                />
              )}
            />
            <Field
              label="Scale Up Cooldown Time In Seconds"
              name="scaleUpCooldownTimeInSeconds"
              component={(
                <Input
                  type="number"
                />
              )}
            />
          </Box>

        )}
      />
    </Modal>
  );
}

EditAutoscaleSettings.propTypes = {
  onUpdate: PropTypes.func,
  settings: PropTypes.shape({
    refreshDelay: PropTypes.number,
    minReplicas: PropTypes.number,
    maxReplicas: PropTypes.number,
    scaleUpSmoothingParameter: PropTypes.number,
    scaleDownSmoothingParameter: PropTypes.number,
    scaleUpUtilizationPercentage: PropTypes.number,
    scaleDownUtilizationPercentage: PropTypes.number,
    scaleUpCooldownTimeInSeconds: PropTypes.number,
  }).isRequired,
};

EditAutoscaleSettings.defaultProps = {
  onUpdate: null,
};

export default EditAutoscaleSettings;
