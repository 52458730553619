import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Flex, Input, Section, Select, stylex, Tabs, TabItem, useApi,
} from 'controls';

import api from 'api';
import ComponentList from 'components/ComponentList';

function ComponentSection(props) {
  const [contentType, setContentType] = useState('');
  const [detectionType, setDetectionType] = useState('');
  const [filter, setFilter] = useState('');
  const { add, onAdd, onClick } = props;

  const {
    data, isLoading, setDependency,
  } = useApi(async (dep) => api.getComponents(dep.contentType, dep.detectionType, dep.filter), {
    contentType: '',
    detectionType: '',
    filter: '',
  });

  return (
    <Section>
      <Tabs>
        <TabItem
          text="All"
          active={contentType === ''}
          onClick={() => {
            setContentType('');
            setDetectionType('');
            setDependency({
              contentType: '',
              detectionType,
              filter,
            });
          }}
        />
        <TabItem
          text="Text"
          active={contentType === 'text'}
          onClick={() => {
            setContentType('text');
            setDependency({
              contentType: 'text',
              detectionType,
              filter,
            });
          }}
        />
        <TabItem
          text="Image"
          active={contentType === 'image'}
          onClick={() => {
            setContentType('image');
            setDependency({
              contentType: 'image',
              detectionType,
              filter,
            });
          }}
        />
        <TabItem
          text="Video"
          active={contentType === 'video'}
          onClick={() => {
            setContentType('video');
            setDependency({
              contentType: 'video',
              detectionType,
              filter,
            });
          }}
        />
        <TabItem
          text="Audio"
          active={contentType === 'audio'}
          onClick={() => {
            setContentType('audio');
            setDependency({
              contentType: 'audio',
              detectionType,
              filter,
            });
          }}
        />
        <TabItem
          text="Url"
          active={contentType === 'url'}
          onClick={() => {
            setContentType('url');
            setDependency({
              contentType: 'url',
              detectionType,
              filter,
            });
          }}
        />
      </Tabs>
      <Box mt={6} mb={6}>
        <Flex>
          <Select
            width="30%"
            mr={2}
            value={detectionType}
            options={[
              { label: 'All', value: '' },
              { label: 'Model', value: 'model' },
              { label: 'Rules', value: 'rules' },
              { label: 'Hash', value: 'hash' },
              { label: 'Library', value: 'library' },
            ]}
            onChange={(e) => {
              setDetectionType(e.target.value);
              setDependency({
                contentType,
                detectionType: e.target.value,
                filter,
              });
            }}
          />
          <Input
            ml={2}
            width="70%"
            placeholder="Search detection components"
            onChange={(e) => {
              setFilter(e.target.value);
              setDependency({
                contentType,
                detectionType,
                filter: e.target.value,
              });
            }}
          />
        </Flex>
      </Box>
      <Section loading={isLoading}>
        {data && (
          <Box>
            <ComponentList
              add={add}
              components={data}
              onAdd={onAdd}
              onClick={onClick}
            />
          </Box>
        )}
      </Section>
    </Section>
  );
}

ComponentSection.propTypes = {
  add: PropTypes.bool,
  onAdd: PropTypes.func,
  onClick: PropTypes.func,
};

ComponentSection.defaultProps = {
  add: true,
  onAdd: null,
  onClick: null,
};

export default stylex(ComponentSection);
