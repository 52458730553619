import React from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react';

import Transition from 'pages/Transition';
import Unauthorized from 'pages/Unauthorized';

import AuthApp from './AuthApp';

function App() {
  useMsalAuthentication('redirect');
  const { inProgress } = useMsal();

  return (
    <>
      <AuthenticatedTemplate>
        <AuthApp />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {inProgress && <Transition text="Logging in" />}
        {!inProgress && <Unauthorized />}
      </UnauthenticatedTemplate>
    </>
  );
}
export default App;
