import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Form, Modal, Text, useHistory,
} from 'controls';

import api from 'api';

function DeleteBoard(props) {
  const {
    type, frames, board, onDelete,
  } = props;
  const history = useHistory();

  return (
    <Modal
      title={`Delete ${frames ? 'frame' : ''} board ${board.id}`}
      width={['100%', '100%', '500px']}
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      <Form
        onSubmit={async () => {
          const response = await api.deleteBoard(type, board.id);
          if (!response.error) {
            if (onDelete) {
              onDelete();
            }

            history.push(`/${type}/boards?frames=${frames}`);
            return null;
          }

          if (response.error.status === 404) {
            return 'Board not found';
          }

          return null;
        }}
        action="Delete"
        actionVariant="error"
        fields={() => (
          <Box>
            <Text
              mb={2}
              color="muted"
              text={`Are you sure you want to delete board ${board.id}?`}
            />
          </Box>
        )}
      />
    </Modal>
  );
}

DeleteBoard.propTypes = {
  board: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  frames: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
};

DeleteBoard.defaultProps = {
  onDelete: null,
};

export default DeleteBoard;
