import React from 'react';

import Markdown from './Markdown';

const markdown = `
## Documentation 

### Documentation Quick Links
* [Workflow and APIs](/documentation/workflow)
* [Customization with Skills](/documentation/skills)
* [Repository and Components](/documentation/repository)
* [Review results with Boards](/documentation/boards)
* [Monitor performance](/documentation/metrics)
* Manage deployment

### Watch For
Watch For is a large-scale, low-cost, highly programmable video analysis platform from Microsoft T&R.
Since 2017, Watch For has analyzed large volumes of videos and live streams for first-party
teams such as Bing, Mixer and Xbox, powering dozens of different experiences with a reach of tens of
millions of users (e.g. [Mixer HypeZones](https://www.microsoft.com/en-us/garage/blog/2018/09/watch-for-hackathon-2017-winner-powers-mixers-massively-successful-hypezone/),
[Bing’s Live Stream Search](https://www.microsoft.com/en-us/garage/wall-of-fame/watch-for/), 
[MSN Esports Hub](https://blogs.msn.com/introducing-the-msn-esports-hub/)). Watch For's
infrastructure is designed to address a broad range of verticals from gaming analytics to content moderation.

### Content Moderation with Watch For
This deployment specifically addresses content moderation and the rest of documentation is focused on this vertical.
In content moderation, the goal of Watch For is to deliver a *One Media Moderation Framework*
that can be used by teams across Microsoft to keep their users safe from inappropriate content (images, videos, live streams, and gifs). 
To that end, Watch For addresses three important dimensions:
* **Shareability**: There are great efforts across the company from Bing to Xbox in building media models to understand various forms of inappropriate content. With a *unified repository of AI models and libraries*, with standardized interfaces, Watch For makes it easy for teams across Microsoft to share, discover and use these models.
* **Customizability**: Different teams have different content types, moderation policies and optimization goals. With an *expressive low-code programming framework*, Watch For makes it easy for teams to express their custom policies and optimizations on top of various AI models and libraries.
* **Affordability**: A customized large-scale video analysis pipeline is expensive to build and operate. With an *efficient large-scale runtime*, Watch For makes it easy for teams to deploy these at scale and at very low cost.

We briefly describe each of the high-level components in the Watch For infrastructure below:
* [**Unified Repository of AI models and libraries**](documentation/repository): Watch For manages a growing [repository](/repository) of AI models and libraries, called *Components*, developed by different teams across the company. These include models to find inappropriate content (e.g. Adult, Gore) as well as general-purpose libraries to analyze media (e.g. Face detection, Image hashing). We have standardized the interfaces to these components so that they can be invoked in a consistent manner when building a custom analysis pipeline. Your team can also bring in your own models to the repository and combine them with existing models to address context and fill gaps in coverage.
* [**Expressive Low-Code Programming Framework**](documentation/skills): On top of the repository, Watch For provides an expressive, web-based programming framework to build custom analysis pipelines for images, videos and live streams. These pipelines, called *Skills*, are built using JavaScript with fully imperative code. The runtime executes these skills efficiently. The framework enables your team to rapidly iterate and build custom pipelines to meet your custom moderation policies and optimization goals.
* **Efficient Large-Scale Runtime**: Watch For has a scalable runtime that can analyze tens of thousands of video streams concurrently at extremely low cost. For instance, Watch For analyzed all Mixer streams (30K concurrent streams at peak) at $0.5 / stream / month (= total deployment cost of less than $15K per month). Watch For's runtime efficiency comes from significant optimizations across various stages in the video analysis pipeline. Watch For is a fully managed service and your team can integrate and scale with minimal effort.

### Get Started
* [Workflow and APIs](/documentation/workflow)
* [Customization with Skills](/documentation/skills)
* [Repository and Components](/documentation/repository)
* [Review results with Boards](/documentation/boards)
* [Monitor performance](/documentation/metrics)
* Manage deployment

<br/><br/>
`;

export default function Doc() {
  return <Markdown markdown={markdown} />;
}
