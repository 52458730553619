import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdownWithHtml from 'react-markdown/with-html';
import gfm from 'remark-gfm';
import SyntaxHighlighter, { Prism as PrismSyntaxHighlighter } from 'react-syntax-highlighter';
import { duotoneLight, darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { defaultStyle } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import {
  Page, stylex,
} from 'controls';

/* eslint-disable */
const renderers = {
  code: ({ language, value }) => {
    if (language === "javascript") {
      return (
        <PrismSyntaxHighlighter showLineNumbers language={language} style={darcula}>
          {value}
        </PrismSyntaxHighlighter >
      );
    } else if (language === "pseudocode") {
      return (
        <PrismSyntaxHighlighter showLineNumbers language="javascript" style={duotoneLight}>
          {value}
        </PrismSyntaxHighlighter >
      );
    }    
    else {
      return (
        <SyntaxHighlighter language={language} style={defaultStyle}>
          {value}
        </SyntaxHighlighter >
      );
    }
  },
};
/* eslint-enable */

function Mardown(props) {
  const { className, markdown } = props;
  return (
    <Page className={className} mt={4}>
      <ReactMarkdownWithHtml
        renderers={renderers}
        plugins={[gfm]}
        allowDangerousHtml
      >
        {markdown}
      </ReactMarkdownWithHtml>
    </Page>
  );
}

Mardown.propTypes = {
  className: PropTypes.string,
  markdown: PropTypes.string.isRequired,
};

Mardown.defaultProps = {
  className: null,
};

export default stylex(Mardown);
