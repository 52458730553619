import React from 'react';

import Markdown from 'documentation/Markdown';

const markdown = `

Integrate with the following APIs to automatically detect traits in live video streams and moderate them in real-time. API key should be in the *x-api-key* header.

#### Processing live video streams
A live video stream is sampled to extract frames and analysis is done on each sampled frame. Results are produced in real-time as each sampled frame is processed. Sampling rate, per-frame analysis and aggregate analysis (across a time window) are all configurable through skills.

~~~
POST /streams
~~~
Start processing a live video stream. The stream is processed until the stop API is called. Input is specified in the body. The main fields are:
* **id** (required): Unique id for the live stream. It can be maximum 1024 characters with [0-9a-zA-Z-_]+. The results in storage are overwritten when multiple videos are passed with the same id. *We recommend not to pass any PII information as part of id.*
* **streamUri** (required): Url to the live stream. We currently support HLS streams and url refers to the HLS manifest file (.m3u8).
* **props** (optional): Properties exposed to the skill. Props has to be formatted as a JSON ({ ... }).
* **context** (optional): A string that is simply echoed back. Pass any value that needs to be read together with the results (e.g. to correlate). Context is attached to the results sent to Event Hub and also stored and returned as part of the GET results API. *We recommend not to pass any PII information here*.
* **privateContext** (optional): Similar to *context* except that it is not stored and not returned as part of the GET results API. It will only be echoed back with the results sent to Event Hub.
* **decryptionKey** (optional): A decryption key for the encrypted live stream. It should be encoded in Base64.

~~~
GET /streams/{id}
~~~
Get real-time results for a live stream. Results are updated after each sampled frame is processed. The API can be periodically polled to get results in real-time.
If retention policy doesn't allow results to be stored, this API only returns the processing status.
The following describes the main fields in the response:
* **id**: Id in the request.
* **status**: Contains one of four values (waiting / processing / success / error) that specifies whether the live stream is waiting to be processed, is currently being processed, has successfully processed the most recent sampled frame or the processing of a frame resulted in an error. If the value is *waiting* or *processing*, poll again. If the value is *error*, the *error* field has a descriptive message. If the value is *success*, read the other fields.
* **result**: Contains the results. The *result* field has all the data returned by the skill (<code>processFrame</code> method) being run. For example, the template skill returns the following values after each frame is processed:
  * result.adult: Whether the most recent sampled frame is classified as adult.
  * result.racy: Whether the most recent sampled frame is classified as racy.
  * result.gore: Whether the most recent sampled frame is classified as gore.
  * result.hash: Perceptual (d)hash of the frame.
  * result.aggregate.adult: Whether the live stream is classified as adult based on a recent time window of processed frames.
  * result.aggregate.racy: Whether the live stream is classified as racy based on a recent time window of processed frames.
  * result.aggregate.gore: Whether the live stream is classified as gore based on a recent time window of processed frames.
* **context**: The context value passed in the POST request.
* **stats**: Stats such as live stream attributes, timestamps and processing durations.
* **error**: Descriptive error if status is returned as *error*. 
* **chunkUri** and **frameIndex**: Url to the HLS chunk and index of the sampled frame inside the chunk (for the most recent frame processed).
* **frameUri**: Url to the most recent processed frame image, if retention policy and skill allows for storing frames. Images are stored at 480p resolution.

~~~
PUT /streams/{id}/stop
~~~
Stop processing a live stream. Live streams are continuously processed (at a configured sampling rate) until stop is called irrespective of if processing throws an error.

~~~
GET /streams/{id}/frames
~~~
Get results for individual sampled frames. The results are limited to the last 100 frames. If retention policy doesn't allow results to be stored, this API returns empty list.
The response is an array of frame results. The following describes the main fields in the response:
* **timestamp**: Timestamp of the frame in the live stream.
* **result**: Contains the frame results. The *result* field has all the data returned by the skill (<code>processFrame</code> method) being run on each frame. For example, the template skill returns the following values for each frame:
  * result.adult: Whether the most recent sampled frame is classified as adult.
  * result.racy: Whether the most recent sampled frame is classified as racy.
  * result.gore: Whether the most recent sampled frame is classified as gore.
  * result.classifiers: Raw results from individual models.
  * result.hash: Perceptual (d)hash of the frame.
* **chunkUri** and **frameIndex**: Url to the HLS chunk and index of the sampled frame inside the chunk.
* **frameUri**: Url to the image, if retention policy and skill allows for storing frames. Images are stored at 480p resolution.
* **stats**: Stats such as processing durations.

~~~
GET /streams/active
~~~
Get all active streams being processed. This API can be periodically used to identify the streams that need to be started/stopped.
The response is an array of streams. The main field in the object is the stream *id*.

~~~
READ Stream Event Hub
~~~
Results are pushed to Event Hub (both on success and on error) as soon as every frame is processed.
One event is generated per sampled frame. Event Hub is partitioned by stream *id* and events for a specific stream are guaranteed to be in order.
The result format is same as described in the GET /streams/{id} API.

#### Example
The following shows a sample request:

~~~
POST /streams

{
  "id": "test-stream",
  "streamUri": "https://nmxlive.akamaized.net/hls/live/529965/Live_1/index.m3u8"
}
~~~

The following is the result. Note that results are continuously updated for live streams.
~~~
{
  "id": "test-stream",
  "status": "success",
  "result": {
    "adult": false,
    "racy": false,
    "gore": false
  }
  ...
}
~~~

<br/><br/>
`;

export default function Doc() {
  return <Markdown fontSize={15} mt={6} markdown={markdown} />;
}
