import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Box, CodeInput, Field, Form, Input, Modal, useHistory,
} from 'controls';

import api from 'api';

function CreateOrEditLayout(props) {
  const {
    create, layout,
    onCreate, onUpdate,
  } = props;
  const history = useHistory();

  return (
    <Modal
      title={`${create ? 'Create' : 'Edit'} layout`}
      width={['100%', '100%', '700px']}
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      <Form
        initialValues={{
          id: layout.id,
          descriptor: layout.descriptor,
        }}
        validationSchema={Yup.object().shape({
          id: Yup.string().required('Required'),
        })}
        onSubmit={async (values) => {
          if (create) {
            const response = await api.createLayout(
              values.id,
              values.descriptor,
            );

            if (!response.error) {
              if (onCreate) {
                onCreate();
              }

              history.goBack();
              return null;
            }

            if (response.error.status === 409) {
              return 'Layout id already taken';
            }
          }

          if (!create) {
            const response = await api.updateLayout(
              values.id,
              values.descriptor,
            );

            if (!response.error) {
              if (onUpdate) {
                onUpdate();
              }

              history.goBack();
              return null;
            }

            if (response.error.status === 404) {
              return 'Layout not found';
            }
          }

          return null;
        }}
        action={create ? 'Create' : 'Update'}
        fields={() => (
          <Box>
            <Field
              label="Layout id"
              name="id"
              component={(
                <Input
                  disabled={!create}
                />
              )}
            />
            <Field
              mt={2}
              label="Layout description"
              name="descriptor"
              component={<CodeInput mt={1} fontSize={14} minLines={6} />}
            />
          </Box>
        )}
      />
    </Modal>
  );
}

CreateOrEditLayout.propTypes = {
  create: PropTypes.bool,
  onUpdate: PropTypes.func,
  onCreate: PropTypes.func,
  layout: PropTypes.shape({
    id: PropTypes.string,
    descriptor: PropTypes.string,
  }),
};

CreateOrEditLayout.defaultProps = {
  create: true,
  onUpdate: null,
  onCreate: null,
  layout: {
    id: '',
    descriptor: '',
  },
};

export default CreateOrEditLayout;
