import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Divider, Flex, Link, Page, Section, Text, useParams,
} from 'controls';

import Utils from 'components/Utils';

const NavContainer = (props) => {
  const {
    title, description, width, to,
  } = props;
  return (
    <Box width={width} p={3} borderWidth={1} borderStyle="solid" borderColor="border" borderRadius={4}>
      {!to && (
        <Text
          fontSize="small"
          fontWeight={500}
          text={title}
        />
      )}
      {to && (
        <Link
          to={`/detect/${to}/test-queue/api`}
        >
          <Text fontSize={16}>
            {title}
          </Text>
        </Link>
      )}
      {description && (
        <Text mt={1} color="muted" fontSize={16}>
          {description}
        </Text>
      )}
    </Box>
  );
};

NavContainer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  width: PropTypes.string.isRequired,
  to: PropTypes.string,
};

NavContainer.defaultProps = {
  description: null,
  to: null,
};

function HackDetectContent() {
  const params = useParams();
  const { type } = params;

  return (
    <Page mt={4}>
      <Section mt={6} mb={4}>
        <Box>
          <Flex alignItems="center">
            <Button
              fontSize={17}
              format="inline"
              text="Automatic Detection"
              to="/detect"
            />
            <Text
              ml={2}
              mr={1}
              fontSize={17}
              text=">"
            />
            <Text
              ml={2}
              fontSize={17}
              text={Utils.toPascalCase(type)}
            />
          </Flex>
        </Box>
      </Section>
      <Divider />
      <Section mt={6}>
        <Flex mt={4} justifyContent="center">
          <Flex width="100%">
            <NavContainer
              width="100%"
              title="test-queue"
              description="Test queue"
              to={type}
            />
          </Flex>
        </Flex>
      </Section>
    </Page>
  );
}

export default HackDetectContent;
