import React from 'react';
import PropTypes from 'prop-types';

import {
  Box, Page, Text,
} from 'controls';

function Transition(props) {
  const { text } = props;
  return (
    <Page mt={6}>
      <Box width="100%" textAlign="center" mt={6}>
        <Text
          text={text}
        />
      </Box>
    </Page>
  );
}

Transition.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Transition;
