import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Flex, Input, Label, Page, Section, Tabs, TabItem, Text, Textarea, Time,
  useApi, useHistory, useParams, useQuery,
} from 'controls';

import api from 'api';
import CDF from 'components/CDF';
import Utils from 'components/Utils';

const STRINGS = {
  placeholders: {
    content: 'Prepend fields with "c." (e.g. c.skillId = "my-skill-id").',
    frames: 'Prepend fields with "c." (e.g. c.result.adult = true).',
  },
};

const Plot = (props) => {
  const { title, points } = props;
  return (
    <Flex mt={4} justifyContent="center">
      <Box width={['100%', '100%', '70%']}>
        <Text
          mb={2}
          fontWeight="bold"
          text={title}
        />
        <CDF data={points} />
      </Box>
    </Flex>
  );
};

Plot.propTypes = {
  title: PropTypes.string.isRequired,
  points: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
};

function Metrics() {
  const history = useHistory();
  const params = useParams();
  const { type } = params;
  const queryParams = useQuery();
  const frames = queryParams.get('frames') === 'true';
  const [filter, setFilter] = useState(queryParams.get('filter'));
  const [orderBy, setOrderBy] = useState(queryParams.get('orderBy') || 'c.createdAt DESC');
  const [limit, setLimit] = useState(queryParams.get('limit') || 1000);

  const {
    data: metricsData, isLoading: isMetricsLoading, setDependency,
  } = useApi(async () => api.getMetrics(type, frames, filter, orderBy, limit), 0);

  return (
    <Page mt={2}>
      {(type === 'videos' || type === 'streams' || type === 'gifs') && (
        <Tabs mt={2}>
          <TabItem
            text={`${Utils.toSingular(type)} metrics`}
            active={!frames}
            to={`/${type}/metrics`}
          />
          <TabItem
            text="Frame metrics"
            active={frames}
            to={`/${type}/metrics?frames=true`}
          />
        </Tabs>
      )}

      <Box
        mt={4}
      >
        <Label
          text="WHERE"
        />
        <Textarea
          defaultValue={filter}
          placeholder={!frames ? STRINGS.placeholders.content : STRINGS.placeholders.frames}
          onBlur={(e) => setFilter(e.target.value)}
          spellCheck={false}
          minRows={3}
        />
      </Box>
      <Flex mt={1} justifyContent="space-between">
        <Box
          width="50%"
          mr={2}
        >
          <Label
            text="ORDER BY"
          />
          <Input
            defaultValue={orderBy}
            onBlur={(e) => setOrderBy(e.target.value)}
          />
        </Box>
        <Box
          width="50%"
          ml={2}
        >
          <Label
            text="LIMIT"
          />
          <Input
            width="100%"
            type="number"
            defaultValue={limit}
            onBlur={(e) => setLimit(e.target.value)}
          />
        </Box>
      </Flex>
      <Flex mt={4} justifyContent="space-between" alignItems="center">
        <Flex>
          <Button
            pt={1}
            pb={1}
            width={100}
            height={30}
            text="Query"
            loading={isMetricsLoading}
            onClick={() => history.push(`/${type}/metrics?filter=${filter || ''}&orderBy=${orderBy || ''}&frames=${frames}&limit=${limit}`)}
          />
        </Flex>
        <Button
          format="inline"
          text="Refresh"
          onClick={() => setDependency(Time.now())}
        />
      </Flex>
      <Section mt={4} loading={isMetricsLoading}>
        {metricsData && !frames && (
          <Box p={4} mt={2} textAlign="center">
            {(type === 'videos' || type === 'gifs') && (
              <>
                <Plot
                  title="Average frame latency (s)"
                  points={metricsData.avgLatencies}
                />
                <Plot
                  title="Average frame fetching time (s)"
                  points={metricsData.avgFetchTimes}
                />
                <Plot
                  title="Average frame processing time (s)"
                  points={metricsData.avgProcessTimes}
                />
              </>
            )}
            {type === 'images' && (
              <>
                <Plot
                  title="Image fetching time (s)"
                  points={metricsData.avgFetchTimes}
                />
                <Plot
                  title="Image processing time (s)"
                  points={metricsData.avgProcessTimes}
                />
              </>
            )}
            {type === 'streams' && (
              <>
                <Plot
                  title="Frame fetching time (s)"
                  points={metricsData.avgFetchTimes}
                />
                <Plot
                  title="Frame processing time (s)"
                  points={metricsData.avgProcessTimes}
                />
              </>
            )}
            <Plot
              title="Latency (s)"
              points={metricsData.latencies}
            />
            {(type === 'videos') && (
              <>
                <Plot
                  title="Total frames processed per video"
                  points={metricsData.framesProcessed}
                />
              </>
            )}
            {(type === 'videos' || type === 'audios') && (
              <>
                <Plot
                  title="Content length (s)"
                  points={metricsData.durations}
                />
              </>
            )}
            {type === 'gifs' && (
              <>
                <Plot
                  title="Total number frames processed per gif"
                  points={metricsData.framesProcessed}
                />
              </>
            )}
            <Plot
              title="Waiting time (s)"
              points={metricsData.waitTimes}
            />
          </Box>
        )}
        {metricsData && frames && (
          <Box p={4} mt={2} textAlign="center">
            <Plot
              title="Frame latency (s)"
              points={metricsData.latencies}
            />
            <Plot
              title="Frame fetching time (s)"
              points={metricsData.fetchTimes}
            />
            <Plot
              title="Frame processing time (s)"
              points={metricsData.processTimes}
            />
          </Box>
        )}
      </Section>
    </Page>
  );
}

export default Metrics;
