import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';
import 'brace/mode/java';
import 'brace/theme/monokai';

import Box from './Box';
import stylex from './stylex';

function CodeInput(props) {
  const {
    className, minLines, name, onChange, onValidate, readOnly, value, mode,
  } = props;
  const [valueState, setValue] = useState(value || '');

  return (
    <Box className={className}>
      <AceEditor
        mode={mode}
        theme="monokai"
        name={name}
        value={valueState}
        onChange={(v) => {
          setValue(v);
          if (onChange) {
            onChange({ target: { name, value: v } });
          }
        }}
        onValidate={(v) => {
          // Check if onValidate function is not null
          if (onValidate) {
            if (v.length > 0 && v.find((x) => x.type === 'error')) {
              onValidate(false);
            } else {
              onValidate(true);
            }
          }
        }}
        fontSize="inherit"
        showPrintMargin
        showGutter
        highlightActiveLine
        setOptions={{
          showLineNumbers: true,
          tabSize: 2,
          useWorker: true,
        }}
        width="100%"
        minLines={minLines}
        maxLines={Infinity}
        readOnly={readOnly}
      />
    </Box>
  );
}

CodeInput.propTypes = {
  className: PropTypes.string,
  minLines: PropTypes.number,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onValidate: PropTypes.func,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  mode: PropTypes.string,
};

CodeInput.defaultProps = {
  className: null,
  minLines: 10,
  name: 'code',
  onChange: null,
  onValidate: null,
  readOnly: false,
  value: null,
  mode: 'javascript',
};

export default stylex(CodeInput);
