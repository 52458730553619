import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Card, Divider, Dropdown, EllipsisText, Flex, Form,
  Icon, IconButton, MenuItem, stylex, Text, Time,
} from 'controls';

import api from 'api';
import Utils from './Utils';
import AudioContent from './AudioContent';

import AnnotatedImage from './AnnotatedImage';

const STRINGS = {
  actions: {
    safe: 'Safe',
    unsafe: 'Flag',
    marked: 'Marked as safe',
    flagged: 'Flagged as unsafe',
  },
};

function ContentItem(props) {
  const {
    className, index,
    onClick, onCreateReport, onDetails, onPinned,
    onReviewChange, onReviewed, onWatchVideo,
    content, type, moderate,
  } = props;
  const [contentState, setContent] = useState(content);
  const [blur, setBlur] = useState(contentState.flagged);
  const [annotate, setAnnotate] = useState(true);

  const showAnnotateIcon = contentState && contentState.result
    && ((contentState.result.objects && contentState.result.objects.length > 0)
      || (contentState.result.faces && contentState.result.faces.length > 0)
      || (contentState.result.ocr && contentState.result.ocr.lines
        && contentState.result.ocr.lines.length > 0));

  let mediaType = true;
  const contentName = Utils.toSingular(type);
  if (type === 'texts' || type === 'audios' || content.channelType === 'subtitles') {
    mediaType = false;
  }

  let skillNameAndVersionInfo = `${contentName}: ${contentState.skillId}`;
  if (contentState.skillVersion) {
    skillNameAndVersionInfo = skillNameAndVersionInfo.concat(`, version: ${contentState.skillVersion}`);
  }

  return (
    <Box
      className={className}
      pl={index % 2 === 0 ? 0 : 1}
      pr={index % 2 === 0 ? 1 : 0}
      py={2}
    >
      <Card
        p={0}
      >
        <Flex pt={1} pb={2} px={2} justifyContent="space-between">
          <Box>
            {!onClick && (
              <Button
                fontSize="x-small"
                lineHeight="100%"
                format="inline"
                to={`/${type}/${contentState.id}`}
                text={contentState.id}
              />
            )}
            {onClick && (
              <Button
                fontSize="x-small"
                lineHeight="100%"
                format="inline"
                onClick={() => onClick(contentState.id)}
                text={contentState.id}
              />
            )}
            <Text
              fontSize="xxx-small"
              lineHeight="100%"
              color="muted"
              text={skillNameAndVersionInfo}
            />
          </Box>
          <Flex alignItems="center">
            {showAnnotateIcon && (
              <IconButton
                icon={[annotate ? 'fas' : 'far', 'square']}
                color="border"
                mr={3}
                onClick={() => setAnnotate(!annotate)}
              />
            )}
            <IconButton
              icon="thumbtack"
              color={contentState.pinned ? 'primary' : 'border'}
              mr={3}
              onClick={async () => {
                const response = await api.updatePin(
                  type, contentState.id, null, !contentState.pinned,
                );
                if (!response.error) {
                  setContent(response.data);
                  if (onPinned) {
                    onPinned(contentState.id, response.data.pinned);
                  }
                }
              }}
            />
            <IconButton
              icon="list"
              color="muted"
              mr={2}
              onClick={() => onDetails(contentState)}
            />

            <Dropdown
              alignRight
              closeOnClick={false}
              toggle={(
                <Button format="inline" color="muted" fontSize="small">
                  {Time.ago(contentState.createdAt)}
                </Button>
              )}
              menuContent={(
                <Text
                  p={1}
                  textAlign="center"
                  color="muted"
                  width="250px"
                >
                  {Time.format(contentState.createdAt)}
                </Text>
              )}
            />
          </Flex>
        </Flex>
        {!contentState.private && !mediaType && type === 'texts' && (
          <>
            <Divider bg="bg-light" />
            <Box px={3} py={2} height="100px">
              <EllipsisText
                text={contentState.contentText}
              />
            </Box>
            <Divider bg="bg-light" />
          </>
        )}
        {!contentState.private && !mediaType && type === 'audios' && (
          <>
            <Divider bg="bg-light" />
            <Box px={3} py={2}>
              <AudioContent
                content={contentState}
                onWatchVideo={onWatchVideo}
              />
            </Box>
            <Divider bg="bg-light" />
          </>
        )}
        {!contentState.private && !mediaType && contentState.channelType === 'subtitles' && (
          <Box position="relative">
            <Divider bg="bg-light" />
            {contentState.subtitles && contentState.subtitles.instances.map((subtitle) => (
              <Box p={2}>
                <Box fontSize="xxx-small" color="muted">
                  {subtitle.startTime.toFixed(2)}
                  {' --> '}
                  {subtitle.endTime.toFixed(2)}
                </Box>
                <Box>
                  {subtitle.lines.map((line) => (
                    <Box>
                      {line}
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
            {contentState.result && contentState.result.overlay && (
              <Flex
                position="absolute"
                bottom="0px"
                right="0px"
                color="bg"
                bg="rgba(0, 0, 0, 0.5)"
                borderRadius={4}
                px={2}
                py="3px"
                fontSize="xxx-small"
                alignItems="center"
              >
                <Text
                  lineHeight="100%"
                  text={contentState.result.overlay}
                />
              </Flex>
            )}
          </Box>
        )}
        {!contentState.private && mediaType && (
          <Box position="relative">
            <AnnotatedImage
              src={type === 'images' ? contentState.contentUri : contentState.thumbnailUri}
              blur={blur}
              alt={contentState.thumbnailUri == null ? null : contentState.id}
              imageWidth={contentState.stats.width}
              imageHeight={contentState.stats.height}
              result={contentState.result}
              annotate={annotate}
            />

            {type === 'videos' && (
              <Button
                borderColor="muted"
                py={1}
                px={2}
                fontSize="xx-small"
                position="absolute"
                top="10px"
                right="10px"
                text="Watch"
                bg="rgba(0, 0, 0, 0.5)"
                onClick={() => {
                  if (onWatchVideo) {
                    onWatchVideo(content);
                  }
                }}
              />
            )}

            {contentState.stats && type === 'videos' && (
              <Flex
                position="absolute"
                top="10px"
                left="10px"
                color="bg"
                bg="rgba(0, 0, 0, 0.5)"
                borderRadius={4}
                px={2}
                py="3px"
                fontSize="xxx-small"
                alignItems="center"
              >
                <Text
                  lineHeight="100%"
                  text={`${contentState.stats.duration.toFixed(1)}s`}
                />
              </Flex>
            )}
            <Flex
              position="absolute"
              bottom="0px"
              left="0px"
              color="bg"
              bg="rgba(0, 0, 0, 0.5)"
              borderRadius={4}
              px={2}
              py="3px"
              fontSize="xxx-small"
              alignItems="center"
            >
              <Text
                lineHeight="100%"
                text={Time.format(contentState.createdAt, 'YYYY-MM-DD hh:mm:ss A Z')}
              />
            </Flex>
            {contentState.result && contentState.result.overlay && (
              <Flex
                position="absolute"
                bottom="0px"
                right="0px"
                color="bg"
                bg="rgba(0, 0, 0, 0.5)"
                borderRadius={4}
                px={2}
                py="3px"
                fontSize="xxx-small"
                alignItems="center"
              >
                <Text
                  lineHeight="100%"
                  text={contentState.result.overlay}
                />
              </Flex>
            )}
          </Box>
        )}
        {!contentState.private && moderate && (
          <Flex pt={2} pb={2} px={2} height="40px" alignItems="center">
            {contentState.reviewed && (
              <Flex justifyContent="space-between" width="100%">
                {!contentState.flagged && (
                  <Text color="accent-dark">
                    <Icon icon="check" mr={2} />
                    {STRINGS.actions.marked}
                  </Text>
                )}
                {contentState.flagged && (
                  <Text color="error">
                    <Icon icon="exclamation-circle" mr={2} />
                    {STRINGS.actions.flagged}
                  </Text>
                )}
                <Dropdown
                  alignRight
                  menuStyle={{ overflow: 'visible' }}
                  toggle={(
                    <IconButton color="muted" icon="ellipsis-v" />
                  )}
                  menuContent={(
                    <>
                      <MenuItem
                        fontSize="small"
                        py={2}
                        text="Remove review"
                        onClick={async () => {
                          const response = await api.updateReview(
                            type, contentState.id, null, false, false,
                          );
                          if (!response.error) {
                            setContent(response.data);
                            setBlur(false);
                            if (onReviewChange) {
                              onReviewChange();
                            }
                          }
                        }}
                      />
                      {contentState.flagged && blur && (
                        <MenuItem
                          fontSize="small"
                          py={2}
                          text="Unblur"
                          onClick={() => setBlur(false)}
                        />
                      )}
                      {contentState.flagged && !blur && (
                        <MenuItem
                          fontSize="small"
                          py={2}
                          text="Blur"
                          onClick={() => setBlur(true)}
                        />
                      )}
                      {contentState.flagged && (
                        <MenuItem
                          fontSize="small"
                          py={2}
                          text="Create report"
                          onClick={() => {
                            if (onCreateReport) {
                              onCreateReport(contentState);
                            }
                          }}
                        />
                      )}
                    </>
                  )}
                />
              </Flex>
            )}
            {!contentState.reviewed && (
              <Flex justifyContent="space-between" width="100%">
                <Flex>
                  <Form
                    errorPosition="none"
                    onSubmit={async () => {
                      const response = await api.updateReview(
                        type, contentState.id, null, true, false,
                      );
                      if (!response.error) {
                        setContent(response.data);
                        setBlur(false);
                        if (onReviewed) {
                          onReviewed(contentState.id, false);
                        }
                        if (onReviewChange) {
                          onReviewChange();
                        }
                      }
                      return null;
                    }}
                    action={(fprops) => (
                      <Button
                        type="submit"
                        width="100px"
                        py="2px"
                        fontSize="small"
                        variant="accent"
                        loading={fprops.isSubmitting}
                      >
                        <Icon icon="check" mr={2} />
                        {STRINGS.actions.safe}
                      </Button>
                    )}
                  />
                  <Form
                    ml={3}
                    errorPosition="none"
                    onSubmit={async () => {
                      const response = await api.updateReview(
                        type, contentState.id, null, true, true,
                      );
                      if (!response.error) {
                        setContent(response.data);
                        setBlur(true);
                        if (onReviewed) {
                          onReviewed(contentState.id, true);
                        }
                        if (onReviewChange) {
                          onReviewChange();
                        }
                      }
                      return null;
                    }}
                    action={(fprops) => (
                      <Button
                        type="submit"
                        width="100px"
                        py="2px"
                        fontSize="small"
                        variant="error"
                        loading={fprops.isSubmitting}
                      >
                        <Icon icon="exclamation-circle" mr={2} />
                        {STRINGS.actions.unsafe}
                      </Button>
                    )}
                  />
                </Flex>
              </Flex>
            )}
          </Flex>
        )}
      </Card>
    </Box>
  );
}

ContentItem.propTypes = {
  className: PropTypes.string,
  content: PropTypes.shape({
    frameImageUrl: PropTypes.string,
    channelType: PropTypes.string,
  }).isRequired,
  index: PropTypes.number,
  onClick: PropTypes.func,
  onCreateReport: PropTypes.func,
  onDetails: PropTypes.func,
  onPinned: PropTypes.func,
  onReviewChange: PropTypes.func,
  onReviewed: PropTypes.func,
  onWatchVideo: PropTypes.func,
  type: PropTypes.string.isRequired,
  moderate: PropTypes.bool,
};

ContentItem.defaultProps = {
  className: null,
  index: 0,
  onClick: null,
  onCreateReport: null,
  onDetails: null,
  onPinned: null,
  onReviewChange: null,
  onReviewed: null,
  onWatchVideo: null,
  moderate: false,
};

export default stylex(ContentItem);
