class Utils {
  static toDecimal = (input, places) => Math.round(input * (10 ** places)) / (10 ** places);

  static mapContentTypeToRestType = (contentType) => `${contentType.toLowerCase()}s`;

  static toPascalCase = (input) => input.charAt(0).toUpperCase() + input.slice(1);

  static toSingular = (input) => input.charAt(0).toUpperCase() + input.slice(1).slice(0, -1);

  static toSingularLowerCase = (input) => input.charAt(0).toLowerCase()
    + input.slice(1).slice(0, -1);
}

export default Utils;
