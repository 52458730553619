import React from 'react';

import Markdown from './Markdown';

const markdown = `
## Monitoring performance
Watch For has built-in performance monitoring for skills. By default, it collects various metrics that can
be used to understand performance and optimize cost of deployment.
Watch For provides a [metrics dashboard](/videos/metrics)
that can be used to interactively query performance data with a fully expressive SQL syntax.

### Performance Metrics
#### Images
The following performance metrics are collected for image processing and returned as part of results (in the *stats* field).
All time values are stored in seconds.
* **Wait time** [*stats.waitTime*]: Waiting time in the queue for the image to be processed after the request is sent.
* **Fetch time** [*stats.fetchTime*]: Time to fetch the image from the URL endpoint provided in request.
* **Process time** [*stats.processTime*]: Time taken by the <code>processImage</code> method to process the image.
* **Latency** [*stats.latency*]: Total time taken to fetch and process the image. This excludes the queue waiting time. 

#### Videos
The following performance metrics are collected for video processing and returned as part of results (in the *stats* field).
All time values are stored in seconds.
* **Wait time** [*stats.waitTime*]: Waiting time in the queue for the video to be processed after the request is sent.
* **Average frame fetch time** [*stats.avgFetchTime*]: Average time to seek and fetch a frame from the video URL endpoint provided in request.
* **Average frame process time** [*stats.avgProcessTime*]: Average time taken by the <code>processFrame</code> method to process each frame.
* **Average frame latency** [*stats.avgLatency*]: Average latency to fetch and process each frame.
* **Total latency** [*stats.latency*]: Total time taken to fetch and process the entire video. This excludes the queue waiting time.
* **Total frames processed** [*stats.framesProcessed*]: Total number of frames processed for the video.
* **Duration** [*stats.duration*]: Total duration of the video in seconds.

The following performance metrics are collected for each individual video frame processed and returned as part of frame results (in the *stats* field).
* **Fetch time** [*stats.fetchTime*]: Time to seek and fetch the frame from the video URL endpoint provided in request.
* **Process time** [*stats.processTime*]: Time taken by the <code>processFrame</code> method to process the frame.
* **Latency** [*stats.latency*]: Latency to fetch and process the frame.

#### Live Streams
The following performance metrics are collected for live stream processing. Note that aggregate metrics are not maintained, 
only frame-level metrics for the last processed frame is returned and continuously updated (in the *stats* field).
* **Fetch time** [*stats.fetchTime*]: Time to fetch the recently processed frame from the streaming endpoint provided in request.
* **Process time** [*stats.processTime*]: Time taken by the <code>processFrame</code> method to process the frame.
* **Latency** [*stats.latency*]: Total time taken to fetch and process the frame. 

#### GIFs
The following performance metrics are collected for gif processing and returned as part of results (in the *stats* field).
All time values are stored in seconds.
* **Wait time** [*stats.waitTime*]: Waiting time in the queue for the gif to be processed after the request is sent.
* **Average frame fetch time** [*stats.avgFetchTime*]: Average time to seek and fetch a frame from the gif URL endpoint provided in request.
* **Average frame process time** [*stats.avgProcessTime*]: Average time taken by the <code>processFrame</code> method to process each frame.
* **Total latency** [*stats.latency*]: Total time taken to fetch and process the entire gif. This excludes the queue waiting time.
* **Average latency** [*stats.avgLatency*]: Average time taken to fetch and process for each frame.
* **Total frames processed** [*stats.framesProcessed*]: Total number of frames processed for the gif.

### Metrics dashboard
The [metrics dashboard](/videos/metrics) plots the above metrics in graphs as cumulative distribution functions (CDFs)
by aggregating results across requests. The CDFs can be used to understand median (50th percentile) and tail values
and the distribution.

A SQL query can be provided to select results that need to be analyzed.
The result storage in Watch For is backed by Azure Cosmos DB, a JSON store that supports 
indexing on all fields. The SQL query syntax used in Watch For is same 
as the one in [Cosmos DB](https://docs.microsoft.com/en-us/azure/cosmos-db/sql-query-getting-started).
Watch For provides a simple query interface that splits the query into three inputs:
* WHERE: Specifies filter conditions.
* ORDERBY: Specifies the the sorting field and direction (ASC or DESC).
* LIMIT: Specifies the maximum number of items returned.

The following Cosmos DB query is constructed using the fields above:

~~~
SELECT TOP {input.LIMIT} * FROM c WHERE {input.WHERE} ORDER BY {input.ORDERBY}
~~~

**Note that all field names should be prepended by "c.".** The following example returns all video results
that uses a specific skill and are flagged as adult.
* WHERE: c.skillId = "my-video-skill" AND c.result.adult = true
* ORDER BY: c.createdAt DESC
* LIMIT: 1000

If WHERE field is not provided, all results are considered.
If ORDER BY is not provided, <code>c.CreatedAt DESC</code> is used as default.
If LIMIT value is not provided, 1000 is used as default.

#### Comparing skill performance
The query interface in the metrics dashboard can be used to compare performance
of different skills. In the WHERE field, simply filter by c.skillId to study the performance
of a specific skill. Skill performance directly correlates with deployment cost.
Watch For team is working on [tools](/videos/status) to make it easy to understand end-to-end deployment cost
once a skill is written.

<br/><br/>
`;

export default function Doc() {
  return <Markdown markdown={markdown} />;
}
