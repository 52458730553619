import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, Modal, useHistory,
} from 'controls';

import api from 'api';

function CreateNewerVersionedSkill(props) {
  const {
    type, skillName, newSkillVersion,
    onCreateNewerVersionedSkill, cloneSkillVersion,
  } = props;
  const history = useHistory();

  return (
    <Modal
      title={`Save skill as Version ${newSkillVersion}?`}
      width={['100%', '576px', '700px']}
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      <Form
        onSubmit={async () => {
          const response = await api.createNewerVersionedSkill(
            type,
            skillName,
            cloneSkillVersion,
          );

          if (!response.error) {
            if (onCreateNewerVersionedSkill) {
              onCreateNewerVersionedSkill();
              history.push(`/${type}/skills/${skillName}/versions/${response.data.version}`);
            }
          }

          return null;
        }}
      />
    </Modal>
  );
}

CreateNewerVersionedSkill.propTypes = {
  onCreateNewerVersionedSkill: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  skillName: PropTypes.string.isRequired,
  newSkillVersion: PropTypes.number.isRequired,
  cloneSkillVersion: PropTypes.number.isRequired,
};

export default CreateNewerVersionedSkill;
