import { useMediaQuery } from 'react-responsive';
import PropTypes from 'prop-types';
import useTheme from './useTheme';

const Responsive = ({ children, bp, not }) => {
  const theme = useTheme();
  const isMinimum = useMediaQuery({ minWidth: theme.breakpoints[bp] });

  if (not) {
    return isMinimum ? null : children;
  }

  return isMinimum ? children : null;
};

Responsive.propTypes = {
  children: PropTypes.node,
  bp: PropTypes.node,
  not: PropTypes.node,
};

Responsive.defaultProps = {
  children: null,
  bp: null,
  not: null,
};

export default Responsive;
