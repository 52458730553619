import React from 'react';
import PropTypes from 'prop-types';

import {
  Box, Button, Divider, Dropdown, Flex, IconButton,
  MenuItem, Page, Section, Text, Time, useApi, useModalState,
} from 'controls';

import api from 'api';
import Utils from 'components/Utils';
import CreateFolder from './CreateFolder';
import DeleteFolder from './DeleteFolder';

const FolderItem = (props) => {
  const {
    resourceType,
    name,
    onDelete,
  } = props;

  return (
    <Box
      pt={3}
    >
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box>
          <Button
            format="inline"
            text={name}
            to={`/${resourceType}/${name}`}
          />
        </Box>
        <Box>
          <Dropdown
            alignRight
            closeOnClick={false}
            toggle={(
              <IconButton p={1} color="muted" icon="ellipsis-v" />
            )}
            menuStyle={{
              marginTop: '5px',
            }}
            menuContent={(
              <>
                <MenuItem
                  fontSize="small"
                  py={2}
                  text="Delete folder"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete();
                  }}
                />
              </>
            )}
          />
        </Box>
      </Flex>
      <Divider mt={3} />
    </Box>
  );
};

FolderItem.propTypes = {
  resourceType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

function ResourcesRoot(props) {
  const { resourceType } = props;
  const singularType = Utils.toSingular(resourceType);
  const pascalSingularType = Utils.toPascalCase(singularType);

  const { data, isLoading, setDependency } = useApi(async () => api.getFolders(resourceType), 0);

  const modalState = useModalState();
  const {
    createFolder, deleteFolder, folderName,
  } = modalState.get();

  return (
    <Page mt={2}>
      <Section mt={6}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <Text
            fontWeight="bold"
            text={`${pascalSingularType} folders`}
          />

          <Button
            format="inline"
            text="+ Create folder"
            onClick={() => modalState.push({
              createFolder: true,
            })}
          />
        </Flex>
        <Divider />
      </Section>
      <Section loading={isLoading}>
        {data && data.map((folder) => (
          <FolderItem
            key={folder}
            resourceType={resourceType}
            name={folder}
            onDelete={() => modalState.push({
              deleteFolder: true,
              folderName: folder,
            })}
          />
        ))}
        {data && data.length === 0 && (
          <Box mt={6} textAlign="center">
            <Text
              color="muted"
              text="No folders found"
            />
          </Box>
        )}
      </Section>
      {createFolder && (
        <CreateFolder
          resourceType={resourceType}
          onCreate={() => setDependency(Time.now())}
        />
      )}
      {deleteFolder && (
        <DeleteFolder
          resourceType={resourceType}
          name={folderName}
          onDelete={() => setDependency(Time.now())}
        />
      )}
    </Page>
  );
}

ResourcesRoot.propTypes = {
  resourceType: PropTypes.string.isRequired,
};

export default ResourcesRoot;
