import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Divider, Flex, Link, Page, Section, Text,
} from 'controls';

const NavContainer = (props) => {
  const {
    title, description, width, to,
  } = props;
  return (
    <Box width={width} p={3} m={1} borderWidth={1} borderStyle="solid" borderColor="border" borderRadius={4}>
      {!to && (
        <Text
          fontSize="small"
          fontWeight={500}
          text={title}
        />
      )}
      {to && (
        <Link
          to={`/detect/${to}`}
        >
          <Text fontSize={18}>
            {title}
          </Text>
        </Link>
      )}
      {description && (
        <Text p={2} color="muted" fontSize={14}>
          {description}
        </Text>
      )}
    </Box>
  );
};

NavContainer.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  width: PropTypes.string.isRequired,
  to: PropTypes.string,
};

NavContainer.defaultProps = {
  description: null,
  to: null,
};

function HackDetect() {
  return (
    <Page mt={4}>
      <Section mt={6} mb={4}>
        <Box>
          <Flex alignItems="center">
            <Text
              fontSize={17}
              text="Automatic Detection"
            />
          </Flex>
        </Box>
      </Section>
      <Divider />
      <Section mt={4}>
        <Text
          mt={1}
          color="muted"
          fontSize={14}
          text="Automatically detect negative and positive traits in various content types with built-in models, components, services and various detection assets in the company."
        />
      </Section>
      <Section mt={6}>
        <Flex justifyContent="center">
          <Flex width="100%" textAlign="center">
            <NavContainer
              width="33%"
              title="Texts"
              to="texts"
            />
            <NavContainer
              width="33%"
              title="Usernames"
              to="texts"
            />
            <NavContainer
              width="33%"
              title="URLs"
              to="urls"
            />
          </Flex>
        </Flex>

        <Flex justifyContent="center">
          <Flex width="100%" textAlign="center">
            <NavContainer
              width="33%"
              title="Images"
              to="images"
            />
            <NavContainer
              width="33%"
              title="Gifs"
              to="gifs"
            />
            <NavContainer
              width="33%"
              title="Videos"
              to="videos"
            />
          </Flex>
        </Flex>

        <Flex justifyContent="center">
          <Flex width="100%" textAlign="center">
            <NavContainer
              width="33%"
              title="Live Streams"
              to="streams"
            />
            <NavContainer
              width="33%"
              title="Audio"
              to="audios"
            />
            <NavContainer
              width="33%"
              title="Custom"
              to="customs"
            />
          </Flex>
        </Flex>
      </Section>
    </Page>
  );
}

export default HackDetect;
