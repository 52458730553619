import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import ReactJson from 'react-json-view';
import {
  Box, Button, Field, Flex, Form, Input, Section,
} from 'controls';

import api from 'api';

function HackDetectStreamTest(props) {
  const { skillId } = props;
  const [data, setData] = useState(null);

  return (
    <Section mt={4}>
      <Section mt={8}>
        <Form
          initialValues={{
            id: '',
            streamUri: '',
            stop: false,
          }}
          validationSchema={Yup.object().shape({
            id: Yup.string().required('Required'),
          })}
          onSubmit={async (values) => {
            let response = null;
            if (!values.stop) {
              response = await api.hackPostStream(
                values.id,
                values.streamUri,
                skillId,
              );
            } else {
              response = await api.hackStopStream(
                values.id,
              );
            }

            if (!response.error) {
              if (values.stop) {
                setData({});
              } else {
                setData(response.data);
              }
              return null;
            }

            return response.error.status.toString();
          }}
          action={null}
          fields={(fprops) => (
            <Box mb={2}>
              <Field
                name="id"
                component={(
                  <Input
                    placeholder="Enter a stream id"
                  />
                )}
              />
              <Field
                name="streamUri"
                component={(
                  <Input
                    placeholder="Enter a live stream URL (HLS)"
                  />
                )}
              />
              <Flex mt={4}>
                <Button
                  text="Start"
                  onClick={() => {
                    fprops.setFieldValue('stop', false);
                    fprops.submitForm();
                  }}
                  loading={fprops.isSubmitting && !fprops.values.stop}
                />
                <Button
                  ml={4}
                  variant="error"
                  text="Stop"
                  onClick={() => {
                    fprops.setFieldValue('stop', true);
                    fprops.submitForm();
                  }}
                  loading={fprops.isSubmitting && fprops.values.stop}
                />
              </Flex>
            </Box>
          )}
        />
      </Section>
      {data && (
        <Section mt={6}>
          <ReactJson
            src={data}
            displayDataTypes={false}
          />
        </Section>
      )}
    </Section>
  );
}

HackDetectStreamTest.propTypes = {
  skillId: PropTypes.string.isRequired,
};

export default HackDetectStreamTest;
