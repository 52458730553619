import { useEffect, useState } from 'react';

const useDimensions = (ref) => {
  const getWidth = () => ref.current.offsetWidth;
  const getHeight = () => ref.current.offsetHeight;

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWidth(getWidth());
      setHeight(getHeight());
    };

    if (ref.current) {
      setWidth(getWidth());
      setHeight(getHeight());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  return { width, height };
};

export default useDimensions;
