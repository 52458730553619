import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Box, CodeInput, Field, Form, Input, Modal, Select, useHistory,
} from 'controls';

import api from 'api';
import Utils from 'components/Utils';

function CreateOrEditBoard(props) {
  const {
    type, frames, create, board,
    onUpdate,
  } = props;
  const history = useHistory();
  const singularType = Utils.toSingular(type);

  return (
    <Modal
      title={`${create ? 'Create' : 'Edit'} ${singularType.toLowerCase()} ${frames ? 'frame' : ''} board`}
      width={['100%', '100%', '700px']}
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      <Form
        initialValues={{
          id: board.id,
          name: board.name,
          filterScript: board.filterScript,
          orderBy: board.orderBy,
          nextOffsetScript: board.nextOffsetScript,
          limit: board.limit,
          reviewable: board.reviewable,
          contentLayoutId: board.contentLayout ? board.contentLayout.id : '',
          frameLayoutId: board.frameLayout ? board.frameLayout.id : '',
        }}
        validationSchema={Yup.object().shape({
          id: Yup.string().required('Required'),
          name: Yup.string().required('Required'),
          filterScript: Yup.string().required('Required'),
          orderBy: Yup.string().required('Required'),
          nextOffsetScript: Yup.string().required('Required'),
          limit: Yup.number().required('Required'),
        })}
        onSubmit={async (values) => {
          if (create) {
            const response = await api.createBoard(
              type,
              frames,
              values.id,
              values.name,
              values.filterScript,
              values.orderBy,
              values.nextOffsetScript,
              values.limit,
              values.reviewable,
              values.contentLayoutId,
              values.frameLayoutId,
            );

            if (!response.error) {
              history.push(`/${type}/boards/${values.id}`);
              return null;
            }

            if (response.error.status === 409) {
              return 'Board id already taken';
            }
          }

          if (!create) {
            const response = await api.updateBoard(
              type,
              frames,
              values.id,
              values.name,
              values.filterScript,
              values.orderBy,
              values.nextOffsetScript,
              values.limit,
              values.reviewable,
              values.contentLayoutId,
              values.frameLayoutId,
            );

            if (!response.error) {
              if (onUpdate) {
                onUpdate();
              }

              history.goBack();
              return null;
            }

            if (response.error.status === 404) {
              return 'Board not found';
            }
          }

          return null;
        }}
        action={create ? 'Create' : 'Update'}
        fields={() => (
          <Box>
            <Field
              label="Board id"
              name="id"
              component={(
                <Input
                  disabled={!create}
                />
              )}
            />
            <Field
              label="Board name"
              name="name"
              component={(
                <Input />
              )}
            />
            <Field
              label="Review board for moderation"
              name="reviewable"
              component={(
                <Select
                  zIndex={10001}
                  options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
                />
              )}
            />
            <Field
              mt={2}
              label="Filter script returns the query filter (WHERE clause)"
              name="filterScript"
              component={<CodeInput mt={1} fontSize={14} minLines={6} />}
            />
            <Field
              label="Order by (ORDER BY clause)"
              name="orderBy"
              component={(
                <Input />
              )}
            />
            <Field
              label="Query LIMIT"
              name="limit"
              component={(
                <Input
                  type="number"
                />
              )}
            />
            <Field
              mt={2}
              label="Next offset script returns the offset value for the next page"
              name="nextOffsetScript"
              component={<CodeInput mt={1} fontSize={14} minLines={6} />}
            />
            {!frames && (
              <Field
                mt={2}
                label="Content layout description"
                name="contentLayoutId"
                component={<Input />}
              />
            )}
            {(type === 'videos' || type === 'streams' || type === 'gifs') && (
              <Field
                mt={2}
                label="Frame layout description"
                name="frameLayoutId"
                component={<Input />}
              />
            )}
          </Box>
        )}
      />
    </Modal>
  );
}

CreateOrEditBoard.propTypes = {
  type: PropTypes.string.isRequired,
  create: PropTypes.bool,
  frames: PropTypes.bool,
  onUpdate: PropTypes.func,
  board: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    filterScript: PropTypes.string,
    orderBy: PropTypes.string,
    nextOffsetScript: PropTypes.string,
    limit: PropTypes.number,
    reviewable: PropTypes.bool,
    contentLayout: PropTypes.shape({
      id: PropTypes.string,
    }),
    frameLayout: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

CreateOrEditBoard.defaultProps = {
  create: true,
  frames: false,
  onUpdate: null,
  board: {
    id: '',
    name: '',
    filterScript: `// prepend field names with 'c.'
function filter(offset) {
  return "c.reviewed = false" + (offset ? " AND c.createdAt <= " + parseInt(offset, 10) : "");
}
`,
    orderBy: 'c.createdAt DESC',
    limit: 100,
    nextOffsetScript: `function nextOffset(lastItem) {
  return lastItem ? lastItem.createdAt : null;
}
`,
    reviewable: false,
    contentLayout: {
      id: '',
    },
    frameLayout: {
      id: '',
    },
  },
};

export default CreateOrEditBoard;
