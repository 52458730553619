import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Divider, Dropdown, Flex, IconButton, MenuItem,
  Page, Section, Text, Time, useApi, useParams, useModalState,
} from 'controls';

import api from 'api';
import SkillUtils from 'components/SkillUtils';
import Utils from 'components/Utils';
import CreateSkill from './CreateSkill';
import DeleteSkill from './DeleteSkill';
import SetDefaultSkill from './SetDefaultSkill';

const SkillItem = (props) => {
  const {
    type,
    skill,
    onClone,
    skillNameUsed,
    onDelete,
    onSetDefault,
  } = props;
  return (
    <Box
      pt={3}
    >
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Box>
          <Flex alignItems="center">
            <Button
              format="inline"
              text={skillNameUsed}
              to={`/${type}/skills/${skillNameUsed}`}
            />

            {skill.default && (
              <Flex ml={3}>
                <Text
                  bg="muted"
                  color="bg"
                  borderRadius={4}
                  text="Default"
                  px={2}
                  fontSize={12}
                  height={18}
                />
              </Flex>
            )}
          </Flex>
          <Text
            fontSize={14}
            color="muted"
            text={skill.name}
          />
          <Text
            fontSize={12}
            color="muted"
            text={`Updated: ${Time.format(skill.updatedAt, 'MMM DD, YYYY LTS')}`}
          />
          {skill.managed && (
            <Box>
              <Text
                display="inline-block"
                color="error"
                borderRadius={4}
                text="Non-editable"
                fontSize={12}
              />
            </Box>
          )}
        </Box>
        <Box>
          <Dropdown
            alignRight
            closeOnClick={false}
            toggle={(
              <IconButton p={1} color="muted" icon="ellipsis-v" />
            )}
            menuStyle={{
              marginTop: '5px',
            }}
            menuContent={(
              <>
                {!skill.default && (
                  <MenuItem
                    divider
                    fontSize="small"
                    py={2}
                    text="Set as default"
                    onClick={(e) => {
                      e.stopPropagation();
                      onSetDefault();
                    }}
                  />
                )}
                <MenuItem
                  divider={!skill.managed && !skill.default}
                  fontSize="small"
                  py={2}
                  text="Clone skill"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClone();
                  }}
                />
                {!skill.managed && !skill.default && (
                  <MenuItem
                    fontSize="small"
                    py={2}
                    text="Delete skill"
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete();
                    }}
                  />
                )}
              </>
            )}
          />
        </Box>
      </Flex>
      <Divider mt={3} />
    </Box>
  );
};

SkillItem.propTypes = {
  onClone: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSetDefault: PropTypes.func.isRequired,
  skillNameUsed: PropTypes.string.isRequired,
  skill: PropTypes.shape({
    default: PropTypes.bool,
    id: PropTypes.string,
    skillName: PropTypes.string,
    managed: PropTypes.bool,
    name: PropTypes.string,
    updatedAt: PropTypes.number,
    forVectorDb: PropTypes.bool,
  }).isRequired,
  type: PropTypes.string.isRequired,
};

function Skills() {
  const params = useParams();
  const { type } = params;

  const {
    data, isLoading, setDependency,
  } = useApi(async () => api.getActiveSkills(type), 0);
  const modalState = useModalState();
  const {
    createSkill, cloneSkillName,
    deleteSkill, skillName,
    batchDelete, setDefaultSkill,
    skillVersion,
  } = modalState.get();

  return (
    <Page mt={2}>
      <Section pt={6} loading={isLoading}>
        {data && (
          <Flex
            justifyContent="space-between"
            alignItems="center"
            mb={3}
          >
            <Text
              fontWeight="bold"
              text={`${Utils.toPascalCase(type)} skills`}
            />

            <Button
              format="inline"
              text="+ Create skill"
              onClick={() => modalState.push({
                createSkill: true,
                cloneSkillName: null,
              })}
            />
          </Flex>
        )}
        <Divider />
        {data && data.map((skill) => {
          const skillNameUsed = SkillUtils.determineSkillName(skill);
          return (
            (skill.forVectorDb === undefined || skill.forVectorDb === false) && (
            <SkillItem
              type={type}
              skill={skill}
              key={skill.id}
              skillNameUsed={skillNameUsed}
              onClone={() => modalState.push({
                createSkill: true,
                cloneSkillName: skillNameUsed,
              })}
              onDelete={() => modalState.push({
                deleteSkill: true,
                skillName: skillNameUsed,
                batchDelete: skill.skillName !== null,
              })}
              onSetDefault={() => modalState.push({
                setDefaultSkill: true,
                skillName: skillNameUsed,
                skillVersion: skill.version === 0 ? null : skill.version,
              })}
            />
            ));
        })}

        <Box mt={4}>
          <Text
            color="muted"
            fontSize={14}
            text="Default skill is executed for requests without a specified skill id"
          />
        </Box>
      </Section>
      {createSkill && (
        <CreateSkill
          type={type}
          cloneSkillName={cloneSkillName}
        />
      )}
      {deleteSkill && (
        <DeleteSkill
          type={type}
          skillName={skillName}
          batchDelete={batchDelete}
          onDelete={() => setDependency(Time.now())}
        />
      )}
      {setDefaultSkill && (
        <SetDefaultSkill
          type={type}
          skillName={skillName}
          skillVersion={skillVersion}
          onSetDefault={() => setDependency(Time.now())}
        />
      )}
    </Page>
  );
}

export default Skills;
