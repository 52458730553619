import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Box, Field, Form, Input, Modal, useHistory,
} from 'controls';

import api from 'api';

function EditSearchSettings(props) {
  const {
    settings, onUpdate,
  } = props;
  const history = useHistory();

  return (
    <Modal
      overflow="visible"
      title="Edit Search Settings"
      width={['100%', '100%', '480px']}
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      <Form
        initialValues={{
          endpoint: settings.endpoint,
          indexName: settings.indexName,
          apiKey: settings.apiKey,
        }}
        validationSchema={Yup.object().shape({
          endpoint: Yup.string().required('Required'),
          indexName: Yup.string().required('Required'),
        })}
        onSubmit={async (values) => {
          const response = await api.updateSearchSettings(
            values.endpoint,
            values.apiKey,
            values.indexName,
          );
          if (!response.error) {
            if (onUpdate) {
              onUpdate();
            }

            history.goBack();
            return null;
          }

          return null;
        }}
        action="Update"
        fields={() => (
          <Box>
            <Field
              label="Endpoint"
              name="endpoint"
              component={(
                <Input
                  type="string"
                />
              )}
            />
            <Field
              label="Api key"
              name="apiKey"
              component={(
                <Input
                  type="password"
                />
              )}
            />
            <Field
              label="Index name"
              name="indexName"
              component={(
                <Input
                  type="string"
                />
              )}
            />
          </Box>
        )}
      />
    </Modal>
  );
}

EditSearchSettings.propTypes = {
  onUpdate: PropTypes.func,
  settings: PropTypes.shape({
    endpoint: PropTypes.string,
    apiKey: PropTypes.string,
    indexName: PropTypes.string,
  }).isRequired,
};

EditSearchSettings.defaultProps = {
  onUpdate: null,
};

export default EditSearchSettings;
