import React from 'react';

import Markdown from './Markdown';

const markdown = `
## Boards
Watch For provides [Boards](/videos/boards) to query and review results at large scale. 
Boards functionality is only applicable if retention policy allows results to be stored
and legal policy allows content to be viewed by dashboard users.

A board is a *standing query* on the results storage and is populated with the following
content:
* Count of the number of items that satisfy the query.
* List of content with results returned by the query.

Boards can be used in pilots with test data sets to study the accuracy of skills.
It can also be used in production to study false positives and false negatives.

### Manually reviewing content

A board can also be used to manually review and flag content. Specifically, Watch For allows
content to be marked with two bits (which are returned as part of results):
* reviewed: Whether the content has been manually reviewed.
* flagged: Whether the content had been flagged as inappropriate.

For each content item, Watch For provides two buttons - *Safe* and *Flag* - that sets reviewed field
to true and sets the flagged field to false and true respectively. There is also a 
*Remove review* option to reset the reviewed field. If a content is flagged, it is blurred by default.

Review functionality should mainly be used for two purposes:
* Mark false positives and false negatives to improve skills.
* Blur inappropriate content to minimize exposure to dashboard users.

<span style="color:red">**Do not use Boards as a human moderation tool. If human moderation is required on top of Watch For processing,
please explore tools like Salus. Salus, built by the Xbox organization, has built-in mechanisms to understand Watch For results.**</span>

### Creating a board
Watch For supports the following types of boards:
* Image boards: Query and review results for images. 
* Video boards: Query and review results for videos. 
* Video frame boards: Query and review results for individual processed video frames. 
* Stream boards: Query and review results for live streams. 
* Stream frame boards: Query and review results for individual processed frames in live streams. 
* Gif boards: Query and review results for GIFs. 
* Gif frame boards: Query and review results for individual processed frames in gif images. 

A new board can be created by using the <code>+ Create board</code> option. A board needs the following inputs:
* Board id: Unique id that can be up to 1024 characters long with [A-Za-z0-9-_].
* Board name: Friendly name for the board.
* Review board: Whether the board will be used for manual review. In review boards, the reviewed items are automatically hidden making it easy to review a large number of items in a short time.
* Filter script: JavaScript method <code>filter</code> that returns the query filter (WHERE clause).
* Order by: ORDER BY clause for the query with the sorting field and direction (ASC or DESC).
* Limit: Item limit for the query.
* Next offset script: JavaScript method <code>nextOffset</code> that returns the offset to be used when the next page button is clicked.

The result storage in Watch For is backed by Azure Cosmos DB, a JSON store that supports 
indexing on all fields and a [SQL query syntax](https://docs.microsoft.com/en-us/azure/cosmos-db/sql-query-getting-started).
Filter script, Order by, Limit and Next offset script fields are used to construct the Cosmos DB queries in the following way:

~~~psuedocode
offset = null;
do {
  result = {};
  whereClause = filter(offset);
  itemsQuery = "SELECT TOP {input.limit} * FROM c WHERE {whereClause} ORDER BY {input.orderBy}";
  countQuery = "SELECT VALUE COUNT(1) FROM c WHERE {whereClause} ORDER BY {input.orderBy}";
  result.items = executeQuery(itemsQuery);
  result.count = executeQuery(countQuery);
  result.offset = nextOffset(items.count > 0 ? items.last() : null);
  offset = result.offset;
  showBoard(result);
} while (next-page)
~~~

A few things to note:
* All field names in the WHERE and ORDER BY clauses are prepended by "c.".
* Offset is passed as a string to the filter method.
* The <code>Next</code> page button is displayed on the board if the offset returned is not null.

The following example shows a review board to display only videos marked 
by a skill as adult. The content is sorted by recently created.
The filter script is written as follows:

~~~javascript
function filter(offset) {
  const where = "c.result.adult = true AND c.reviewed = false";
  if (offset) {
    where = where + " AND c.createdAt <= " + parseInt(offset, 10);
  }
  return where;
}
~~~

The order by field has the following:
~~~
c.createdAt DESC
~~~

The limit field is set to 100 to restrict a page to 100 items.

The next offset script is written as follows:
~~~javascript
function nextOffset(lastItem) {
  return lastItem ? lastItem.createdAt : null;
}
~~~

Like skills, the scripts can call any JavaScript constructs. The following filter script 
restricts the queried items to only the last 24 hours.

~~~javascript
function filter(offset) {
  const startTime = Math.floor(Date.now() / 1000) - 86400;
  const where = "c.createdAt >= startTime AND c.result.adult = true AND c.reviewed = false";
  if (offset) {
    where = where + " AND c.createdAt <= " + parseInt(offset, 10);
  }
  return where;
}
~~~

### Interactive query interface
Watch For provides an [interactive query interface](/videos) to experiment with queries before a board is created.

Select <code>Interactive query</code> option in the boards page.
The query page provides a simple interface that splits the query into three inputs:
* WHERE: Specifies filter conditions.
* ORDERBY: Specifies the sorting field and direction (ASC or DESC).
* LIMIT: Specifies the maximum number of items returned.

The following Cosmos DB query is constructed using the fields above:

~~~
SELECT TOP {input.LIMIT} * FROM c WHERE {input.WHERE} ORDER BY {input.ORDERBY}
~~~

Results can be queried for images, videos, live streams, and gifs. Frame results can also be queried for 
individual frames in videos and live streams.
The <code>Count</code> option simply counts the number of items instead of returning the content.

### Running accuracy experiments
A skill can be evaluated for accuracy (FPR, FNR) with boards
with a tagged dataset that can be processed through Watch For.

The following shows an example experiment for evaluating
a skill that finds adult content in videos.

We assume that there is a video dataset with ground truth tags (adult or not adult).
We assume *my-video-skill* is the custom skill to be evaluated.

When requests are sent for processing, the ground truth tag and experiment details are 
added to the *props* field.

~~~
POST /videos
{
  "id": "test-video-1",
  "videoUri": "<<url-to-video>>",
  "skillId": "my-video-skill",
  "props": {
    "expName": "exp1",
    "groundTruthAdult": true
  }
}
~~~

In the skill, the fields in *props* are copied to results so that they can be queried.

~~~javascript
let state = {};
function init(props) {
  state = {};
  state.expName = props.expName;
  state.groundTruthAdult = props.groundTruthAdult;
}
~~~

~~~javascript
function aggregateFrameResults(frameResults) {  
  ...
  ...
  result.adult = ...; // populated using skill logic
  result.expName = state.expName;
  result.groundTruthAdult = state.groundTruthAdult;
  return result;
}
~~~

Four different boards are created with the following queries:

1. Board to track false positives
~~~
c.result.expName = "exp1" AND c.result.adult = true AND c.result.groundTruthAdult = false
~~~

2. Board to track true positives
~~~
c.result.expName = "exp1" AND c.result.adult = true AND c.result.groundTruthAdult = true
~~~

3. Board to track false negatives
~~~
c.result.expName = "exp1" AND c.result.adult = false AND c.result.groundTruthAdult = true
~~~

4. Board to track true negatives
~~~
c.result.expName = "exp1" AND c.result.adult = false AND c.result.groundTruthAdult = false
~~~

The boards show the count (#) of items that satisfy the queries. False positive rate (FPR) can be calculated as #1 / (#1 + #4).
False negative rate (FNR) can be calculated as #3 / (#2 + #3).

<br /><br />
`;

export default function Doc() {
  return <Markdown markdown={markdown} />;
}
