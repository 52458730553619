import React, { useState } from 'react';
import ReactJson from 'react-json-view';
import PropTypes from 'prop-types';
import {
  Box, Field, Form, Input, Section, useApi, Select, Text, Button, Flex,
} from 'controls';
import api from 'api';
import Utils from '../components/Utils';

function TestContent(props) {
  const {
    type, skillIdFromSkillPage, skillNameFromSkillPage, skillVersionFromSkillPage,
  } = props;
  const [content, setContent] = useState(null);
  const [jobWaiting, setJobWaiting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [globalId, setGlobalId] = useState('');
  const [form, setForm] = useState(null);
  const [uri, setUri] = useState('');
  const [skillId, setSkillId] = useState('');
  const [stopButtonDisabled, setStopButtonDisabled] = useState(false);
  const [skillVersionsDropdown, setSkillVersionsDropdown] = useState(null);
  const singularType = Utils.toSingular(type);
  const label = type === 'texts' ? singularType : `${singularType} URI`;
  const inputName = type === 'texts' ? `${singularType}` : `${singularType}Uri`;
  const submitText = type === 'streams' ? 'Start' : 'Submit';
  const videoTypeOptions = [
    { label: 'Mp4', value: 'Mp4' },
    { label: 'Hls', value: 'Hls' },
  ];

  const {
    data: skills,
  } = useApi(async () => api.getActiveSkills(type), 0);

  let skillArr = [];
  if (skills) {
    skillArr = skills.map((s) => {
      const skillNameUsed = s.skillName === null ? s.id : s.skillName;
      return { label: skillNameUsed, value: skillNameUsed, type: s.contentType };
    });
  }

  let submitButtonIsDisabled = uri.trim() === '';
  if (type === 'images') {
    submitButtonIsDisabled = submitButtonIsDisabled && form === null;
  }

  if (skillNameFromSkillPage === '') {
    submitButtonIsDisabled = submitButtonIsDisabled || skillId === '';
  }

  async function getResponse(id) {
    setJobWaiting(true);
    const response = await api.getContent(type, id);
    setContent(response.data);
    setJobWaiting(false);
  }

  return (
    <>
      <Section>
        <Form
          initialValues={{
            Text: '',
            ImageUri: '',
            VideoUri: '',
            StreamUri: '',
            GifUri: '',
            AudioUri: '',
            skillId: skillIdFromSkillPage,
            skillName: skillNameFromSkillPage,
            skillVersion: skillVersionFromSkillPage === 0
              ? null : skillVersionFromSkillPage.toString(),
            videoType: 'Mp4',
          }}
          action={null}
          onSubmit={async (values) => {
            const req = JSON.stringify(values);
            let testResult = null;
            if (form === null) {
              testResult = await api.test(req, singularType);
            } else {
              testResult = await api.processUploadedImage(form.name,
                form, {
                  skillId: values.skillId,
                  skillName: values.skillName,
                  skillVersion: values.skillVersion,
                });
            }
            if (!testResult.error) {
              setGlobalId(testResult.data.id);
              await getResponse(testResult.data.id);
            } else {
              setJobWaiting(false);
              setIsError(true);
              setContent(testResult.error);
            }
          }}
          fields={(fprops) => (
            <Box mb={2}>
              <Field
                label={label}
                name={inputName}
                onChange={(e) => {
                  const doubleQuotesRemoved = e.target.value.replaceAll('"', '');
                  setUri(doubleQuotesRemoved);
                  fprops.setFieldValue(inputName, doubleQuotesRemoved);
                }}
                component={(
                  <Input
                    placeholder={`Enter ${label}`}
                    name="uri"
                    disabled={form !== null}
                  />
                )}
              />
              {type === 'images' && (
                <Field
                  label="Upload an image"
                  name="imageUpload"
                  onChange={(e) => {
                    if (e.target.files !== null && e.target.files[0] !== null
                      && e.target.files[0].name !== null) {
                      setForm(e.target.files[0]);
                    } else {
                      setForm(null);
                    }
                  }}
                  component={(
                    <Input
                      id="file"
                      type="file"
                      disabled={uri.trim() !== ''}
                    />
                  )}
                />
              )}
              {(skillIdFromSkillPage == null && skillNameFromSkillPage === null) && (
                <Field
                  label="Skill"
                  name={skillId}
                  component={(
                    <Select
                      options={skillArr}
                    />
                  )}
                  onChange={async (e) => {
                    setSkillId(e.target.value);
                    setSkillVersionsDropdown(null);
                    fprops.setFieldValue('skillId', e.target.value);
                    fprops.setFieldValue('skillVersion', null);
                    const skillVersions = await api.listSkillVersions(type, e.target.value);

                    if (!skillVersions.error) {
                      let versionsDropdown = null;
                      if (skillVersions.data && skillVersions.data.length !== 0) {
                        versionsDropdown = skillVersions.data.map((x) => ({ value: x.version, label: `Version ${x.version} - ${x.name}` }));
                      }
                      setSkillVersionsDropdown(versionsDropdown);
                    }
                  }}
                />
              )}
              {skillVersionsDropdown !== null && (
                <Field
                  label="Skill Version"
                  name="skillVersion"
                  component={(
                    <Select
                      width="400px"
                      options={skillVersionsDropdown}
                    />
                  )}
                  onChange={(e) => {
                    fprops.setFieldValue('skillVersion', e.target.value);
                  }}
                />
              )}
              {type === 'videos' && (
                <Field
                  label="Video Type"
                  name="videoType"
                  component={(
                    <Select
                      width="400px"
                      value={fprops.values.videoType}
                      options={videoTypeOptions}
                    />
                  )}
                  onChange={(e) => {
                    fprops.setFieldValue('videoType', e.target.value);
                  }}
                />
              )}
              <Box>
                {type === 'images' && (form !== null || uri.trim() !== '') && (
                  <Text
                    mb={2}
                    color="error"
                    text="You can provide either an image URI or an image file."
                  />
                )}
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  mb={3}
                  mt={5}
                >
                  <Flex>
                    <Button
                      fontSize={18}
                      px={4}
                      text={submitText}
                      type="submit"
                      disabled={submitButtonIsDisabled}
                      loading={fprops.isSubmitting}
                      height={40}
                    />
                    {type === 'streams' && globalId !== '' && (
                      <Button
                        text="Stop"
                        fontSize={18}
                        px={4}
                        ml={4}
                        height={40}
                        disabled={stopButtonDisabled}
                        onClick={() => {
                          api.hackStopStream(globalId);
                          setStopButtonDisabled(true);
                        }}
                      />
                    )}
                  </Flex>
                  {globalId !== '' && (
                    <Button
                      format="inline"
                      text="Refresh"
                      fontSize={18}
                      height="40%"
                      onClick={async () => {
                        if (globalId !== '') {
                          await getResponse(globalId);
                        }
                      }}
                    />
                  )}
                </Flex>
              </Box>
            </Box>
          )}
        />
      </Section>
      <Section loading={jobWaiting}>
        {isError && content && (
          <Text
            mt={6}
            mb={2}
            color="error"
            text={`Status: ${content.status}, Message: ${content.data}`}
          />
        )}
        {content && (
          <Box mt={6}>
            <ReactJson
              src={content}
              displayDataTypes={false}
            />
          </Box>
        )}
      </Section>
    </>
  );
}

TestContent.propTypes = {
  type: PropTypes.string.isRequired,
  skillIdFromSkillPage: PropTypes.string,
  skillNameFromSkillPage: PropTypes.string,
  skillVersionFromSkillPage: PropTypes.number,
};

TestContent.defaultProps = {
  skillIdFromSkillPage: null,
  skillNameFromSkillPage: null,
  skillVersionFromSkillPage: 0,
};

export default TestContent;
