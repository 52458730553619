import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Box, Field, Form, Modal, Select, useHistory,
} from 'controls';

import api from 'api';

function EditUiSettings(props) {
  const {
    settings, onUpdate,
  } = props;
  const history = useHistory();

  return (
    <Modal
      overflow="visible"
      title="Edit UI Settings"
      width={['100%', '100%', '480px']}
      overlayStyle={{
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'fixed',
        zIndex: 11,
      }}
    >
      <Form
        initialValues={{
          contentType: settings.contentType,
        }}
        validationSchema={Yup.object().shape({
          contentType: Yup.string().required('Required'),
        })}
        onSubmit={async (values) => {
          const response = await api.updateUiSettings(values.contentType);
          if (!response.error) {
            if (onUpdate) {
              onUpdate();
            }

            history.goBack();
            return null;
          }

          return null;
        }}
        action="Update"
        fields={() => (
          <Box>
            <Field
              label="Preferred content type"
              name="contentType"
              component={(
                <Select
                  options={[
                    { label: 'Image', value: 'image' },
                    { label: 'Video', value: 'video' },
                    { label: 'Live Stream', value: 'stream' },
                    { label: 'Gif', value: 'gif' },
                    { label: 'Text', value: 'text' },
                    { label: 'Audio', value: 'audio' },
                    { label: 'Custom', value: 'custom' },
                  ]}
                />
              )}
            />
          </Box>
        )}
      />
    </Modal>
  );
}

EditUiSettings.propTypes = {
  onUpdate: PropTypes.func,
  settings: PropTypes.shape({
    contentType: PropTypes.string,
  }).isRequired,
};

EditUiSettings.defaultProps = {
  onUpdate: null,
};

export default EditUiSettings;
