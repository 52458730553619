import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, EllipsisText, Link, Page, Section,
  Table, Text, TruncatedText,
  Time, Tooltip, useApi, useResponsive,
} from 'controls';

import api from 'api';

const STRINGS = {
  title: 'Active streams',
};

const TableHeader = (props) => {
  const { text, tooltip } = props;
  return (
    <Text textAlign="left" p={2}>
      {text}
      {tooltip && (
        <Tooltip ml={1} tooltip={tooltip} />
      )}
    </Text>
  );
};

TableHeader.propTypes = {
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

TableHeader.defaultProps = {
  tooltip: null,
};

function ActiveStreams() {
  const isMd = useResponsive('sm');
  const { data, isLoading } = useApi(
    async () => api.getActiveStreams(),
  );

  /* eslint-disable react/prop-types */
  const columns = [{
    Header: <TableHeader text="Id" />,
    accessor: 'id',
    Cell: ({ row }) => (
      <Box p={2} fontSize="xxx-small">
        {row.values.id && (
          <Link
            to={`/streams/${row.values.id}`}
          >
            <EllipsisText
              text={row.values.id}
            />
          </Link>
        )}
      </Box>
    ),
    width: 'auto',
  },
  {
    Header: <TableHeader text="DQ" />,
    accessor: 'dequeueCount',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={cell.value}
        />
      </Box>
    ),
    width: 40,
  },
  {
    Header: <TableHeader text="Status" />,
    accessor: 'status',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={cell.value}
        />
      </Box>
    ),
    width: 80,
  },
  {
    Header: <TableHeader text="Errors" />,
    accessor: 'continuousErrors',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xxx-small">
        <TruncatedText
          text={cell.value}
        />
      </Box>
    ),
    width: 80,
  },
  {
    Header: <TableHeader text="Created" />,
    accessor: 'createdAt',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={Time.ago(cell.value)}
        />
      </Box>
    ),
    width: 90,
  },
  {
    Header: <TableHeader text="Success" />,
    accessor: 'lastSuccessAt',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={Time.ago(cell.value)}
        />
      </Box>
    ),
    width: 90,
  },
  {
    Header: <TableHeader text="Updated" />,
    accessor: 'updatedAt',
    Cell: ({ cell }) => (
      <Box p={2} fontSize="xx-small">
        <Text
          text={Time.ago(cell.value)}
        />
      </Box>
    ),
    width: 90,
  }];
  /* eslint-enable react/prop-types */

  return (
    <Page mt={2}>
      <Section mt={4} loading={isLoading}>
        {data && (
          <>
            <Text fontSize="medium-large" fontWeight="bold" mb={4}>
              {`${STRINGS.title} (${data.length})`}
            </Text>
            <Table
              data={data}
              columns={columns}
              hiddenColumns={[...(isMd ? [] : ['dequeueCount', 'continousErrors', 'createdAt', 'updatedAt'])]}
            />
          </>
        )}
      </Section>
    </Page>
  );
}

export default ActiveStreams;
